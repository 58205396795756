import React from "react";

function FolioTechFive21() {
  return (
    <section class="video-slice bg-darkest-overlay" data-v-22a985e7>
      <div
        class="relative flex items-center justify-center h-screen w-full max-h-[400px] md:max-h-[700px] lg:max-h-[900px]"
        data-v-22a985e7
      >
        <div class="relative z-30 w-full text-center" data-v-22a985e7>
          <div class="wrapper" data-v-22a985e7>
            <div
              class="rich-text text-white mb-6 md:mb-10 lg:mb-14 max-w-6xl mx-auto"
              data-v-22a985e7
            >
              <h2></h2>
            </div>
          </div>
        </div>{" "}
        <video
            class="absolute inset-0 object-cover h-full w-full"
            controls
            src='../images/Rashid/R.mp4'
            autoPlay
            loop
            muted
          >
          </video>
      </div>
    </section>
  );
}

export default FolioTechFive21;
