import React from "react";

function Stories2a() {
  return (
    <div class="wrapper -mt-10 lg:-mt-20" data-v-715b3dbb>
      <div class="lg:flex" data-v-715b3dbb>
        <div class="lg:w-3/12" data-v-715b3dbb>
          <span
            class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]"
            data-v-715b3dbb
          >
            <span class="inline-block transform" data-v-715b3dbb>
              Nov 2023
            </span>
          </span>
          <div class="mt-8 flex flex-wrap lg:block" data-v-715b3dbb>
            <div id="onlinemarknadsföring" class="btn" data-v-715b3dbb>
              The Chromatic Symphony
            </div>
            <div id="Digital marknadsföring" class="btn" data-v-715b3dbb>
              Unraveling the Impact
            </div>
            <div id="Lågkonjunktur" class="btn" data-v-715b3dbb>
              of Color on Brand Identity
            </div>
          </div>
        </div>
        <div class="mt-8 lg:mt-0 lg:w-9/12" data-v-715b3dbb>
          <div class="w-full pb-[56%] relative" data-v-715b3dbb>
            <img
              src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243512/SWEK/images/Others/pexels2_uowfys.jpg"
              alt="Onlinemarknadsföring under lågkonjunktur. Flygande motorcykel."
              class="w-full h-full inset-0 object-cover absolute"
              data-v-715b3dbb
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stories2a;
