import React from 'react'

function OlandFolio19() {
  return (
   <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/5907d2e5-367a-4478-a497-4bd8d47e4d41_oland_16x9_icons.png?auto=compress,format" alt="Ikonsystem Öland" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio19
