import React from "react";

function InnerBrand1() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <div class="lg:w-3/12">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Brand strategy and brand development
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">We</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">build</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">strong</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">brands</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  A unique and reliable brand is your company's greatest asset.{" "}
                </span>
              </p>
              <p className="mobile-small">
                It creates loyalty and makes a customer choose your company
                instead of one of your competitors. But a strong brand is not
                created by itself. It takes a clear brand strategy and perhaps
                not entirely unexpected hard, focused work. How lucky that we
                like to work hard then!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerBrand1;
