import React from "react";

function FolioDigital20() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/4100d0d1-a493-4f0f-bd5c-712352358a41_kff_2022_nahom_webb.jpg?auto=compress,format&rect=501,0,2000,2000&w=800&h=800"
            alt="Nahom Netabay Kalmar FF"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/9a3bc420-5459-42b1-9fdb-86bd6784d0e1_kff_2022_sasongskort_1080x1920_5.jpg?auto=compress,format&rect=0,640,1080,1082&w=998&h=1000"
            alt="Säsongskort 2023 Kalmar FF"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/59582b86-3e54-4dae-81c9-c1b155663e14_image+%2870%29.png?auto=compress,format&rect=102,0,3000,3000&w=1200&h=1200"
            alt="T-shirt legendarer Kalmar FF"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/ac5ed8d2-ddf4-493e-86db-fc6118f543e0_Ska%CC%88rmavbild+2022-07-07+kl.+14.07.13.png?auto=compress,format&rect=3,0,1696,1696&w=1200&h=1200"
            alt="Kalmar FF grå sweatshirt Kalmar FF"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigital20;
