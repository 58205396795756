import React from "react";

function InnerCopy4() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>Uzawi</h4>
              <p className="mobile-small">
                We write copy for Uzawi for their website blog every month.{" "}
                <br />
                Feel free to browse a bit and read. <br />
                <a
                  target="_blank"
                  rel="noopener"
                  // href=""
                >
                  Read Uzawi Articles
                </a>
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243145/SWEK/images/Uzawi/6_apvj10.jpg"
                alt="Case Öländska hjärtan"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerCopy4;
