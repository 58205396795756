import React from "react";

function TechSeo2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Search Engine Optimization
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>What does SEO mean?</h2>
            <p className="mobile-small">
              SEO is an abbreviation of Search Engine Optimization. In short, it involves optimizing a website both technically and in terms of content in order to appear high in the search results. Today, Google is by far the most important search engine and there are hundreds of factors that affect a website's ranking. Many factors are known, but not all and they also have different impact and importance. The pages that are perceived to fit the current search best at the moment rank higher and those that do not, for obvious reasons, end up lower in the results. It is also important to bear in mind that the ranking often goes up and down over time, which means that SEO is not a one-time effort but a continuous strategic work over time.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechSeo2;
