import React from "react";

function CoDigital1() {
  return (
    <section class="slice bg-darkest " data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Commitment and relationship strengthening
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Why work with content?</h2>
            <p className="mobile-small">
              Well-produced and unique content has several advantages. It creates engagement and helps you cultivate a stronger relationship with your target audience. In addition, good content drives traffic and increases your conversion. Competition online is brutal, so it's important to differentiate yourself with inspiring, informative and educational content. By working with a content agency like Swek  Interactive, you gain access to the expertise and creativity necessary to create valuable and relevant content. We have extensive experience in creating sharp content for all digital channels.{" "}
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoDigital1;
