import React from "react";

function InnerBrand2() {
  return (
    <section class="slice bg-darkest custom-padding" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              More than a logo
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>What is a trademark?</h2>
            <p className="mobile-small">
              A brand is much more than a logo or a clever name. It is the sum
              of all associations that a target group has with a company,
              product or service. Consider the most successful companies in the
              world – their brands represent not only products or services, but
              also values, traditions and visions. A strong brand is based on
              authenticity and consistency. It reflects the soul of your
              business, your promises to customers and how you differentiate
              yourself from the competition. At Swek Interactive, we focus on
              building brands that are not only recognizable but also create
              long-lasting emotional bonds with customers. By combining your
              unique business identity with our creativity and expertise, we
              shape a brand that people remember and trust.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerBrand2;
