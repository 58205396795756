import React from "react";

function TechHosting3() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Reliability
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>We keep an eye on</h2>
            <p className="mobile-small">
              Troubleshooting during operational disruptions is included in the hosting agreement, and with the help of SMS alerts, we monitor potential interruptions around the clock. If a critical error occurs on the website, rendering it unavailable to users, we resolve it immediately without the need for you to file a report.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechHosting3;
