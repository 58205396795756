import React from "react";

function CareerHome() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243243/SWEK/images/Headers/career_d3rljf.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243243/SWEK/images/Headers/career_d3rljf.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case mobile"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Search for jobs and internships at Swek Interactive
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Career</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              {/* <h6>
                Swek Interactive is a digital agency with 22 employees. Our
                offices are located in Kalmar and Stockholm, but we serve
                clients across Sweden. Last spring, we brought home{" "}
                <a href="/stories/dubbla-silver-i-arets-byra/">
                  double silver medals in the Agency of the Year awards.
                </a>
                : Digital Agency of the Year and Employer of the Year.
              </h6> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CareerHome;
