import React from "react";
import {Outlet, Link} from 'react-router-dom'

function TechWebdev9() {
  return (
    <section class="slice custom-padding" data-v-d8f50b44>
      <div class="wrapper flex flex-col" data-v-d8f50b44>
        <div
          class="w-full py-12 border-t border-darker first-of-type:border-none first:pt-0 last:pb-0"
          data-v-d8f50b44
        >
          <div class="flex flex-wrap lg:flex-nowrap group" data-v-d8f50b44>
            <div class="w-full lg:w-1/4 mb-4 lg:mb-0" data-v-d8f50b44>
              <Link to='/tech'><a
                href=""
                class="text-base lg:text-lg block hover:text-laser hover:underline md:mt-5"
                data-v-d8f50b44
              >
                01.Tech
              </a></Link>
            </div>
            <div class="w-full lg:w-3/4" data-v-d8f50b44>
              <span
                class="text-2xl md:text-3xl lg:text-5xl block"
                data-v-d8f50b44
              >
                <div class="leading-tight links" data-v-d8f50b44>
                  <p>
                   <Link to='/techuxdesign'> <a>UX & UI</a></Link> /{" "}
                    <Link to='/techwebdev'><a>Web development</a></Link> /{" "}
                    <Link to='/techecommercepage'><a>E-Commerce</a></Link> /{" "}
                    <Link to='/techapp'><a>App</a></Link> /
                    <Link to='/techseo'><a>SEO</a></Link>{" "}
                    / <Link to='/techhosting'><a>Hosting</a></Link>
                  </p>
                </div>
              </span>
            </div>
          </div>
              </div>
              <Outlet/>
      </div>
    </section>
  );
}

export default TechWebdev9;
