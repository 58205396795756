import React from "react";

function InnerBrand6() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Brand platform - the company's bible</h3>
              <p className="mobile-small">
                A brand platform, also called a brand book, is the heart of
                every successful brand. It's a comprehensive and detailed plan
                that covers everything from your company's mission and vision to
                its core values ​​and personality. This document serves as a
                guide for all your marketing and communications efforts. It
                ensures that your brand's message is consistent, regardless of
                the channel or format it is presented in. At Swek Interactive,
                we work with you to define and articulate these core elements of
                your brand platform. We make sure that your platform is not only
                a description of where your company stands today, but also of
                where you want to go. Through a well-developed brand platform,
                your company can communicate its unique value and build strong
                relationships with both current and potential customers.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243189/SWEK/images/Uzawi/7_mnawx2.jpg"
                alt="Swek Branding"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerBrand6;
