import React from "react";

function FolioTechFive3() {
  return (
    <section class="video-slice bg-darkest-overlay" data-v-22a985e7>
      <div
        class="relative flex items-center justify-center h-screen w-full max-h-[400px] md:max-h-[700px] lg:max-h-[900px]"
        data-v-22a985e7
      >
        <video
          class="absolute inset-0 object-cover h-full w-full"
          controls
          src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723243607/SWEK/images/Rashid/R_ginx5y.mp4"
          autoPlay
          loop
          muted
        ></video>
      </div>
    </section>
  );
}

export default FolioTechFive3;
