import React from "react";

function SmDigital3() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>
                How does it work to hire Swek Interactive as a social media agency?
              </h3>
              <ol>
                <li className="mobile-small">
                  We start by getting to know each other and identify challenges and opportunities
                </li>
                <li className="mobile-small">
                  We lay the foundation for a social media strategy based on your unique goals
                </li>
                <li className="mobile-small">
                  We produce value-creating content, publish and optimize
                </li>
                <li className="mobile-small">
                  We'll keep you in the loop all the way and finish with a performance report
                </li>
                <li className="mobile-small">
                  The cost of the bet is always fixed - no nasty surprises
                </li>
                <li className="mobile-small">
                  We are happy to share our knowledge to strengthen the client's SoMe competence
                </li>
              </ol>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <video
                class="absolute inset-0 object-cover h-full w-full"
                controls
                src="../images/Others/Bottle-R.mp4"
                autoPlay
                loop
                muted
              ></video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital3;
