import React from "react";
import {Outlet, Link} from 'react-router-dom'

function StoriesMessage() {
  return (
    <div data-v-06c40047>
      <div class="wrapper" data-v-06c40047>
        <div class="lg:flex" data-v-06c40047>
          <div class="lg:w-3/12 custom-padding-second" data-v-06c40047>
            <span
              class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]"
              data-v-06c40047
            >
              <span class="inline-block transform text-white" data-v-06c40047>
                Dec 2023
              </span>
            </span>
          </div>

          {/* Blog-1 */}
          <Link to="/blog1">
            <a class="lg:w-9/12 pb-10 group" data-v-06c40047>
              <div
                class="story group-hover:text-laser transition-colors duration-200"
                data-v-06c40047
              >
                <h1>Swek News 11/12/23 - A Blueprint for Success</h1>
              </div>
              <div
                class="flex flex-col md:flex-row -mx-8 mt-4 md:mt-12 lg:mt-16 pb-10 lg:pb-0"
                data-v-06c40047
              >
                <div class="w-full md:w-1/2 px-8" data-v-06c40047>
                  <div class="w-full pb-[66%] relative" data-v-06c40047>
                    <img
                      src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243654/SWEK/images/Others/Grains/6_ezqkdk.jpg"
                      alt="Ny hemsida till Luma Energy "
                      class="w-full h-full inset-0 object-cover absolute"
                      data-v-06c40047
                    />
                  </div>
                </div>
                <div
                  class="flex flex-col justify-between w-full md:w-1/2 px-8 mt-4 md:mt-0"
                  data-v-06c40047
                >
                  <div
                    class="rich-text text-white ingress-text"
                    data-v-06c40047
                  >
                    <p className="mobile-small">
                      Brand strategy is the comprehensive plan that outlines the
                      long-term goals, values, and mission of a brand. It goes
                      beyond the tangible elements like logos and colors,
                      encompassing the emotional connection a brand aims to
                      establish with its audience.
                    </p>
                  </div>
                  <div
                    class="text-base text-laser flex items-center mt-4 md:mt-0"
                    data-v-06c40047
                  >
                    <span class="group-hover:underline" data-v-06c40047>
                      Read More
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-2 transform transition-all duration-200 group-hover:translate-x-1"
                      data-v-06c40047
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-06c40047
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </div>
      </div>

      {/* Blog-2 */}
      <div
        class="border-t last:border-b border-darker py-10 w-full"
        data-v-06c40047
      >
        <div class="wrapper" data-v-06c40047>
          <div class="lg:flex" data-v-06c40047>
            <div class="lg:w-3/12" data-v-06c40047>
              <span
                class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]"
                data-v-06c40047
              >
                <span class="inline-block transform text-white" data-v-06c40047>
                  Nov 2023
                </span>
              </span>
            </div>
            <div class="lg:w-9/12" data-v-06c40047>
              <Link to="/blog2">
                <a
                  class="story-link hover:text-laser transition-colors duration-200"
                  data-v-06c40047
                >
                  The Chromatic Symphony
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Blog-3 */}
      <div
        class="border-t last:border-b border-darker py-10 w-full"
        data-v-06c40047
      >
        <div class="wrapper" data-v-06c40047>
          <div class="lg:flex" data-v-06c40047>
            <div class="lg:w-3/12" data-v-06c40047>
              <span
                class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]"
                data-v-06c40047
              >
                <span class="inline-block transform text-white" data-v-06c40047>
                  Aug 2023
                </span>
              </span>
            </div>
            <div class="lg:w-9/12" data-v-06c40047>
              <a
                class="story-link hover:text-laser transition-colors duration-200"
                data-v-06c40047
              >
                Swek News 08.23
              </a>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
}

export default StoriesMessage;
