import React from "react";

function ProjectHome() {
  return (
    <div class="wrapper lg:flex mb-20 lg:mb-40 home-reset">
      <div class="lg:w-3/12">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243249/SWEK/images/Headers/projects_mzjemq.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243242/SWEK/images/Headers/projects2_z7qoin.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case mobile"
        ></img>

        <span class="label mb-label block overflow-hidden delete">
          <span class="inline-block transform translate-y-full translate-force">
            Selection of customer collaborations
          </span>
        </span>
      </div>
      <div class="lg:w-9/12 delete">
        <h1 class="text-white huge-title mb-huge-title leading-none pt-[0.15em]">
          <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
            <span class="inline-block transform translate-y-full align-top translate-force">
              <h1>Projects</h1>
            </span>
          </span>
        </h1>
        <div class="rich-text ingress-text w-full max-w-3xl opacity-0 transform translate-y-8">
          <p className="mobile-small">
            If there's something we love, it's our customers/friends/sparring
            partners. Large and small brands we help grow and achieve success.
            Here's a selection of everything we've done, get out the bag of
            chips and enjoy!
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProjectHome;
