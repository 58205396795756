import React from "react";

function DigitalMessage() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight  text-delete"
              data-v-2ac86e56
            >
              Customized digital love
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Therefore, digital marketing is right for your business</h2>
            <p className="mobile-small">
              Actually, the term "digital marketing" feels a bit outdated
              considering how media habits have developed since the turn of the
              millennium. Today, more or less everyone is online, which rather
              makes digital marketing yes, marketing.
            </p>
            <p className="mobile-small">
              The biggest advantage of digital marketing is that it is measurable. This means that the marketing budget is placed on the channels that give the very best effect, which minimizes risk and maximizes the opportunity to reach both existing and prospective customers with the right message at the right time. An advantage of letting Swek Interactive take a comprehensive approach to digital marketing is that we can simply and easily use the media budget where it does the most good. For us, it is important to be flexible, transparent and quick-footed to ensure the highest possible ROI.


            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DigitalMessage;
