import React from "react";

function OurPolicy1() {
  return (
    <section class="slice relative">
      <div class="wrapper lg:flex">
        <div class="lg:w-3/12">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">Our policy</h1>
          </div>
        </div>
        <div class="lg:w-9/12">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">How</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">We</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Handle</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Information</span>
            </span>
            
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p className="mobile-small">
                Personal data is broadly defined according to data protection legislation and includes both the obvious, such as your name and email address, and less obvious things like IP addresses and cookies. This means that we come into contact with your personal data when you, for example, visit our website.
              </p>
              <p className="mobile-small">
                All companies that in any way collect this type of data are obligated to inform the data subject about what information is being collected, how the data is used, and what rights you have to modify or delete your information (and more). We believe this is a good practice!
              </p>
              <p className="mobile-small">Så här gör vi på Wilson.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurPolicy1;
