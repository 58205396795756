import React from "react";

function TechSeo10() {
  return (
    <section class="py-20 lg:py-40 bg-lapis" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="white-smiley link rich-text" data-v-7dc95f74>
              <h3>Are you looking for a creative SEO agency?</h3>
              <h3>Contact Hakim</h3>
              <p>
                Tebusweke Hakim
                <br />
                Cheif Creative Director
                <br />
                <a
                  target="_blank"
                  rel="noopener"
                  href="mailto:info@swekinteractive.com"
                >
                  Email me
                </a>
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="../images/illustrations/9.jpeg"
                alt="SEO-byrå - kontakta Hasse Hofvergård"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechSeo10;
