import React, { useEffect } from "react";
import "../styles/Contact.css";
import ContactHome from "../pageSections/ContactSections/ContactHome";
import ContactIcons from "../pageSections/ContactSections/ContactIcons";
import Form from "../UniSections/Form";
import ContactMessage from "../pageSections/ContactSections/ContactMessage";
import ContactCrew from "../pageSections/ContactSections/ContactCrew";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <main class="flex-grow contain">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <ContactHome />
          <ContactIcons />
          <Form />
          <ContactMessage />
          {/* <ContactCrew />       */}
        </div>
      </div>
    </main>
  );
}

export default Contact;
