import React from "react";
import {Outlet, Link} from 'react-router-dom'

function TechSeo6() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              On page-SEO
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Content-driven SEO - focus on content</h2>
            <p className="mobile-small">
              Content-driven SEO, or on page SEO, focuses on the actual content of your website: <Link to='/copyinner'><a>text</a></Link>,{" "}
              <Link to='/photo'></Link><a>images</a>,{" "}
              <Link to='/film'><a>video</a></Link> and links. Using relevant keywords and phrases is important, but so is creating engaging and descriptive content. Our copywriters write SEO-optimized texts, and our content team produces professional content, all tailored to optimize your digital presence.
            </p>
            <h3>Custom SEO strategies </h3>
            <p className="mobile-small">
             We understand that every business is unique. That's why we offer customized SEO strategies that are specifically tailored to meet your unique business goals and needs. We work closely with you to create a customized plan that takes your visibility on Google to the next level.
            </p>
            <h3>Measurable results and continuous SEO optimization</h3>
            <p className="mobile-small">
              We work with full transparency and measurable results. Through continuous monitoring and{" "}
              <Link to='/digitaldataanalysis'><a>data analysis</a></Link> we ensure that your SEO strategy is constantly developed and improved. We analyze traffic, user behavior and conversion rates to ensure that your search engine optimization investment yields the best possible return.
            </p>
          </div>
              </div>
              <Outlet/>
      </div>
    </section>
  );
}

export default TechSeo6;
