import React from "react";

function InnerCopy3() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Content
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>Editorial Copy / Native</h3>
            <p className="mobile-small">
              Do you need content for a customer platform, newsletter, press
              release or native advertisement? We write editorial content with
              or without interviews, but always with the right tone and flow.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerCopy3;
