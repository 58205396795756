import React from "react";

function PhiloCeo() {
  return (
    <section class="py-20 lg:py-40 bg-lapis" data-v-605c8685>
      <div class="wrapper" data-v-605c8685>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-605c8685
        >
          <div class="w-full lg:w-1/2" data-v-605c8685>
            <div class="white-smiley link rich-text" data-v-605c8685>
              <h3>Wanna know more? Contact Hakim!</h3>
              <p>
                Hakim Tebusweke
                <br />
                Executive Creative Director
                <br />
                <a href="info@swekinteractive.com">Email me</a>
                <br />
                {/* <a href="tel:+4670-842 09 59">+4670 842 09 59</a> */}
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-605c8685>
            <div class="w-full pb-[70%] relative" data-v-605c8685>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243122/SWEK/images/illustrations/9_ovodlo.jpg"
                alt="Hakim Tebusweke, Cheif Creative Director Swek Interactive"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-605c8685
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PhiloCeo;
