import React from "react";

function GridFilm18() {
  return (
    <section class="slice bg-darkest custom-padding" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>04. Delivery</h4>
              <ul>
                <li>Web</li>
                <li>SoMe</li>
                <li>TV</li>
                <li>LED/Advertising signs</li>
                <li>Digital outdoor signs</li>
                <li>Bio</li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243356/SWEK/images/Others/final_blv17s.jpg"
                alt="Leverans Linus Sandvide"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GridFilm18;
