import React from "react";

function KalmaFolio7() {
  return (
    <section
      class="text-slice py-20 lg:py-40 bg-white mydark-bg"
      data-v-2ac86e56
    >
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Process
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2>Gen Organics</h2>
            <p className="mobile-small">
              Values:
              * 		Sustainability: Commitment to environmentally friendly practices and ingredients.  <br />
              <br/>
              * 		Health: Prioritizing the well-being of customers by offering safe and natural products.<br />
              <br/>
              * 		Integrity: Transparency and honesty in all business practices.<br />
              <br/>
* 		Quality: Ensuring excellence in product standards and customer satisfaction.
            </p>
            <p className="mobile-small">
              Brand Truth:
Gen Organics delivers on its promise of organic and natural ingredients, providing effective solutions for skincare needs while minimising environmental impact.
            </p>
            <p className="mobile-small">
              Functional Benefit:
Gen Organics offers organic soaps and Vaseline that cleanse, nourish, and protect the skin, suitable for both adults and kids.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KalmaFolio7;
