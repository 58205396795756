import React from "react";

function FolioDigitalThree13() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243397/SWEK/images/Rock-boom/11_bcrddi.jpg"
            alt="Mister York stortavla i Göteborg"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigitalThree13;
