import React from "react";

function DaThree() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Why data-driven?
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h4>Predict the future and build a strategy</h4>
            <p className="mobile-small">
              The analysis is not only based on the present but to an equal
              extent on the past and future. By combining data analysis with
              trend scouting, consumer trends, seasonal patterns and search
              trends,
              <strong> </strong>we can always be at the forefront and predict
              the future. Instead of going by gut feeling and old ingrained
              patterns, you can thereby set a sharp strategy and goals for the
              business year.
            </p>
            <h4>Digital goals, measurement points and conversions</h4>
            <p className="mobile-small">
              Setting goals, measurement points and leads for the business
              offline is a hygiene factor for most businesses. Digitally, it is
              not as obvious to many, although of course it should be. Our data
              analyzes always include the setting of digital goals and
              measurement points as well as ongoing conversion optimization.
            </p>
            <h4>Don't make decisions with your gut</h4>
            <p className="mobile-small">
              Gut feeling is good for lots of things, but not for defining
              target groups, which digital platforms they are on and which
              messages attract them. Through data-driven analysis, you can
              define, base your decisions and build your business plan based on
              real behaviors and situations instead of guessing.
              <strong> </strong>Many times you realize that what you imagined
              does not match reality at all, or you discover other interesting
              potential target groups along the way.
            </p>
            <h4>Base creativity on data-driven insights</h4>
            <p className="mobile-small">
              Creativity is important, very important even. But if you base your
              creativity in data-driven insights, you have a greater opportunity
              to prioritize where creativity actually does the most good.
              Instead of going by gut feeling, ensure the basics first. Even the
              most incredible, innovative and awesome creative concepts can fail
              if you don't understand the target audience and its digital
              behaviors. Because we receive all information in real time, we can
              also act very quickly if an exciting opportunity arises in the
              form of an event or trend.
            </p>
            <h4>Maxed conversion</h4>
            <p className="mobile-small">
              Since it is possible to follow the visitor from the first
              interaction and every step up to an actual conversion, it is
              possible to identify and evaluate what happens along the way. It
              also means that we can eliminate obstacles and thresholds and thus
              increase the conversion rate.
            </p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DaThree;
