import React from "react";

function PhiloMessage1() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Contact
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div
            class="black-bg green-smiley content rich-text custom-padding"
            data-v-2ac86e56
          >
            <h2>Allow us to be your next partner in creativity.</h2>
            <p className="mobile-small">
              Whether you're looking for a companion to conquer the world or
              simply have a question, we value staying connected. Feel free to
              reach out – we're just an email or phone call away, always ready
              to assist.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PhiloMessage1;
