import React from "react";

function FolioDigital7() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/764544b7-3a1a-4712-8dc3-c47b87154340_kff_2022_kampanjer_1x1_3.jpg?auto=compress,format&rect=0,0,2500,2500&w=1500&h=1500"
            alt="Carl Gustafsson förlänger avtal vid bensinpump"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/9611fb12-aaba-4a28-a788-635f5e6ab5da_kff_2022_kampanjer_1x1_4.jpg?auto=compress,format&rect=0,0,2500,2500&w=1500&h=1500"
            alt="Resultat av kampanj på Twitter Carl Gustafsson Kalmar FF "
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigital7;
