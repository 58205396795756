import React from "react";

function HayFolio20() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <img
              loading="lazy"
              src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243318/SWEK/images/Uganda-waragi/16_tqsehc.jpg"
              alt="Swek interactive - Uganda waragi"
              class="absolute inset-0 object-cover h-full w-full"
            />
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723244346/SWEK/images/Uganda-waragi/ug-ad2_nn67kf.mp4"
              autoplay
              loop
            ></video>
          </figure>
        </div>
      </div>
    </section>
  );
}

export default HayFolio20;
