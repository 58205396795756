import React from "react";

function InnerDesign5() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>Get to know Brandpad</h4>
              <p className="mobile-small">
                Take a look at{" "}
                <a target="_blank" rel="noopener">
                  Swek Interactive graphic profile{" "}
                </a>
                as it appears in Brandpad. Contact us and we will tell you more.
              </p>
              <p></p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243122/SWEK/images/Uzawi/9_z6qtnd.jpg"
                alt="Wilson Creative Brandpad"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerDesign5;
