import React from "react";

function DaDigital1() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Map and compass for digital communication
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>What is a digital strategy?</h2>
            <p className="mobile-small">
              Digital strategies can look many different ways depending on the market, organization and level of ambition. However, the format does not have to be very advanced and it is important not to make it all unnecessarily difficult, the best strategy is the one used. The digital strategy may also need to be divided into several different parts, in order to define and clarify target groups and communication goals for, for example, the website, social media and newsletters.{" "}
            </p>
            <p className="mobile-small">
              If you don't have a strategy, you often lack goals and thus direction when it comes to various communication efforts. It becomes difficult to prioritize and, moreover, the content often suffers. With a digital strategy in place, you get a better map and the organization can more easily pull in the same direction.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DaDigital1;
