import React from 'react'

function FolioTechTwo2() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
                                <div class="wrapper lg:flex" data-v-2ac86e56>
                                    <div class="lg:w-3/12" data-v-2ac86e56>
                                        <div class="transform translate-y-10 opacity-0" data-v-2ac86e56><span class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight" data-v-2ac86e56>Intro</span></div>
                                    </div>
                                    <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
                                        <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
                                            <h2>Framtiden är ljus</h2>
                                            <p>Bravura är ett bemannings- och rekryteringsföretag, som specialiserar sig mot människor i början av karriären. De hjälper även företag att hitta nya medarbetare, och matchar ihop rätt kompetens med rätt uppgift.
                                                Sedan starten 2008 i en källare på KTH, har Bravura gjort framtiden ljus för många duktiga talanger.</p>
                                            <p>Bravuras och Wilsons samarbete började med en ny webb för Bravura Sverige och har fortsatt med design, illustrationer, en uppdaterad grafisk profil och ännu mer webb för internationella avknoppningar.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioTechTwo2
