import React from "react";

function SwekMessage5() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              02/02/04. Freedom
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h4>Free Swek</h4>
            <p className="mobile-small">
              The business remains unaffected by the commitment and drive of its employees, maintaining a consistent trajectory. Stability is the norm. We embrace manuals whenever possible. Our emphasis lies in independence, viewing each project as a routine task without substantial opportunities for self-development or customer advancement.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SwekMessage5;
