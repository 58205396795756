import React from "react";

function StoriesHome() {
  return (
    <section class="slice relative home-reset" data-v-06c40047>
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243203/SWEK/images/Headers/stories_kezpqq.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243190/SWEK/images/Headers/stories2_iznsu5.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case mobile"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">09.Stories</h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Stories</span>
            </span>
          </h2>
        </div>
      </div>
    </section>
  );
}

export default StoriesHome;
