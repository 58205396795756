import React from "react";
import {Outlet, Link} from 'react-router-dom'

function GaDigital8() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Advertising on Google gives results quickly</h3>
              <p className="mobile-small">
                Digital marketing can be divided into three different categories in terms of results. In the long term, relevant content and{" "}
                <Link to='/techseo'><a>SEO</a></Link> is the big winner. It does, however, require a certain amount of patience to work organically in that way, but is of course worth it when the ranking on Google does increase. In the medium term,{" "}
                <Link to='/digitalsocialmedia'><a>
                   social media advertising
                </a></Link>
                 is  interesting. Here you advantageously combine sales and brand-strengthening communication. To quickly generate traffic to your web page, keyword advertising with{" "}
                <Link to='/googleads'><a>
                  Google Ads </a></Link>{" "}
                is the big winner. A well-balanced combination of SEO, SoMe and Google Ads is often the best solution. Contact us and we'll take a look at how we can take your digital marketing to the next level.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/182ed543-f289-4823-9444-e9e4e09ac2fa_Modell_test.png?auto=compress,format"
                alt="Mediemixens effekt på kort, medel och lång sikt med Google Ads, Sociala medier och SEO."
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
        <Outlet/>
      </div>
    </section>
  );
}

export default GaDigital8;
