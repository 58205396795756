import React from "react";

function TechUX1() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243129/SWEK/images/Headers/tech/swek-ux-ui_ckb5is.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Agency for UX & UI design
            </h1>
          </div>
        </div>

        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">We</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">are</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">experts</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">in</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">UX</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">&</span>
            </span>
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">UI</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl ">
              <p>
                <span class="ingress">
                  UX, or User Experience, is about making everyday life easier
                  through a good user experience.
                </span>{" "}
              </p>
              <p className="mobile-small">
                Designing digital services and user experiences that people like
                is a lot about understanding how they think, feel and act. By
                getting to know the users and finding out how a product or
                service is actually used, Swek Interactive's UX experts can
                create an optimal experience with the greatest possible benefit.
                In short, UX design is about using different methods, collected
                in what is called a UX process, to ensure that a service or
                product creates the best possible user experience on your
                website, app or other digital platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechUX1;
