import React from "react";

function TechWebdev5() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>uzawi.com</h4>
              <p className="mobile-small">
                Empowering decision-making through accessible data.{" "}
                <a
                  target="_blank"
                  rel="noopener"
            
                >
                  uzawi.com
                </a>
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="./images/Uzawi/AppLogo.jpg"
                alt="Webbcase sporrongeriksson.se | Webbutveckling"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechWebdev5;
