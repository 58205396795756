import React from "react";
import { Outlet, Link } from "react-router-dom";

function TechBulliten() {
  return (
    <section class="slice bg-darkest custom-padding" data-v-605c8685>
      <div class="wrapper" data-v-605c8685>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-605c8685
        >
          <div class="w-full lg:w-1/2" data-v-605c8685>
            <div class="green-smiley rich-text" data-v-605c8685>
              <h3>Why should I choose Swek Interactive as a web agency?</h3>
              <ul>
                <li className="mobile-small">
                  We have worked with{" "}
                  <Link to="/techuxdesign">
                    {" "}
                    <a>webb-design.</a>
                  </Link>{" "}
                  We have been working in web development since 2015 and have
                  several pixel-perfect websites under our belt.
                </li>
                <li className="mobile-small">
                  We have a complete and experienced team of web designers, web
                  developers, data analysts, and SEO experts on board.{" "}
                  <Link to="/contact">
                    <a>at our offices</a>.
                  </Link>
                </li>
                <li className="mobile-small">
                  We believe in sustainable web development and code{" "}
                  <Link to="/stories">
                    <a>energy-efficient websites.</a>
                  </Link>{" "}
                  using the latest technologies.
                </li>
                <li className="mobile-small">
                  We work long-term and transparently with our{" "}
                  <Link to="/project">
                    <a>client's</a>
                  </Link>{" "}
                  business goals in focus.
                </li>
                <li className="mobile-small">
                  We have won awards competitions.{" "}
                  <Link to="/stories">
                    <a>Agency of the Year 2023.</a>
                  </Link>
                  . A competition that measures customer satisfaction.
                </li>

                <li className="mobile-small">
                  We are not just a web agency; Swek Interactive is a complete{" "}
                  <Link to="/project">
                    <a>full-service Design Agency</a>.
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-605c8685>
            <div class="w-full pb-[70%] relative" data-v-605c8685>
              <video
                class="absolute inset-0 object-cover h-full w-full"
                controls
                src="../images/Others/Bottle-R.mp4"
                autoPlay
                loop
                muted
              ></video>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default TechBulliten;
