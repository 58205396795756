import React from 'react'

function FolioTechFour8() {
  return (
     <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/643e49c1-b595-460f-a90c-c6dbb6676737_HaileyHR_2022_case_1x1_2.jpg?auto=compress,format" alt="" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/60806792-ab30-4da4-8709-16c490a854e8_HaileyHR_2022_case_1x1_3.jpg?auto=compress,format" alt="" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTechFour8
