import React, {useEffect} from 'react'
import TechHosting1 from '../../pageSections/TechSections/Hosting/TechHosting1'
import TechHosting2 from '../../pageSections/TechSections/Hosting/TechHosting2'
import TechHosting3 from '../../pageSections/TechSections/Hosting/TechHosting3'
import TechHosting4 from '../../pageSections/TechSections/Hosting/TechHosting4'
import TechHosting5 from '../../pageSections/TechSections/Hosting/TechHosting5'
import TechHosting6 from '../../pageSections/TechSections/Hosting/TechHosting6'
import TechHosting7 from '../../pageSections/TechSections/Hosting/TechHosting7'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function TechHosting() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Hosting</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <TechHosting1 />
          <TechHosting2 />
          <TechHosting3 />
          <TechHosting4 />
          <TechHosting5 />
          <TechHosting6 />
          <TechHosting7 />
        </div>
      </div>
    </main>
  )
}

export default TechHosting
