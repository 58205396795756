import React from "react";

function ContactIcons() {
  return (
    <section class="slice">
      <div class="wrapper">
        <div class="flex flex-wrap -mx-5 lg:-mx-10 -mb-14 md:mb-0">
          <div class="w-full md:w-1/2 px-5 lg:px-10 mb-14 md:mb-0">
            <div class="w-full relative pb-[100%]">
              <img
                src="https://images.prismic.io/wilsoncreative/c0b5938f-af8a-4ddc-ba33-70e70d5c07ad_Kontakt_kalmar.png?auto=compress,format"
                alt="Swek-interactive"
                class="w-full h-full object-cover absolute inset-0 transform scale-90 origin-center"
              />
            </div>
            <div class="mt-8">
              <div class="rich-text green-smiley transform translate-y-10 opacity-0">
                <h4>Buziga</h4>
                <p>
                  Buziga Kampala, Uganda
                  <br />
                  <br />
                  <a href="mailto:info@swekinteractive.com">
                    info@swekinteractive.com
                  </a>
                  <br />
                  {/* <a href="tel:+46480-36 37 70">0480-36 37 70</a>{" "} */}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 px-5 lg:px-10 mb-14 md:mb-0">
            <div class="w-full relative pb-[100%]">
              <img
                src="https://images.prismic.io/wilsoncreative/70b7b15b-c73e-4d1f-b5d7-e4e6e62f824a_Kontakt_stockholm.png?auto=compress,format"
                alt="Swek-interactive"
                class="w-full h-full object-cover absolute inset-0 transform scale-90 origin-center"
              />
            </div>
            <div class="mt-8">
              <div class="rich-text green-smiley transform translate-y-10 opacity-0">
                <h4>Uk</h4>
                <p>
                 Chrisp St. E14 6LL
                  <br />
                  <br />
                  <a href="mailto:info@swekinteractive.com">
                    info@swekinteractive.com
                  </a>
                  <br />
                  {/* <a target="_blank" rel="noopener" href="tel:+46850165660">
                    08-501 656 60
                  </a> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactIcons;
