import React from "react";

function OurPolicy2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
             Information
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>Swek Interactive is the data controller</h3>
            <p>Here is our complete information:</p>
            <p className="mobile-small">
              Swek Interactive AB
              <br />
              
            </p>
          
            <p></p>
            <h3>
              We collect contact information through our forms and set cookies in your browser
            </h3>
            <p className="mobile-small">
              Read more about each data collection below, under
              <em>personal information submitted through our forms</em> and 
              <em>cookies that save your IP address and track your visit</em>.
            </p>
            <p></p>
            <h3>
              We store your information within the European Economic Area (EEA), but we may also transfer data to countries outside the EEA.
            </h3>
            <p className="mobile-small">
              All such transfer of your personal data is done in accordance with applicable European legislation. This includes, for example, Google handling data from you on servers around the world. Contact information that you submit via forms is only stored on our own server here in Sweden.
            </p>
            <p></p>
            <h3>We share your information with some others.</h3>
            <p className="mobile-small">
              We use an external partner for our servers, one to analyze user behavior, and one to optimize our marketing towards other companies. Data forwarded to a third party is solely used to provide our services to you. We never exchange or sell your information for others to use.
            </p>
            <p></p>
            <h3>
              You have extensive rights to modify or delete your information.
            </h3>
            <p className="mobile-small">
              You can request a copy of the data we have stored about you at any time and have it modified or deleted. If you have given consent to any data collection, you also have the right to withdraw your consent at any time. Send us an email at
              <a
                target="_blank"
                rel="noopener"
                href="mailto:info@wilsoncreative.se"
              >
                info@wilsoncreative.se
              </a>
               så hjälper vi till! 
              <a
                target="_blank"
                rel="noopener"
                href="https://www.imy.se/privatperson/dataskydd/dina-rattigheter/"
              >
                Läs mer om dina rättigheter hos Datainspektionen här. 
              </a>
            </p>
            <p className="mobile-small">
              Du har också rätt att klaga hos Datainspektionen om du anser att
              vi på något sätt har hanterat dina uppgifter felaktigt. 
              <a
                target="_blank"
                rel="noopener"
                href="https://www.datainspektionen.se/vagledningar/for-dig-som-privatperson/klagomal-och-tips/"
              >
                Läs mer om hur du gör hos Datainspektionen här.
              </a>
            </p>
            <p></p>
            <h3>Personuppgifter som skickas in via våra formulär</h3>
            <p className="mobile-small">
              Vi har tre kontaktformulär: 
              <em>Starta ett projekt, Skicka en hälsning</em> och 
              <em>Jobba hos oss. </em>I dem samlar vi in dina kontaktuppgifter.
              Ditt IP-nummer sparas också.
            </p>
            <p className="mobile-small">
              I alla tre fall räknar vi med att du vill ha kontakt med oss. Den
              så kallade <em>rättsliga grunden</em> för insamlingen är alltså en
              intresseavvägning, där vi tror att ditt intresse för att vi sparar
              uppgifterna och kan kontakta dig väger tyngre än risken att dina
              rättigheter kränks av insamlingen. Därför behöver du inte heller
              lämna något uttryckligt samtycke till insamlingen.
            </p>
            <p className="mobile-small">
              Uppgifterna lagras på vår server, som vi hyr av en tredje part
              baserad i Sverige. Ett mail skickas också till vår 
              <a
                target="_blank"
                rel="noopener"
                href="mailto:info@wilsoncreative.se"
              >
                info@wilsoncreative.se
              </a>
              -adress, som tas bort efter att ärendet har hanterats.
            </p>
            <p></p>
            <h3>Kakor som sparar ditt IP-nummer och spårar ditt besök</h3>
            <p className="mobile-small">
              När du besöker Wilsoncreative.se sätter vi några kakor i din
              webbläsare. De används dels för att analysera användarbeteende i
              Google Analytics, dels för att se vilka företag som besöker sajten
              och kunna marknadsföra oss mot dem. Kakorna registrerar ditt
              IP-nummer och genererar ett pseudonymiserat användar-ID hos våra
              partners.
            </p>
            <p className="mobile-small">
              Eftersom uppgifterna inte är känsliga och vi inte marknadsför oss
              mot privatpersoner, samlar vi in uppgifterna med intresseavvägning
              som rättslig grund. Vi gör bedömningen att vårt intresse av att
              kunna optimera hemsidan och marknadsföra våra tjänster till andra
              företag väger tyngre än den registrerades intresse att skydda just
              dessa uppgifter.
            </p>
            <p className="mobile-small">
              Kakorna sätts i din webbläsare, och datan de genererar skickas
              dels till Google, dels till vår partner för B2B-marknadsföring.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurPolicy2;
