import React from "react";

function TechEcommerce7() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>A selection of previous UX/UI projects.</h4>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.Aamaka.com"
                  >
                    aamaka.com
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.genorganics.com"
                  >
                    genorganics.com
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.uzawi.com"
                  >
                    uzawi.com
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener" href="https://swekinteractive.com">
                    swekinteractive.com
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www. nonivie.com"
                  >
                    nonivie.com
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.rockboom.com"
                  >
                    rockboom.com
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.ugandawaragi.com"
                  >
                    ugandawaragi.com
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.auten.com"
                  >
                    auten.com
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.supremacysounds.com"
                  >
                    supremacysounds.com
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.smoooo.com"
                  >
                    smoooo.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="../images/Others/e-com.png"
                alt="Ux-design för webbshop"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechEcommerce7;
