import React, {useEffect} from 'react'
import '../styles/Studio.css'
import StudioHome from '../pageSections/StudioSections/StudioHome'
import StudioVideo from '../pageSections/StudioSections/StudioVideo'
import StudioMessage from '../pageSections/StudioSections/StudioMessage'
import StudioServiceGrid from '../pageSections/StudioSections/StudioServiceGrid'
import StudioMessage2 from '../pageSections/StudioSections/StudioMessage2'
import StudioVideo2 from '../pageSections/StudioSections/StudioVideo2'
import StudioMessage3 from '../pageSections/StudioSections/StudioMessage3'
import StudioFood from '../pageSections/StudioSections/StudioFood'
import Form from '../UniSections/Form'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Studio() {


  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Studio</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
                  <StudioHome />
                  <StudioVideo />
                  <StudioMessage />
                  <StudioServiceGrid />
                  <StudioMessage2 />
                  <StudioVideo2 />
                  <StudioMessage3 />
                  <StudioFood />
                  <Form />
                  
        </div>
      </div>
    </main> 
  )
}

export default Studio
