import React from "react";

function FolioTechFive2() {
  return (
    <section
      class="text-slice py-20 lg:py-40 bg-white rashid"
      data-v-2ac86e56
    >
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Intro
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2>Brand Overview:</h2>
            <p className="mobile-small">
              Chef Rashid is a culinary virtuoso, renowned for crafting exquisite and innovative dishes. With a commitment to culinary excellence, Chef Rashid's brand embodies a harmonious blend of creativity, passion, and culinary expertise.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioTechFive2;
