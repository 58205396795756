import React from "react";

function FolioTechThree2() {
  return (
    <section
      class="text-slice py-20 lg:py-40 bg-white mydark-bg"
      data-v-2ac86e56
    >
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56
            >
              Intro
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2>En professionell lekfullhet</h2>
            <p>
              Myrstack är ett techbolag med vass kompetens och höga ambitioner.
              Företaget utvecklar förstklassiga mjukvaror i molnet för kunder
              som PayPal, Tink och Resurs Bank. Vi fick i uppdrag att ta fram en
              riktigt krispig webb som ska attrahera både nya kunder och
              medarbetare.
            </p>
            <p>
              Resultatet blev en hel myrvärld – från yttre rymden till myrgångar
              i underjorden – där Myrstack presenteras på ett intresseväckande
              och dynamiskt sätt. Vi illustrerade och animerade även en rad
              myror som guidar besökaren runt om på sajten. Lekfullt men
              samtidigt väldigt professionellt och seriöst.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioTechThree2;
