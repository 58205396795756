import React from "react";

function FolioDigitalTwo3() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/438cfc0e-30b2-49ce-911f-00dbc75fccea_KE_LOGO_LOOP_1200x1200_optimerad.gif?auto=compress,format"
            alt="Energirepubiliken.se"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/c3a3ae97-4a30-4b1c-b335-3b8dab1f9c16_KE_STUFF_LOOP_1200x1200.gif?auto=compress,format"
            alt="Energirepubliken"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigitalTwo3;
