import React from "react";
import { Outlet, Link } from 'react-router-dom'
import Marquee from "react-fast-marquee";

function ProjectFolio() {
  return (
    <div class="flex flex-wrap">
      <div class="w-full lg:w-1/2">
        <Link to="/digitalfolio3">
          <a class="block w-full pb-[56%] relative transform origin-center group">
            <div class="w-full h-full overflow-hidden absolute inset-0 pointer-events-none">
              <div class="w-full pb-[56%] relative">
                <picture>
                  <source
                    srcset="./images/Rock-boom/1.jpg"
                    media="(min-width: 650px)"
                  />{" "}
                  <img
                    src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243205/SWEK/images/Rock-boom/1_mlfzfs.jpg"
                    alt="brackediakoni.se på en laptop mot ljuslila bakgrund"
                    loading="lazy"
                    class="w-full h-full object-cover absolute inset-0 case"
                  />
                </picture>
              </div>
            </div>
            <div class="wrapper absolute left-0 right-0 mx-auto bottom-8 md:bottom-12 lg:bottom-16 z-20 group-hover:opacity-0 duration-500 ease-out-expo transform group-hover:translate-y-10">
              <div class="flex items-center text-xl">
                Rock Boom
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-3 block text-laser"
                >
                  <path
                    d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="w-full h-full absolute inset-0 bg-darkest-overlay bg-opacity-50 flex items-center opacity-0 group-hover:opacity-100 duration-500 transition-all ease-out-expo">
              <div class="w-full overflow-hidden">
                <div
                  class="overflow-hidden w-full leading-tight transform translate-y-20 group-hover:translate-y-0 duration-1000 transition-all ease-out-expo"
                  data-v-32a13d1f
                >
                  <Marquee>
                    <div
                      class="scroll-text whitespace-nowrap transform pointer-events-none inline-block text-[4.5vw]"
                      data-v-32a13d1f
                    >
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>{" "}
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </a>
        </Link>
      </div>

      <div class="w-full lg:w-1/2">
        <Link to="/hayfolio">
          <a class="block w-full pb-[56%] relative transform origin-center group">
            <div class="w-full h-full overflow-hidden absolute inset-0 pointer-events-none">
              <div class="w-full pb-[56%] relative">
                <picture>
                  <source
                    srcset="./images/Uganda-waragi/15.jpg"
                    media="(min-width: 650px)"
                  />{" "}
                  <img
                    src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243298/SWEK/images/Uganda-waragi/15_rhazsx.jpg"
                    alt="Myrstack.tech i mobilvy mot stjärnklar rymd"
                    loading="lazy"
                    class="w-full h-full object-cover absolute inset-0 case"
                  />
                </picture>
              </div>
            </div>
            <div class="wrapper absolute left-0 right-0 mx-auto bottom-8 md:bottom-12 lg:bottom-16 z-20 group-hover:opacity-0 duration-500 ease-out-expo transform group-hover:translate-y-10">
              <div class="flex items-center text-xl">
                Uganda Waragi
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-3 block text-laser"
                >
                  <path
                    d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="w-full h-full absolute inset-0 bg-darkest-overlay bg-opacity-50 flex items-center opacity-0 group-hover:opacity-100 duration-500 transition-all ease-out-expo">
              <div class="w-full overflow-hidden">
                <div
                  class="overflow-hidden w-full leading-tight transform translate-y-20 group-hover:translate-y-0 duration-1000 transition-all ease-out-expo"
                  data-v-32a13d1f
                >
                  <Marquee>
                    <div
                      class="scroll-text whitespace-nowrap transform pointer-events-none inline-block text-[4.5vw]"
                      data-v-32a13d1f
                    >
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>{" "}
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </a>
        </Link>
      </div>

      <div class="w-full lg:w-1/2">
        <Link to="/dillfolio">
          <a class="block w-full pb-[56%] relative transform origin-center group">
            <div class="w-full h-full overflow-hidden absolute inset-0 pointer-events-none">
              <div class="w-full pb-[56%] relative">
                <picture>
                  <source
                    srcset="./images/Aamaka/1.jpg"
                    media="(min-width: 650px)"
                  />{" "}
                  <img
                    src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243107/SWEK/images/Aamaka/1_aypwil.jpg"
                    alt="Mister York skylt - en del av den grafiska profilen"
                    loading="lazy"
                    class="w-full h-full object-cover absolute inset-0 case"
                  />
                </picture>
              </div>
            </div>
            <div class="wrapper absolute left-0 right-0 mx-auto bottom-8 md:bottom-12 lg:bottom-16 z-20 group-hover:opacity-0 duration-500 ease-out-expo transform group-hover:translate-y-10">
              <div class="flex items-center text-xl">
                Aamaka
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-3 block text-laser"
                >
                  <path
                    d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="w-full h-full absolute inset-0 bg-darkest-overlay bg-opacity-50 flex items-center opacity-0 group-hover:opacity-100 duration-500 transition-all ease-out-expo">
              <div class="w-full overflow-hidden">
                <div
                  class="overflow-hidden w-full leading-tight transform translate-y-20 group-hover:translate-y-0 duration-1000 transition-all ease-out-expo"
                  data-v-32a13d1f
                >
                  <Marquee>
                    <div
                      class="scroll-text whitespace-nowrap transform pointer-events-none inline-block text-[4.5vw]"
                      data-v-32a13d1f
                    >
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>{" "}
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </a>
        </Link>
      </div>

      <div class="w-full lg:w-1/2">
        <Link to="/techfolio5">
          <a class="block w-full pb-[56%] relative transform origin-center group">
            <div class="w-full h-full overflow-hidden absolute inset-0 pointer-events-none">
              <div class="w-full pb-[56%] relative">
                <picture>
                  <source
                    srcset="./images/Rashid/7.jpg"
                    media="(min-width: 650px)"
                  />{" "}
                  <img
                    src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243171/SWEK/images/Rashid/7_rpfmgw.jpg"
                    alt="Dillbergs logotyp och mönster i en bok"
                    loading="lazy"
                    class="w-full h-full object-cover absolute inset-0 case"
                  />
                </picture>
              </div>
            </div>
            <div class="wrapper absolute left-0 right-0 mx-auto bottom-8 md:bottom-12 lg:bottom-16 z-20 group-hover:opacity-0 duration-500 ease-out-expo transform group-hover:translate-y-10">
              <div class="flex items-center text-xl">
                Chef Rashid
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-3 block text-laser"
                >
                  <path
                    d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="w-full h-full absolute inset-0 bg-darkest-overlay bg-opacity-50 flex items-center opacity-0 group-hover:opacity-100 duration-500 transition-all ease-out-expo">
              <div class="w-full overflow-hidden">
                <div
                  class="overflow-hidden w-full leading-tight transform translate-y-20 group-hover:translate-y-0 duration-1000 transition-all ease-out-expo"
                  data-v-32a13d1f
                >
                  <Marquee>
                    <div
                      class="scroll-text whitespace-nowrap transform pointer-events-none inline-block text-[4.5vw]"
                      data-v-32a13d1f
                    >
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>{" "}
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </a>
        </Link>
      </div>

      <div class="w-full lg:w-1/2">
        <Link to="/strifefolio">
          <a class="block w-full pb-[56%] relative transform origin-center group">
            <div class="w-full h-full overflow-hidden absolute inset-0 pointer-events-none">
              <div class="w-full pb-[56%] relative">
                <picture>
                  <source
                    srcset="./images/Uzawi/AppLogo.jpg"
                    media="(min-width: 650px)"
                  />{" "}
                  <img
                    src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243131/SWEK/images/Uzawi/AppLogo_qdbzon.jpg"
                    alt="Strife logotyp"
                    loading="lazy"
                    class="w-full h-full object-cover absolute inset-0 case"
                  />
                </picture>
              </div>
            </div>
            <div class="wrapper absolute left-0 right-0 mx-auto bottom-8 md:bottom-12 lg:bottom-16 z-20 group-hover:opacity-0 duration-500 ease-out-expo transform group-hover:translate-y-10">
              <div class="flex items-center text-xl">
                Uzawi
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-3 block text-laser"
                >
                  <path
                    d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="w-full h-full absolute inset-0 bg-darkest-overlay bg-opacity-50 flex items-center opacity-0 group-hover:opacity-100 duration-500 transition-all ease-out-expo">
              <div class="w-full overflow-hidden">
                <div
                  class="overflow-hidden w-full leading-tight transform translate-y-20 group-hover:translate-y-0 duration-1000 transition-all ease-out-expo"
                  data-v-32a13d1f
                >
                  <Marquee>
                    <div
                      class="scroll-text whitespace-nowrap transform pointer-events-none inline-block text-[4.5vw]"
                      data-v-32a13d1f
                    >
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>{" "}
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </a>
        </Link>
      </div>

      <div class="w-full lg:w-1/2">
        <Link to="/kalmafolio">
          <a class="block w-full pb-[56%] relative transform origin-center group">
            <div class="w-full h-full overflow-hidden absolute inset-0 pointer-events-none">
              <div class="w-full pb-[56%] relative">
                <img
                  src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243248/SWEK/images/GenOrganics/1_cwp9pr.jpg"
                  alt="Kalmar FF:s nya emblem, formgivet av Wilson Creative."
                  loading="lazy"
                  class="w-full h-full object-cover absolute inset-0 case"
                ></img>
              </div>
            </div>
            <div class="wrapper absolute left-0 right-0 mx-auto bottom-8 md:bottom-12 lg:bottom-16 z-20 group-hover:opacity-0 duration-500 ease-out-expo transform group-hover:translate-y-10">
              <div class="flex items-center text-xl">
                Gen Organics
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-3 block text-laser"
                >
                  <path
                    d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
            <div class="w-full h-full absolute inset-0 bg-darkest-overlay bg-opacity-50 flex items-center opacity-0 group-hover:opacity-100 duration-500 transition-all ease-out-expo">
              <div class="w-full overflow-hidden">
                <div
                  class="overflow-hidden w-full leading-tight transform translate-y-20 group-hover:translate-y-0 duration-1000 transition-all ease-out-expo"
                  data-v-32a13d1f
                >
                  <Marquee>
                    <div
                      class="scroll-text whitespace-nowrap transform pointer-events-none inline-block text-[4.5vw]"
                      data-v-32a13d1f
                    >
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          <span data-v-32a13d1f> </span>Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>{" "}
                      <span class="inline-block" data-v-32a13d1f>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                        <span class="whitespace-nowrap" data-v-32a13d1f>
                          Explore Project
                          <span class="slash" data-v-32a13d1f>
                            /
                          </span>
                        </span>
                      </span>
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </a>
        </Link>
      </div>

      {/* Pausing the  projects above */}
      <Outlet />
    </div>
  );
}

export default ProjectFolio;
