import React from "react";

function GoogleAdsHome() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243124/SWEK/images/Headers/digital/swek-ads_ejennw.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Google Ads agency for advertising on Google
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Google</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Ads</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  Keyword advertising with Google Ads is smart and effective.
                  You are only visible to those who actively type your keywords.
                </span>
              </p>
              <p className="mobile-small">
                In addition, you only pay if someone clicks on your ad. This
                makes advertising on Google suitable for most companies that
                want to increase their revenue and profitability, regardless of
                size and budget.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GoogleAdsHome;
