import React from "react";
import {Outlet, Link} from 'react-router-dom'

function TechSeo8() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>SEO and content are the big winners in the long run</h3>
              <p className="mobile-small">
                To quickly create quality traffic to your website, we recommend{" "} 
                <Link to='/googleads'><a>Google Ads</a></Link>. In the medium term{" "}
                <Link to='/digitalsocialmedia'><a>social media</a></Link>{" "}
                advertising is interesting. In the long term, quality content and good SEO are always the big winners, but you have to be patient because the effect is not immediate. A well-balanced combination of search engine optimization, Ads and SoMe is often the best solution.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/182ed543-f289-4823-9444-e9e4e09ac2fa_Modell_test.png?auto=compress,format"
                alt="Sökmotoroptimering ger effekt på sikt"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
              </div>
              <Outlet/>
      </div>
    </section>
  );
}

export default TechSeo8;
