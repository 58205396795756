import React from 'react'
import OurPolicy1 from '../../UniSections/FormInner/OurPolicy1'
import OurPolicy2 from '../../UniSections/FormInner/OurPolicy2'

function OurPolicy() {
  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <OurPolicy1 />
            <OurPolicy2 />
        </div>
      </div>
    </main>
  )
}

export default OurPolicy
