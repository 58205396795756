import React, {useEffect} from 'react'
import DigitalContentHome from '../../pageSections/DigitalSections/Inner-Content/DigitalContentHome'
import CoDigital1 from '../../pageSections/DigitalSections/Inner-Content/CoDigital1'
import CoDigital2 from '../../pageSections/DigitalSections/Inner-Content/CoDigital2'
import CoDigital3 from '../../pageSections/DigitalSections/Inner-Content/CoDigital3'
import CoDigital4 from '../../pageSections/DigitalSections/Inner-Content/CoDigital4'
import CoDigital5 from '../../pageSections/DigitalSections/Inner-Content/CoDigital5'
import CoDigital6 from '../../pageSections/DigitalSections/Inner-Content/CoDigital6'
import CoDigitalFolio7 from '../../pageSections/DigitalSections/Inner-Content/CoDigitalFolio7'
import CoDigital8 from '../../pageSections/DigitalSections/Inner-Content/CoDigital8'
import CoDigital9 from '../../pageSections/DigitalSections/Inner-Content/CoDigital9'
import CoDigital10 from '../../pageSections/DigitalSections/Inner-Content/CoDigital10'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Content() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Content</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
           <DigitalContentHome /> 
            <CoDigital1 />      
            <CoDigital2 />      
            <CoDigital3 />      
            <CoDigital4 />      
            <CoDigital5 />      
            <CoDigital6 />      
            {/* <CoDigitalFolio7 />    */}
            <CoDigital8 />      
            <CoDigital9 />      
            <CoDigital10 />      
        </div>
      </div>
    </main> 
  )
}

export default Content
