import React, {useEffect} from 'react'
import KalmaFolio1 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio1'
import KalmaFolio2 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio2'
import KalmaFolio3 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio3'
import KalmaFolio4 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio4'
import KalmaFolio5 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio5'
import KalmaFolio6 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio6'
import KalmaFolio7 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio7'
import KalmaFolio8 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio8'
import KalmaFolio9 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio9'
import KalmaFolio10 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio10'
import KalmaFolio11 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio11'
import KalmaFolio12 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio12'
import KalmaFolio13 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio13'
import KalmaFolio14 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio14'
import KalmaFolio15 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio15'
import KalmaFolio16 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio16'
import KalmaFolio17 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio17'
import KalmaFolio18 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio18'
import KalmaFolio19 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio19'
import KalmaFolio20 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio20'
import KalmaFolio21 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio21'
import KalmaFolio22 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio22'
import KalmaFolio23 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio23'
import KalmaFolio24 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio24'
import KalmaFolio25 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio25'
import KalmaFolio26 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio26'
import KalmaFolio27 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio27'
import KalmaFolio28 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio28'
import KalmaFolio29 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio29'
import KalmaFolio30 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio30'
import KalmaFolio31 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio31'
import KalmaFolio32 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio32'
import KalmaFolio33 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio33'
import KalmaFolio34 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio34'
import KalmaFolio35 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio35'
import KalmaFolio36 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio36'
import KalmaFolio37 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio37'
import KalmaFolio38 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio38'
import KalmaFolio39 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio39'
import KalmaFolio40 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio40'
import KalmaFolio41 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio41'
import KalmaFolio42 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio42'
import KalmaFolio43 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio43'
import KalmaFolio44 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio44'
import KalmaFolio45 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio45'
import KalmaFolio46 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio46'
import KalmaFolio47 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio47'
import KalmaFolio48 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio48'
import KalmaFolio49 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio49'
import KalmaFolio50 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio50'
import KalmaFolio51 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio51'
import KalmaFolio52 from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio52'
import KalmaFolio51a from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio51a'
import KalmaFolio51b from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio51b'
import KalmaFolio51c from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio51c'
import KalmaFolio51d from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio51d'
import KalmaFolio51e from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio51e'
import KalmaFolio51f from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio51f'
import KalmaFolio51g from '../../pageSections/BrandingSections/BrandFolio3/KalmaFolio51g'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function KalmaFolio() {

  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Gen Organics - Swek</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <KalmaFolio1 />
          <div data-fetch-key="PageFetch:0">
          <KalmaFolio2 />
          <KalmaFolio3 />
          <KalmaFolio4 />
          <KalmaFolio5 />
          <KalmaFolio6 />
          <KalmaFolio7 />
          <KalmaFolio8 />
          <KalmaFolio9 />
          <KalmaFolio10 />
          <KalmaFolio11 />
          <KalmaFolio12 />
          <KalmaFolio13 />
          <KalmaFolio14 />
          <KalmaFolio15 />
          <KalmaFolio16 />
          <KalmaFolio17 />
          <KalmaFolio18 />
          <KalmaFolio19 />
          <KalmaFolio20 />
          <KalmaFolio21 />
          <KalmaFolio22 />
          <KalmaFolio23 />
          <KalmaFolio24 />
          <KalmaFolio25 />
          <KalmaFolio26 />
          <KalmaFolio27 />
          <KalmaFolio28 />
          <KalmaFolio29 />
          <KalmaFolio30 />
          <KalmaFolio31 />
          <KalmaFolio32 />
          <KalmaFolio33 />
          <KalmaFolio34 />
          <KalmaFolio35 />
          <KalmaFolio36 />
          <KalmaFolio37 />
          <KalmaFolio38 />
          <KalmaFolio39 />
          <KalmaFolio40 />
          <KalmaFolio41 />
          <KalmaFolio42 />
          <KalmaFolio43 />
          <KalmaFolio44 />
          <KalmaFolio45 />
          <KalmaFolio46 />
          <KalmaFolio47 />
          <KalmaFolio48 />
          <KalmaFolio49 />
          <KalmaFolio50 />
          <KalmaFolio51 />
          <KalmaFolio51a />
          <KalmaFolio51b />
          <KalmaFolio51c />
          <KalmaFolio51d />
          <KalmaFolio51e />
          <KalmaFolio51f />
          <KalmaFolio51g />
          <KalmaFolio52 />
          </div>
        </div>
      </div>
    </main>
  )
}

export default KalmaFolio
