import React from "react";

function GridFilm12() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723243491/SWEK/images/Others/matano_htguv4.mp4"
              autoPlay
              loop
              muted
            ></video>
          </figure>
        </div>
      </div>
    </section>
  );
}

export default GridFilm12;
