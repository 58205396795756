import React from "react";

function InnerDesign2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              More than a logo
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Graphic profile</h2>
            <p className="mobile-small">
              A graphic profile can look many ways, but if we boil it down to
              the most essential, we usually find a logo, font/typography and
              colors. The logo is the outward face and should reflect
              distinctiveness and the company's values. Both fonts and colors
              are arranged in a clear hierarchy for different purposes. The
              watchword is clarity, but it also requires flexibility and a dose
              of playfulness, because a graphic profile should not be limiting
              but rather consist of inspiring possibilities. In addition to the
              logo, fonts and colors, you can advantageously add graphic
              elements, patterns, animations, image style and<strong> </strong>
              templates for different purposes: PowerPoint, letter,
              advertisement, email signature, business card, merchandise... Sky
              is the limit!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerDesign2;
