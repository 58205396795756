import React from "react";

function GaDigital6() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>YouTube advertising with Google Ads</h3>
              <p className="mobile-small">
                YouTube is today the obvious platform for video. Here, hundreds of hours of moving material are uploaded every minute and the site has several hundred million views daily. A perfect place for profitable and effective advertising thus. What makes YouTube extra interesting is its flexibility. Here there is the possibility of accurate target group adaptation but also broader advertising based on keywords, topics and channels. And of course it is possible to measure the results of YouTube advertising in the smallest detail and optimize the campaign on an ongoing basis for maximum effect and profitability. At Swek, you get help with the entire process: Everything from campaign setup, production of moving content, ongoing optimization and result reporting.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/e05901e4-7bbe-45e3-8863-3d71a54cce34_Yoytube.png?auto=compress,format"
                alt="YouTube logo"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GaDigital6;
