import React from "react";

function InnerBrand5() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>This is how we work with brand development</h3>
              <p className="mobile-small">
                At Swek Interactive, we take a holistic approach to brand
                development. We start by diving deep into your company's
                history, values ​​and ambitions. We make sure to understand your
                target group, your industry and the specific challenges you
                face. This comprehensive understanding underpins our creative
                process where we work together to create a distinctive and
                meaningful brand. Our process is inclusive, we believe in
                working closely with our clients to ensure that every aspect of
                the brand truly represents them and speaks to their target
                audience. Through this methodology, we not only create a brand,
                we create a lasting feeling and a story that touches.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243145/SWEK/images/Uzawi/6_apvj10.jpg"
                alt="Swek Branding"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerBrand5;
