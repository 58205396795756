import React from "react";

function DaFour() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>Listen to Moreen</h4>
              <p className="mobile-small">
                "With the help of Swek's customized reports, we quickly get a
                clear picture of how our marketing efforts are performing and
                can work effectively to achieve our goals. Swek is always
                professional, competent and wonderful to work with".
              </p>
              <p>Nice Moreen, Gen Organics</p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243107/SWEK/images/illustrations/Gen3_frohk4.jpg"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DaFour;
