import React from "react";

function FolioDigital23() {
  return (
    <section class="py-20 lg:py-40 bg-lapis" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="white-smiley link rich-text" data-v-7dc95f74>
              <h3 >Vill du veta mer? Kontakta Hasse!</h3>
              <p>
                Hasse Hofvergård
                <br />
                VD
                <br />
                <a href="mailto:hasse@wilsoncreative.se">Maila mig</a>
                <br />
                <a href="tel:070-842 09 59">070-842 09 59</a>
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/e1f187f8-a291-4d02-80e4-59827bf732fb_hasse1-800x800.jpeg?auto=compress,format"
                alt="Hasse Hofvergård, VD Wilson Creative"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital23;
