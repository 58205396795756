import React from "react";

function FolioDigitalThree8() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243466/SWEK/images/Rock-boom/6_c2ivfp.jpg"
            alt="Flaggor för Drive In och utfart"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigitalThree8;
