import React from "react";

function SmDigital1() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Marketing in Social Media - The Swek Way</h3>
              <ul>
                <li className="mobile-small">
                  We are experts in paid social and work with all social media platforms
                </li>
                <li className="mobile-small">
                  We adapt the social media strategy to your unique circumstances
                </li>
                <li className="mobile-small">
                 We make decisions with the help of careful data analysis, never gut feeling
                </li>
                <li className="mobile-small">
                  We make paid social a key part of your digital media mix
                </li>
                <li className="mobile-small">
                  To create security for our clients, we work completely transparently
                </li>
                
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="../images/Others/sm.jpg"
                alt="Annonsering i sociala medier - Årets byrå 2023"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital1;
