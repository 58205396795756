import React from 'react'

function FolioTechFour1() {
  return (
    <section class="pt-page pb-20 lg:pb-40">
                            <div class="wrapper lg:flex">
                                <div class="lg:w-3/12"><span class="label block mb-label">Projekt</span></div>
                                <div class="lg:w-9/12">
                                    <div class="huge-title">
                                        <h1>Hailey HR - HR tech som flyger</h1>
                                    </div>
                                </div>
                            </div>
                        </section>
  )
}

export default FolioTechFour1
