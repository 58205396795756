import React, {useEffect} from 'react'
import FolioTechTwo1 from '../../pageSections/TechSections/Inner-Folio-Tech-2/FolioTechTwo1'
import FolioTechTwo2 from '../../pageSections/TechSections/Inner-Folio-Tech-2/FolioTechTwo2'
import FolioTechTwo3 from '../../pageSections/TechSections/Inner-Folio-Tech-2/FolioTechTwo3'
import FolioTechTwo4 from '../../pageSections/TechSections/Inner-Folio-Tech-2/FolioTechTwo4'
import FolioTechTwo5 from '../../pageSections/TechSections/Inner-Folio-Tech-2/FolioTechTwo5'
import FolioTechTwo6 from '../../pageSections/TechSections/Inner-Folio-Tech-2/FolioTechTwo6'
import ReactGA from 'react-ga4'

function TechFolio2() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <FolioTechTwo1 /> 
          <div data-fetch-key="PageFetch:0">
            <FolioTechTwo2 />
            <FolioTechTwo3 />
            <FolioTechTwo4 />
            <FolioTechTwo5 />
            <FolioTechTwo6 />
            
          </div>
        </div>
      </div>
    </main>
  )
}

export default TechFolio2
