import React from 'react'

function OlandFolio6() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/6660d42d-0f0f-4bf4-a46b-0ba11042c96d_oland_16x9_logo_grid.png?auto=compress,format" alt="Öland logo i stödraster" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/09a72c1b-0d75-40a6-a93a-6b9b7e93b341_oland_16x9_logo.png?auto=compress,format" alt="Öland logo utan stödraster" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio6
