import React from 'react'

function FolioTechThree12() {
  return (
    <section class="py-20 lg:py-40 bg-lapis" data-v-7dc95f74>
                                <div class="wrapper" data-v-7dc95f74>
                                    <div class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10" data-v-7dc95f74>
                                        <div class="w-full lg:w-1/2" data-v-7dc95f74>
                                            <div class="white-smiley link rich-text" data-v-7dc95f74>
                                                <h2>Vill du veta mer? Kontakta Fredrika!</h2>
                                                <p>Fredrika Bylund<br/>Projektledare<br/><a href="mailto:fredrika@wilsoncreative.se">Maila mig</a><br/><a href="tel:073-592 86 24">073-592 86 24</a></p>
                                            </div>
                                        </div>
                                        <div class="w-full lg:w-1/2" data-v-7dc95f74>
                                            <div class="w-full pb-[70%] relative" data-v-7dc95f74><img src="https://images.prismic.io/wilsoncreative/67c06843-4bc2-418a-9997-e2568bdb09a5_fred+%281%29.png?auto=compress,format" alt="" class="w-full h-full inset-0 object-cover absolute" data-v-7dc95f74 /></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioTechThree12
