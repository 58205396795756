import React from "react";

function FolioTechFive16() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243322/SWEK/images/Rashid/r6_tcobep.jpg"
            alt="Promas copy1"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243451/SWEK/images/Rashid/r7_pq7olm.jpg"
            alt="Promas copy2"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioTechFive16;
