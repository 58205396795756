import React from "react";
import {Outlet, Link} from 'react-router-dom'

function StudioMessage3() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Photo
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text custom-padding" data-v-2ac86e56>
            <h2>Say cheese!</h2>
            <p className="mobile-small">
              Photography in the studio and out in the field. <br />
              Pssst! → <Link to='/photo'><a>More photos here.</a></Link>
            </p>
          </div>
              </div>
              <Outlet/>
      </div>
    </section>
  );
}

export default StudioMessage3;
