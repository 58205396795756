import React from "react";

function FolioDigital22() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/b58901f4-20ec-4479-9f02-5588e4ba91b0_kff_webbcase_instacarousel_full.jpg?auto=compress,format"
            alt="kalmarff.se mobilvy"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigital22;
