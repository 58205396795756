import React, {useEffect} from 'react'
import TechWebdev1 from '../../pageSections/TechSections/Inner-Wed-Dev/TechWebdev1'
import TechWebdev2 from '../../pageSections/TechSections/Inner-Wed-Dev/TechWebdev2'
import TechWebdev3 from '../../pageSections/TechSections/Inner-Wed-Dev/TechWebdev3'
import TechWebdev4 from '../../pageSections/TechSections/Inner-Wed-Dev/TechWebdev4'
import TechWebdev5 from '../../pageSections/TechSections/Inner-Wed-Dev/TechWebdev5'
import TechWebdev6 from '../../pageSections/TechSections/Inner-Wed-Dev/TechWebdev6'
import TechWebdev7 from '../../pageSections/TechSections/Inner-Wed-Dev/TechWebdev7'
import TechWebdev8 from '../../pageSections/TechSections/Inner-Wed-Dev/TechWebdev8'
import TechWebdev9 from '../../pageSections/TechSections/Inner-Wed-Dev/TechWebdev9'
import TechWebdev10 from '../../pageSections/TechSections/Inner-Wed-Dev/TechWebdev10'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function TechWebdev() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Web Development</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <TechWebdev1 />
          <TechWebdev2 />
          <TechWebdev3 />
          <TechWebdev4 />
          <TechWebdev5 />
          <TechWebdev6 />
          <TechWebdev7 />
          <TechWebdev8 />
          <TechWebdev9 />
          <TechWebdev10 />
        </div>
      </div>
    </main>
  )
}

export default TechWebdev
