import React from "react";

function FolioDigital3() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/83991188-d9df-40d8-add8-3d21deedc9fa_BB220522SM037.jpeg?auto=compress,format&rect=240,0,4264,3411&w=3000&h=2400"
            alt="Kalmar FF 2022"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigital3;
