import React, { useEffect } from "react";
import "../styles/Career.css";
import CareerHome from "../pageSections/CareerSections/CareerHome";
import CareerMessage1 from "../pageSections/CareerSections/CareerMessage1";
import Form from "../UniSections/Form";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

function Career() {
  return (
    <main class="flex-grow contain">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Career</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <CareerHome />
          <CareerMessage1 />
          <Form />
        </div>
      </div>
    </main>
  );
}

export default Career;
