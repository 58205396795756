import React from "react";

function GaDigital2() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>What is Google Ads?</h3>
              <ul>
                <li className="mobile-small">The world's most used online advertising tool</li>
                <li className="mobile-small">
                  The fastest and easiest way to create visibility on Google
                </li>
                <li className="mobile-small">You only pay for actual clicks on your ads </li>
                <li className="mobile-small">
                  The higher the competition for a keyword - the more expensive the CPC (cost per click)
                </li>
                <li className="mobile-small">
                  An average click in Google Ads costs between  $ 0.39 - $ 0.78
                </li>
                <li className="mobile-small">
                  Google ads can appear as search ads, display ads, and video.
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/779cd384-568f-4e26-9bb9-9c1190de3a55_Google_ads.png?auto=compress,format"
                alt="Google Ads logo"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GaDigital2;
