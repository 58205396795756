import React from "react";

function TechMiniClients() {
  return (
    <section class="py-20 bg-lapis" data-v-39fd1ff0>
      <div class="wrapper" data-v-39fd1ff0>
        <div class="flex flex-wrap items-center -mx-7 -my-7" data-v-39fd1ff0>
          <div
            class="w-1/2 lg:w-3/12 p-7 transform scale-75 origin-center opacity-0"
            data-v-39fd1ff0
          >
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
              data-v-39fd1ff0
            >
              <img
                loading="lazy"
                src="./images/My-logos/aura.svg"
                alt="Webbyrå Webbdesign - Figma logo"
                class="mx-auto w-full h-full"
                data-v-39fd1ff0
              />
            </a>
          </div>
          <div
            class="w-1/2 lg:w-3/12 p-7 transform scale-75 origin-center opacity-0"
            data-v-39fd1ff0
          >
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
              data-v-39fd1ff0
            >
              <img
                loading="lazy"
                src="./images/My-logos/uzazi.svg"
                alt="Webbyrå Hostig - Glesys logo"
                class="mx-auto w-full h-full"
                data-v-39fd1ff0
              />
            </a>
          </div>
          <div
            class="w-1/2 lg:w-3/12 p-7 transform scale-75 origin-center opacity-0"
            data-v-39fd1ff0
          >
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
              data-v-39fd1ff0
            >
              <img
                loading="lazy"
                src="./images/My-logos/aamaka.svg"
                alt="Webbyrå - Netlify logo"
                class="mx-auto w-full h-full"
                data-v-39fd1ff0
              />
            </a>
          </div>
          <div
            class="w-1/2 lg:w-3/12 p-7 transform scale-75 origin-center opacity-0"
            data-v-39fd1ff0
          >
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
              data-v-39fd1ff0
            >
              <img
                loading="lazy"
                src="./images/My-logos/belinda.svg"
                alt="Webbyrå - NuxtJS logo"
                class="mx-auto w-full h-full"
                data-v-39fd1ff0
              />
            </a>
          </div>
          <div
            class="w-1/2 lg:w-3/12 p-7 transform scale-75 origin-center opacity-0"
            data-v-39fd1ff0
          >
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
              data-v-39fd1ff0
            >
              <img
                loading="lazy"
                src="./images/My-logos/gen.svg"
                alt="Webbyrå - Prismic CMS logo"
                class="mx-auto w-full h-full"
                data-v-39fd1ff0
              />
            </a>
          </div>
          <div
            class="w-1/2 lg:w-3/12 p-7 transform scale-75 origin-center opacity-0"
            data-v-39fd1ff0
          >
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
              data-v-39fd1ff0
            >
              <img
                loading="lazy"
                src="./images/My-logos/nicee.svg"
                alt="Webbyrå - Wordpress logo"
                class="mx-auto w-full h-full"
                data-v-39fd1ff0
              />
            </a>
          </div>
          <div
            class="w-1/2 lg:w-3/12 p-7 transform scale-75 origin-center opacity-0"
            data-v-39fd1ff0
          >
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
              data-v-39fd1ff0
            >
              <img
                loading="lazy"
                src="./images/My-logos/qq.svg"
                alt="Webbyrå - Jamstack logo"
                class="mx-auto w-full h-full"
                data-v-39fd1ff0
              />
            </a>
          </div>
          <div
            class="w-1/2 lg:w-3/12 p-7 transform scale-75 origin-center opacity-0"
            data-v-39fd1ff0
          >
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
              data-v-39fd1ff0
            >
              <img
                loading="lazy"
                src="./images/My-logos/rashid.svg"
                alt="Webbyrå för e-handel Shopify logo"
                class="mx-auto w-full h-full"
                data-v-39fd1ff0
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechMiniClients;
