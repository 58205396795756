import React from "react";

function TechHosting1() {
  return (
    <section class="slice relative home-reset custom-padding">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243131/SWEK/images/Headers/tech/swek-hosting_lgiqvm.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Secure server operations
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Web hosting</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">and</span>
            </span>
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">support</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  Swek Interactive is responsible for the operation of the
                  server environment for your website.
                </span>{" "}
              </p>
              <p className="mobile-small">
                This means that we install and manage all the technical details
                required to keep the site online. We also offer a service
                agreement.<strong> </strong>to maintain the user experience at
                its best over time through ongoing optimization of the site's
                performance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechHosting1;
