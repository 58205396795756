import React from "react";
import {Outlet, Link} from 'react-router-dom'

function DaDigital4() {
  return (
    <section class="slice custom-padding" data-v-d8f50b44>
      <div class="wrapper flex flex-col" data-v-d8f50b44>
        <div
          class="w-full py-12 border-t border-darker first-of-type:border-none first:pt-0 last:pb-0"
          data-v-d8f50b44
        >
          <div class="flex flex-wrap lg:flex-nowrap group" data-v-d8f50b44>
          <div class="w-full lg:w-1/4 mb-4 lg:mb-0" data-v-d8f50b44>
          <Link to='/digital'>                             
              <a
                class="text-base lg:text-lg block hover:text-laser hover:underline md:mt-5 nuxt-link-active"
                data-v-d8f50b44
              >
                02.Digital
           </a>
           </Link>
            </div>
            <div class="w-full lg:w-3/4" data-v-d8f50b44>
              <span
                class="text-2xl md:text-3xl lg:text-5xl block"
                data-v-d8f50b44
              >
                <div class="leading-tight links" data-v-d8f50b44>
                  <p>
                    <Link to='/digitaldataanalysis'></Link><a>Data Analysis</a> /{" "}
                    <Link to='/digitalstrategy'><a>Digital strategi</a></Link> /{" "}
                    <Link to='/digitalsocialmedia'><a>Sociala medier</a></Link> /{" "}
                    <Link to='/googleads'><a href="/digital/google-ads/">Google Ads</a></Link> /{" "}
                    <Link to='/content'><a>Content</a></Link>
                  </p>
                </div>
              </span>
            </div>
          </div>
              </div>
              <Outlet/>
      </div>
    </section>
  );
}

export default DaDigital4;
