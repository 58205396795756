import React from "react";

function InnerConcept2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Concept development
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>A super idea creates lifelong love</h2>
            <p className="mobile-small">
              At Swek Interactive we love a good communication concept, this is
              when our creative souls are really put to the test. Coming up with
              some random exciting, different and quirky ideas is easy. The
              challenge lies in chiseling out a really strong core (read super
              idea) that sets the direction for communication efforts for
              several years to come. The basic message must be intact, but the
              expression must be flexible and able to be adapted based on the
              strengths of the various media channels and their users. Timing is
              also an important aspect here. A good communication concept must
              be long-term, but must also provide opportunities for quick
              interventions when unexpected opportunities for communication
              arise.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerConcept2;
