import React from 'react'

function FolioTech4() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/02fc0323-4d18-4b2e-8ad2-21dd7e71123c_bracke_case_webb_kontrast_16x9.gif?auto=compress,format" alt="" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTech4
