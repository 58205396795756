import React, {useEffect} from 'react'
import TechAppdev5 from '../../pageSections/TechSections/Inner-App-Dev/TechAppdev5'
import TechAppdev1 from '../../pageSections/TechSections/Inner-App-Dev/TechAppdev1'
import TechAppdev2 from '../../pageSections/TechSections/Inner-App-Dev/TechAppdev2'
import TechAppdev3 from '../../pageSections/TechSections/Inner-App-Dev/TechAppdev3'
import TechAppdev4 from '../../pageSections/TechSections/Inner-App-Dev/TechAppdev4'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function TechApp() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>App</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <TechAppdev1 />
          <TechAppdev2 />
          <TechAppdev3 />
          <TechAppdev4 />
          <TechAppdev5 />
        </div>
      </div>
    </main>
  )
}

export default TechApp
