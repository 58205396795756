import React from "react";

function CoDigital6() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Content is king
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>Understand your target audience and their needs</h3>
            <p className="mobile-small">
              Understanding your target audience's needs and how they navigate their buying journey is critical to successful content marketing. At Swek Interactive, we take the time to understand your specific audience and create custom content relevant to each step of their journey. From creating awareness to aiding decision-making, our content is carefully crafted to respond to your target audience's needs and questions at every stage.
            </p>
            <h3>Create value and build trust</h3>
            <p className="mobile-small">
              In content marketing, it is not just about selling products or services, but about building lasting relationships and trust with your target group. By providing valuable and engaging content, we establish your company as a trusted and knowledgeable resource within your industry. This helps to strengthen your brand and create long-term customer relationships.
            </p>
            <h3>Measurement and optimization</h3>
            <p className="mobile-small">
              To ensure that we deliver the best results, we focus on measuring and analyzing our work continuously. By using KPIs and performance data, we can adjust and optimize our content marketing strategy to maximize its impact and ROI.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoDigital6;
