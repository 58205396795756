import React from "react";

function FolioDigital12() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/78aba00b-2cc1-48cf-9b1c-77560ad3a732_Henrik.jpg?auto=compress,format"
            alt="#sabbastämningen Henrik Rydström"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/8942f73c-521d-414c-b6f6-65b2a0d40a31_Erik.jpg?auto=compress,format"
            alt="#sabbastämningen Erik Israelsson"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/46ff5d34-7a7d-4473-aece-90b1ea1c9d9b_Rasmus.jpg?auto=compress,format"
            alt="#sabbastämningen Rasmus Elm"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/2ed9c4cc-e103-4eb2-9c99-62fe975472c9_Oliver.jpg?auto=compress,format"
            alt="#sabbastämningen Oliver Berg"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigital12;
