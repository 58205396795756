import React from "react";

function FolioDigital6() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56 className="black-convert">
              SoMe-strategi
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h3 className="black-convert">Tre viktiga pusselbitar i sociala medier</h3>
            <p className="black-convert">
              <strong className="black-convert">
                01. Något att säga
                <br />
              </strong>
              Det börjar alltid med ett kommunikationsbehov såklart. Det kan
              vara en specifik match, ett nyförvärv eller att det är dags att
              förnya säsongsbiljetterna. Vi undviker onödigt babbel.
            </p>
            <p className="black-convert">
              <strong className="black-convert">
                02. Timing
                <br />
              </strong>
              Den kanske viktigaste pusselbiten är timing. Vi vill vara
              snabbfotade och ta tillvara på de möjligheter som bjuds med kort
              varsel och det finns alltid en öppen linje mellan förening och
              byrå. Men ännu viktigare är nog känslan för NÄR det är läge att
              tramsa och när vi bör hålla en mer seriös ton.
            </p>
            <p className="black-convert">
              <strong className="black-convert">
                03. Kanalkännedom
                <br />
              </strong>
              Vi anpassar budskap utifrån våra målgrupper. Twitter har blivit en
              arena för många inbitna KFF-fans i exil och därför lägger vi
              mycket kraft just här. Att ha ambassadörer som engagerar sig,
              delar och kommenterar är en superviktig ingrediens i att
              kommunikationen når ut brett.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital6;
