import React from "react";
import { Outlet, Link } from "react-router-dom";
// import { Carousel } from 'react-responsive-carousel';

function DigitalServicesGrig() {
  return (
    <section class="slice">
      <div class="wrapper">
        <div class="text-laser text-[10vw] md:text-[7vw] leading-[0.85] mb-10 lg:mb-20"></div>
        <div class="rich-text green-smiley"></div>
        <div class="over-flow-x-hidden swiper-container mt-10 lg:mt-20">
          <div class="swiper-wrapper relative h-full z-[1] flex transition-transform transform-gpu lg:flex lg:flex-wrap lg:-mx-8 lg:-mb-16 flexing">
            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/digitaldataanalysis">
                    <img
                      src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/bf2100f7-c10f-4fde-9aa9-12934c8253de_stats-up.svg"
                      alt="Ikon - analys"
                      class="w-16 h-16"
                      data-v-a2570448
                    />

                    <div class="mt-32" data-v-a2570448>
                      <h3
                        class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                        data-v-a2570448
                      >
                        Data analysis
                      </h3>
                      <div
                        class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                        data-v-a2570448
                      >
                        <p className="mobile-small">
                          Data is the foundation of smart decisions and that's
                          why we love it. Data analysis means that we never have
                          to guess when we take your digital presence to
                          completely new heights with maximum conversion as a
                          result.
                        </p>
                      </div>
                    </div>
                    <div class="flex items-center mt-10" data-v-a2570448>
                      <span
                        class="text-base lg:text-lg block text-white"
                        data-v-a2570448
                      >
                        Read More
                      </span>{" "}
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                        data-v-a2570448
                      >
                        <path
                          d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-v-a2570448
                        ></path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/digitalsocialmedia">
                    <img
                      src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/fada1380-ec4e-48d3-9072-f3d7d8926387_mobile.svg"
                      alt="Ikon - mobiltelefon"
                      class="w-16 h-16"
                      data-v-a2570448
                    />
                    <div class="mt-32" data-v-a2570448>
                      <h3
                        class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                        data-v-a2570448
                      >
                        Social Media
                      </h3>
                      <div
                        class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                        data-v-a2570448
                      >
                        <p className="mobile-small">
                          It doesn't matter if you want to goof around on TikTok
                          or engage in recruiting on LinkedIn. We go way back
                          with all social media and know how to get the right
                          message across, in the right place, to the right
                          target group, at the right time.
                        </p>
                      </div>
                    </div>
                    <div class="flex items-center mt-10" data-v-a2570448>
                      <span
                        class="text-base lg:text-lg block text-white"
                        data-v-a2570448
                      >
                        Read More
                      </span>{" "}
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                        data-v-a2570448
                      >
                        <path
                          d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-v-a2570448
                        ></path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/digitalstrategy">
                    <img
                      src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/b725e1e4-7bc6-4bc5-bd8a-9272013b8ea3_direction-alt.svg"
                      alt="Ikon - skyltar/pilar"
                      class="w-16 h-16"
                      data-v-a2570448
                    />
                    <div class="mt-32" data-v-a2570448>
                      <h3
                        class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                        data-v-a2570448
                      >
                        Digital strategy
                      </h3>
                      <div
                        class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                        data-v-a2570448
                      >
                        <p  className="mobile-small">
                          We like long-term relationships where everyone pulls
                          in the same direction. That's why we often and happily
                          work with both thorough digital communication
                          strategies and more easily accessible guidelines for
                          social media.{" "}
                        </p>
                      </div>
                    </div>
                    <div class="flex items-center mt-10" data-v-a2570448>
                      <span
                        class="text-base lg:text-lg block text-white"
                        data-v-a2570448
                      >
                        Read More
                      </span>{" "}
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                        data-v-a2570448
                      >
                        <path
                          d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-v-a2570448
                        ></path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/googleads">
                    <img
                      src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/a296526b-79cb-459b-987a-f1277164a290_layout-list-thumb-alt.svg"
                      alt="Okon - Google Ads"
                      class="w-16 h-16"
                      data-v-a2570448
                    />
                    <div class="mt-32" data-v-a2570448>
                      <h3
                        class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                        data-v-a2570448
                      >
                        Google Ads
                      </h3>
                      <div
                        class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                        data-v-a2570448
                      >
                        <p className="mobile-small">
                          Do you want to be at the top of Google search results
                          or reach people worldwide through their display
                          network of millions of websites and apps? We got you.
                        </p>
                      </div>
                    </div>
                    <div class="flex items-center mt-10" data-v-a2570448>
                      <span
                        class="text-base lg:text-lg block text-white"
                        data-v-a2570448
                      >
                        Read More
                      </span>{" "}
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                        data-v-a2570448
                      >
                        <path
                          d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-v-a2570448
                        ></path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/content">
                    <img
                      src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/97b889fa-d8a3-4812-8cb6-2d6553290352_layout-list-post.svg"
                      alt="Ikon - content"
                      class="w-16 h-16"
                      data-v-a2570448
                    />
                    <div class="mt-32" data-v-a2570448>
                      <h3
                        class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                        data-v-a2570448
                      >
                        Content
                      </h3>
                      <div
                        class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                        data-v-a2570448
                      >
                        <p className="mobile-small">
                          Images and SEO-adapted text for the web, videos for
                          social media or audio for a podcast. We produce loving
                          and value-creating content for your target group.
                        </p>
                      </div>
                    </div>
                    <div class="flex items-center mt-10" data-v-a2570448>
                      <span
                        class="text-base lg:text-lg block text-white"
                        data-v-a2570448
                      >
                        Read More
                      </span>{" "}
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                        data-v-a2570448
                      >
                        <path
                          d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-v-a2570448
                        ></path>
                      </svg>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination lg:hidden"></div>
        <Outlet />
      </div>
    </section>
  );
}

export default DigitalServicesGrig;
