import React, {useEffect} from 'react'
import '../styles/Philosophy.css'
import PhilosophyHome from '../pageSections/PhilosophySections/PhilosophyHome'
import PhiloMessage1 from '../pageSections/PhilosophySections/PhiloMessage1'
import PhiloCeo from '../pageSections/PhilosophySections/PhiloCeo'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Philosophy() {

  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Philosophy</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <PhilosophyHome /> 
            <PhiloMessage1 /> 
            <PhiloCeo />      
        </div>
      </div>
    </main> 
  )
}

export default Philosophy
