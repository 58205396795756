import React, {useEffect} from 'react'
import Stories1 from '../../pageSections/StoriesSections/StoriesInner/Stories1'
import Stories2 from '../../pageSections/StoriesSections/StoriesInner/Stories2'
import Stories3 from '../../pageSections/StoriesSections/StoriesInner/Stories3'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Blog1() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Stories - Startegy</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <Stories1 />
            <Stories2 />
            <Stories3 />
        </div>
      </div>
    </main> 
  )
}

export default Blog1
