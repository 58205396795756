import React from "react";

function FolioDigital19() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56
             className="black-convert">
              Design
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2 className="black-convert">Content och design</h2>
            <p className="black-convert">
              Allt från sociala medier-content, printannonser och stationery
              design till LED-reklam, merchandise och matteböcker.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital19;
