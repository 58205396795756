import React from "react";
import { Outlet, Link } from "react-router-dom";

function InnerCopy2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              People + Google approves
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Web copy</h2>
            <p className="mobile-small">
              Web copy is texts designed for websites, social media, blogs and
              digital advertising. What distinguishes web copy from traditional
              copywriting is that it is not only the reader who must be
              captivated by the text, Google must also like it. If Google
              doesn't understand what your text is about, the site risks ranking
              low in the search results with the consequence that potential
              visitors simply won't find you. We can't have it like that, can
              we? We make sure that your web text is unique, contains the right
              keywords/phrases and is appreciated by both real people and
              Google, regardless of channel and device.{" "}
            </p>
            <p className="mobile-small">
              <Link to="/techseo">
                <a>Read more about SEO here!</a>
              </Link>
            </p>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default InnerCopy2;
