import React from "react";
import {Outlet, Link} from 'react-router-dom'

function GridFilm2() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>Examples of films</h4>
              <p className="mobile-small">
                We are enthusiastic about all kinds of film production. Here are
                some different types of films we are used to producing:
              </p>
              <ul>
                <li>Commercial for SoMe</li>
                <li>Commercial film</li>
                <li>Branding film</li>
                <li>Product film</li>
                <li>Explainer film</li>
                <li>After movie</li>
                <li>Recruitment film</li>
                <li>Employer branding film</li>
                <li>Interview film</li>
                <li>Documentation</li>
                <li>Movie for web pages</li>
                <li>
                  <Link to="/film">
                    <a>Animated Videos</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243287/SWEK/images/Others/sd_rnxldv.gif"
                alt="Bertil Vallien: Foto Wilson Creative"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default GridFilm2;
