import React from "react";

function SwekHome() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243189/SWEK/images/Headers/swek2_ovhdvw.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243225/SWEK/images/Headers/swek_xxsmfd.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case mobile"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Philosophy and values
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Swek™</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p className="mobile-small">
                Our unique blend incorporates a complete disregard for
                conventional norms coupled with a profound sense of camaraderie
                among ourselves and with our valued customers. While our
                destination may not be precisely mapped out, our journey is
                characterized by continuous innovation and the spontaneous
                creation of imaginative solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SwekHome;
