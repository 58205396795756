import React from "react";

function TechAppdev3() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Native app
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Apps for iOS and Android.</h2>
            <p className="mobile-small">
             An application for iOS and Android works a bit differently. It is downloaded to the phone from the App Store (iOS) or Google Play Store (Android) and can more extensively use the phone's features, such as camera and GPS. Unlike a web app, which requires an internet connection, a native app can often be used offline. However, the higher flexibility and functionality come with higher development costs compared to a web app.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechAppdev3;
