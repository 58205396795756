import React from 'react'

function OlandFolio16() {
  return (
     <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
                                <div class="wrapper lg:flex" data-v-2ac86e56>
                                    <div class="lg:w-3/12" data-v-2ac86e56>
                                        <div class="transform translate-y-10 opacity-0" data-v-2ac86e56><span class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight" data-v-2ac86e56>Grafiska element</span></div>
                                    </div>
                                    <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
                                        <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56 >
                                            <h2>Avskalade och organiska former</h2>
                                            <p>Identiteten har berikats med flera grafiska element som binder ihop helheten men också ger mer karaktär. Det finns element uppbyggda kring den 18-gradiga vinkeln, två olika mönster – båda baserade på den avskalade
                                                Ölands-symbolen – samt ett illustrationsmanér som är mjukt och organiskt, vilket balanserar upp det mer raka formspråket vid behov.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default OlandFolio16
