import React from "react";

function SwekMessage1() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              The Swek Experience
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>The experience in the center</h2>
            <p className="mobile-small">
              Swek Interactive does not work with ready-made communication
              solutions and various Small/Medium/Large packages. Each customer
              has unique needs, ambitions and knowledge that we are sensitive
              to. Design, code, content and digital marketing occur, but the
              core of our idea is the experience, The Swek Experience, because
              it is the same regardless of the nature of the assignment.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SwekMessage1;
