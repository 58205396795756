import React, {useEffect} from 'react'
import FolioTech1 from '../../pageSections/TechSections/Inner-Folio-Tech-1/FolioTech1'
import FolioTech2 from '../../pageSections/TechSections/Inner-Folio-Tech-1/FolioTech2'
import FolioTech3 from '../../pageSections/TechSections/Inner-Folio-Tech-1/FolioTech3'
import FolioTech4 from '../../pageSections/TechSections/Inner-Folio-Tech-1/FolioTech4'
import FolioTech5 from '../../pageSections/TechSections/Inner-Folio-Tech-1/FolioTech5'
import FolioTech6 from '../../pageSections/TechSections/Inner-Folio-Tech-1/FolioTech6'
import FolioTech7 from '../../pageSections/TechSections/Inner-Folio-Tech-1/FolioTech7'
import FolioTech8 from '../../pageSections/TechSections/Inner-Folio-Tech-1/FolioTech8'
import ReactGA from 'react-ga4'

function TechFolio() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <FolioTech1 />
          <div data-fetch-key="PageFetch:0">
            
            <FolioTech2 />
            <FolioTech3 />
            <FolioTech4 />
            <FolioTech5 />
            <FolioTech6 />
            <FolioTech7 />
            <FolioTech8 />
          </div>
        </div>
      </div>
    </main>
  )
}

export default TechFolio
