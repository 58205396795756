import React from "react";

function DaTwo() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Reporting with Google Looker Studio</h3>
              <p className="mobile-small">
                In order to take the pace of the digital presence and show the target group's behavior patterns, we produce a visualized, easy-to-understand report at regular intervals. The report is tailored based on needs and can be quickly adapted if conditions change. An overall picture of the customer's entire digital ecosystem, external monitoring, behavioral patterns of the target group and interesting trends is presented here. Based on this basis, we then draw up an action plan for future efforts.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/68c234d4-e74a-40de-a13a-a1fdf1d30287_Looker-by-Google-Cloud.png?auto=compress,format"
                alt="Google Looker"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DaTwo;
