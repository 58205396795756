import React from "react";

function SmDigital10() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Advertising on YouTube</h3>
              <p className="mobile-small">
                As a platform that specializes in video, YouTube offers a unique opportunity to create in-depth content that can engage and inform viewers worldwide. Create content such as tutorials, behind-the-scenes videos, interviews and product reviews. Be consistent with your video production and maintain a high standard of quality. Also use YouTube Stories to engage with your audience on a more personal level. In addition to optimizing your videos for SEO, you should also focus on building a loyal subscriber base. Respond to comments and create a dialogue with your audience to build a community.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/c1433833-a1c7-40d3-bbb2-e42988876570_youtube.png?auto=compress,format"
                alt="Annonsering på YouTube"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital10;
