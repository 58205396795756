import React, { useEffect } from "react";
import StrifeFolio1 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio1";
import StrifeFolio4 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio4";
import StrifeFolio2 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio2";
import StrifeFolio3 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio3";
import StrifeFolio5 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio5";
import StrifeFolio6 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio6";
import StrifeFolio7 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio7";
import StrifeFolio8 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio8";
import StrifeFolio9 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio9";
import StrifeFolio10 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio10";
import StrifeFolio11 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio11";
import StrifeFolio12 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio12";
import StrifeFolio13 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio13";
import StrifeFolio14 from "../../pageSections/BrandingSections/BrandFolio2/StrifeFolio14";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

function StrifeFolio() {
  return (
    <main class="flex-grow contain">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Uzazi - Swek</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <StrifeFolio1 />
          <div data-fetch-key="PageFetch:0">
            <StrifeFolio2 />
            <StrifeFolio3 />
            <StrifeFolio4 />
            <StrifeFolio5 />
            <StrifeFolio6 />
            <StrifeFolio7 />
            <StrifeFolio8 />
            <StrifeFolio9 />
            <StrifeFolio10 />
            <StrifeFolio11 />
            <StrifeFolio12 />
            <StrifeFolio13 />
            <StrifeFolio14 />
          </div>
        </div>
      </div>
    </main>
  );
}

export default StrifeFolio;
