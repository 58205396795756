import React from 'react'

function FolioTechThree5() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/2bb8acee-1bb9-4496-8e34-de0f84e98e46_myrstack_2023_case_16x9_webb1.jpg?auto=compress,format" alt="Myrstack webbdesign" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTechThree5
