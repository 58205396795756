import React, {useEffect} from "react";
import SocialMedia from "../../pageSections/DigitalSections/InnerSocialMedia/SocialMedia";
import SmDigital1 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital1";
import SmDigital2 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital2";
import SmDigital3 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital3";
import SmDigital4 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital4";
import SmDigital5 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital5";
import SmDigital6 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital6";
import SmDigital7 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital7";
import SmDigital8 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital8";
import SmPortfolio from "../../pageSections/DigitalSections/InnerSocialMedia/SmPortfolio";
import SmDigital9 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital9";
import SmDigital10 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital10";
import SmDigital11 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital11";
import SmDigital12 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital12";
import SmDigital13 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital13";
import SmDigital14 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital14";
import SmDigital15 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital15";
import SmDigital16 from "../../pageSections/DigitalSections/InnerSocialMedia/SmDigital16";
import ReactGA from 'react-ga4'
import { Helmet } from "react-helmet";

function DigitalSocialMedia() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Social Media</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
           <SocialMedia />
           <SmDigital1 />
           <SmDigital2 />
           <SmDigital3 />
           {/* <SmPortfolio /> */}
           <SmDigital4 />
           <SmDigital5 />
           <SmDigital6 />
           <SmDigital7 />
           <SmDigital8 />
           <SmDigital9 />
           <SmDigital10 />
           <SmDigital11 />
           <SmDigital12 />
           <SmDigital13 />
           <SmDigital14 />
           <SmDigital15 />
           <SmDigital16 />
        </div>
      </div>
    </main>
  );
}

export default DigitalSocialMedia;
