import React, {useEffect} from 'react'
import TechUXA from '../../pageSections/TechSections/Inner-UX-UI-Desing/TechUXA.js'
import TechUX2 from '../../pageSections/TechSections/Inner-UX-UI-Desing/TechUX2'
import TechUX3 from '../../pageSections/TechSections/Inner-UX-UI-Desing/TechUX3'
import TechUX4 from '../../pageSections/TechSections/Inner-UX-UI-Desing/TechUX4'
import TechUX5 from '../../pageSections/TechSections/Inner-UX-UI-Desing/TechUX5'
import TechUX6 from '../../pageSections/TechSections/Inner-UX-UI-Desing/TechUX6'
import TechUX7 from '../../pageSections/TechSections/Inner-UX-UI-Desing/TechUX7'
import TechUX8 from '../../pageSections/TechSections/Inner-UX-UI-Desing/TechUX8'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function TechUXDesign() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>UX-UI</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <TechUXA />
          <TechUX2 />
          <TechUX3 />
          <TechUX4 />
          <TechUX5 />
          {/* <TechUX6 /> */}
          <TechUX7 />
          <TechUX8 />
        </div>
      </div>
    </main>
  )
}

export default TechUXDesign
