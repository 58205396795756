import React from "react";

function GridAnimation3() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <img
              src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243287/SWEK/images/Others/sd_rnxldv.gif"
              alt="Bertil Vallien: Foto Wilson Creative"
              class="w-full h-full inset-0 object-cover absolute"
              data-v-7dc95f74
            />
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723244214/SWEK/images/GenOrganics/baby_ze82zm.mov"
              autoPlay
              loop
              muted
            ></video>
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723244346/SWEK/images/Uganda-waragi/ug-ad2_nn67kf.mp4"
              autoplay
              loop
            ></video>
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723244425/SWEK/images/Aamaka/Reel2_q1lttj.mp4"
              autoPlay
              loop
              muted
            ></video>
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723244348/SWEK/images/Rock-boom/rock_boom_szmdpx.mp4"
              autoPlay
              loop
              muted
            ></video>
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723243500/SWEK/images/Others/Bottle-R_svbrph.mp4"
              autoPlay
              loop
              muted
            ></video>
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723243709/SWEK/images/Others/M2_qawkr7.mp4"
              autoPlay
              loop
              muted
            ></video>
          </figure>
        </div>
      </div>
    </section>
  );
}

export default GridAnimation3;
