import React from "react";

function Stories1a() {
  return (
    <section class="slice relative" data-v-715b3dbb>
      <div class="wrapper lg:flex">
        <div class="lg:w-3/12">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              The Chromatic Symphony
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Unraveling</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">the</span>
            </span>
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">impact</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">of color</span>
            </span>
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">on brand</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">identity</span>
            </span>
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Stories1a;
