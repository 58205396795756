import React from 'react'

function FolioTechThree9() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/74e170e6-9727-48fe-a04c-2e8cdbba6180_myrstack_2023_case_16x9_webb5.jpg?auto=compress,format" alt="Myrstack webbdesign" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTechThree9
