import React from "react";

function TechEcommerce3() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              We're with you all the way.
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Start e-commerce </h2>
            <p className="mobile-small">
              Today, consumers want to shop and conduct their errands online, and the expectations for functionality and user-friendliness are high. An e-commerce platform must be easy to navigate, especially on mobile, and the steps to the purchase button should be as few as possible.
            </p>
            <p className="mobile-small">
              When you choose Swek Interactive to start an e-commerce business, you can expect full engagement from start to finish. The choice of the e-commerce platform depends on factors such as the target audience, product range, as well as the level of ambition and budget. Before we begin developing the online store, we conduct a thorough SEO analysis, create a visual site map, and then start the work on UX and UI design. In this process, we are careful to involve Wilson's branding team, contributing insights on how the brand is communicated most effectively. A clear and genuine brand creates a stronger emotional connection with customers and is often a more powerful competitive advantage than price.
            </p>
            <p className="mobile-small">
              When the e-commerce platform is launched, we are, of course, still by your side for follow-up, conversion optimization, and support. Through data analysis, we continually enhance the user experience and ensure that the online store is its absolute best version 24/7.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechEcommerce3;
