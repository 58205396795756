import React from "react";

function TechWebdev3() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-laser " data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
             Swek Tech
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div
            class="white-smiley link laser-bg link text-black dark-smiley content rich-text"
            data-v-2ac86e56
          >
            <h2 className="text-back-main">
              We create sustainable websites for companies that want to be part of the solution.
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechWebdev3;
