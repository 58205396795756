import React from "react";
import {Outlet, Link} from 'react-router-dom'

function BrandingLogos() {
  return (
    <section class="my-20 lg:my-40" data-v-499be521>
      <div class="wrapper" data-v-499be521>
        <div class="title" data-v-499be521>
          <h2>Logos designed by Swek</h2>
        </div>
        <div
          class="flex flex-wrap items-center -mx-4 -my-8 lg:-mx-8 :-my-12"
          data-v-499be521
        >




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <a
              href="/projekt/oland/"
              class="flex flex-col items-center justify-center border-b border-b-[#179062] group"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/aura.svg"
                  alt="Logga: Öland"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <a href="/projekt/oland/" class="link" data-v-499be521>
                    <span data-v-499be521>Aura Lounge</span>
                  </a>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Hospitality
                </span>{" "}
                <span class="text" data-v-499be521>
                  2022
                </span>
              </div>
            </a>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <a
              
              class="flex flex-col items-center justify-center border-b border-b-[#179062] group"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/rock.svg"
                  alt="Logga: Kalmar FF"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <a href="/projekt/kalmarff/" class="link" data-v-499be521>
                    <span data-v-499be521>Rock Boom</span>
                  </a>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Energy Drink
                </span>{" "}
                <span class="text" data-v-499be521>
                  2021
                </span>
              </div>
            </a>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <div
              class="flex flex-col items-center justify-center border-b border-b-[#179062]"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/waragi.svg"
                  alt="Logga: Royal Design"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <span class="company" data-v-499be521>
                    Uganda Waragi
                  </span>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Alcoholic Beverage
                </span>{" "}
                <span class="text" data-v-499be521>
                  2012
                </span>
              </div>
            </div>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <a
              
              class="flex flex-col items-center justify-center border-b border-b-[#179062] group"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/rashid.svg"
                  alt="Logga: Dillbergs"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <a href="/projekt/dillbergs/" class="link" data-v-499be521>
                    <span data-v-499be521>Chef Rashid</span>
                  </a>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Hospitality
                </span>{" "}
                <span class="text" data-v-499be521>
                  2023
                </span>
              </div>
            </a>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <div
              class="flex flex-col items-center justify-center border-b border-b-[#179062]"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/uzazi.svg"
                  alt="Logga: Mister York"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <span class="company" data-v-499be521>
                    Uzawi
                  </span>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Tech
                </span>{" "}
                <span class="text" data-v-499be521>
                  2021
                </span>
              </div>
            </div>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <a
              
              class="flex flex-col items-center justify-center border-b border-b-[#179062] group"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/gen.svg"
                  alt="Logga: Haypp Group"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <a href="/projekt/haypp-group/" class="link" data-v-499be521>
                    <span data-v-499be521>Gen Organics</span>
                  </a>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Cosmetics
                </span>{" "}
                <span class="text" data-v-499be521>
                  2022
                </span>
              </div>
            </a>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <div
              class="flex flex-col items-center justify-center border-b border-b-[#179062]"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/aamaka.svg"
                  alt="Logga: Only Live"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <span class="company" data-v-499be521>
                    Aamaka
                  </span>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Real Estate
                </span>{" "}
                <span class="text" data-v-499be521>
                  2023
                </span>
              </div>
            </div>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <div
              class="flex flex-col items-center justify-center border-b border-b-[#179062]"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/nicee.svg"
                  alt="Logga: Evelid"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <span class="company" data-v-499be521>
                    Nicee
                  </span>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Fast Food
                </span>{" "}
                <span class="text" data-v-499be521>
                  2022
                </span>
              </div>
            </div>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <a
              href="/projekt/strife/"
              class="flex flex-col items-center justify-center border-b border-b-[#179062] group"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/qq.svg"
                  alt="Logga: Strife"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <a href="/projekt/strife/" class="link" data-v-499be521>
                    <span data-v-499be521>Quality Chemicals</span>
                  </a>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Pharmaceuticals
                </span>{" "}
                <span class="text" data-v-499be521>
                  2019
                </span>
              </div>
            </a>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <a
              
              class="flex flex-col items-center justify-center border-b border-b-[#179062] group"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/hemmam.svg"
                  alt="Logga: Promas"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <a href="/projekt/promas/" class="link" data-v-499be521>
                    <span data-v-499be521>Hemmam</span>
                  </a>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Logistics
                </span>{" "}
                <span class="text" data-v-499be521>
                  2022
                </span>
              </div>
            </a>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <div
              class="flex flex-col items-center justify-center border-b border-b-[#179062]"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/smart.svg"
                  alt="Logga: MyQR"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <span class="company" data-v-499be521>
                    Smart Compare
                  </span>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Tech
                </span>{" "}
                <span class="text" data-v-499be521>
                  2022
                </span>
              </div>
            </div>
          </div>




          <div
            class="px-4 py-8 lg:px-8 lg:py-12 w-1/2 md:w-1/3 lg:w-1/4"
            data-v-499be521
          >
            <div
              class="flex flex-col items-center justify-center border-b border-b-[#179062]"
              data-v-499be521
            >
              <div
                class="h-[110px] lg:h-[130px] w-full relative"
                data-v-499be521
              >
                <img
                  src="./images/My-logos/kohen.svg"
                  alt="Logga: Svenska Elitserien"
                  class="w-full object-contain absolute px-6 md:px-10 lg:px-14 h-full inset-0"
                  data-v-499be521
                />
              </div>
              <div class="my-8 text-center" data-v-499be521>
                <div data-v-499be521>
                  <span class="company" data-v-499be521>
                    Jaycee Kohen
                  </span>
                </div>{" "}
                <span class="text" data-v-499be521>
                  Artist
                </span>{" "}
                <span class="text" data-v-499be521>
                  2023
                </span>
              </div>
            </div>
          </div>




        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default BrandingLogos;
