import React, {useEffect} from 'react'
import DataAnalysis from '../../pageSections/DigitalSections/Inner-Digital/DataAnalysis'
import DaOne from '../../pageSections/DigitalSections/Inner-Digital/DaOne'
import DaTwo from '../../pageSections/DigitalSections/Inner-Digital/DaTwo'
import DaThree from '../../pageSections/DigitalSections/Inner-Digital/DaThree'
import DaFour from '../../pageSections/DigitalSections/Inner-Digital/DaFour'
import DaFive from '../../pageSections/DigitalSections/Inner-Digital/DaFive'
import DaSix from '../../pageSections/DigitalSections/Inner-Digital/DaSix'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function DigitalDataAnalysis() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Data Analysis</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
                  <DataAnalysis /> 
                  <DaOne />
                  <DaTwo />
                  <DaThree />
                  <DaFour />
                  <DaFive />
                  <DaSix />
        </div>
      </div>
    </main> 
  )
}

export default DigitalDataAnalysis
