import React from "react";

function DataAnalysis() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243123/SWEK/images/Headers/digital/swek-data_xxgjwx.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Understand your customers with data-driven marketing
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Data Analysis</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  Analysis of relevant data is the very foundation of digital
                  marketing at Swek Interactive.{" "}
                </span>
              </p>
              <p className="mobile-small">
                A collaboration therefore begins by looking in the rearview
                mirror to then predict the future and set a strategy and
                direction for the work going forward. But analysis of data is of
                course a natural part in the future as well. We continuously
                analyze statistics and outcomes from the digital efforts that
                are made and constantly learn more about how the target group
                acts. In this way, the digital presence is tuned and developed
                with each passing day, with increased competitiveness and
                conversion as a result. Not all data
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DataAnalysis;
