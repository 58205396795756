import React from 'react'

function FolioTechSix9() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/3f21b267-ec3e-46b2-93cc-c1a022087cb8_guldfageln_case_16x9.jpg?auto=compress,format" alt="Guldfågelns webbsida i desktop- och mobilvy" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTechSix9
