import React, {useEffect} from 'react'
import '../styles/Project.css'
import ProjectHome from '../pageSections/ProjectSections/ProjectHome'
import ProjectFolio from '../pageSections/ProjectSections/ProjectFolio'
// import ProjectClients from '../pageSections/ProjectSections/ProjectClients'
import Form from '../UniSections/Form'
import Clients from '../pageSections/HomeSections/Clients'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Project() {

  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Projects</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <ProjectHome />  
            <ProjectFolio />     
            <Clients />   
            <Form />     
        </div>
      </div>
    </main> 
  )
}

export default Project
