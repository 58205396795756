import React from 'react'

function FolioTechFour7() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/0b043a30-0a1b-4372-bce9-f6fb61b5cb7f_HaileyHR_2022_case_16x9_1.jpg?auto=compress,format" alt="" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTechFour7
