import React from 'react'

function OlandFolio3() {
  return (
     <section class="video-slice bg-darkest-overlay" data-v-22a985e7>
                                <div class="relative flex items-center justify-center h-screen w-full max-h-[400px] md:max-h-[700px] lg:max-h-[900px]" data-v-22a985e7>
                                    <video autoplay loop muted playsinline src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/010c1b20-237c-4f18-8382-13231a800021_651_OLANDS_TURISTBYRA_TAVLING_OVP_v002.mp4" class="absolute w-full h-full object-cover inset-0"
                                        data-v-22a985e7></video></div>
                            </section>
  )
}

export default OlandFolio3
