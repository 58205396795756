import React, {useEffect} from 'react'
import GridPhoto1 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto1'
import GridPhoto2 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto2'
import GridPhoto3 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto3'
import GridPhoto4 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto4'
import GridPhoto5 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto5'
import GridPhoto6 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto6'
import GridPhoto7 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto7'
import GridPhoto8 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto8'
import GridPhoto9 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto9'
import GridPhoto10 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto10'
import GridPhoto11 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto11'
import GridPhoto12 from '../../pageSections/StudioSections/InnerPhotography/GridPhoto12'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Photo() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Photography</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
        <GridPhoto1 />
        <GridPhoto2 />
        <GridPhoto3 />
        <GridPhoto4 />
        <GridPhoto5 />
        <GridPhoto6 />
        <GridPhoto7 />
        <GridPhoto8 />
        <GridPhoto9 />
        <GridPhoto10 />
        <GridPhoto11 />
        <GridPhoto12 />
        </div>
      </div>
    </main> 
  )
}

export default Photo
