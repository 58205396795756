import React from "react";

function InnerBrand8() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              From A to Z
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Do you want to create a brand from scratch? Cool!</h2>
            <p className="mobile-small">
              Building a brand from scratch is an exciting process for new
              businesses. At Swek Interactive, we have extensive experience in
              this and can effectively guide you through every step of this
              journey. We start by helping you find a name that is memorable and
              stands out in the noise. The choice of name is important and lays
              the foundation for the work going forward.
            </p>
            <p className="mobile-small">
              After finding the perfect name, we take on the task of ensuring
              trademark protection. This means registering the brand legally,
              which is an important step to protect your identity and avoid
              unnecessary conflicts. We assist to ensure that the name is unique
              and does not infringe existing trademark rights.
            </p>
            <p className="mobile-small">
              Domain registration is the next step in the process. We help you
              choose and register a relevant and effective domain that not only
              represents your brand but also optimizes your visibility online. A
              strong presence on the web is essential and we make sure that your
              website reflects your brand and communicates effectively with your
              target group.{" "}
            </p>
            <p className="mobile-small">
              Finally, the launch is about introducing the new brand to the
              market in a way that gets attention and builds lasting
              impressions. We develop a customized launch strategy that includes
              everything from marketing materials and campaigns to social media
              and PR. Our goal is to create a flying start for your brand,
              attract the interest of the right target group and establish a
              strong foundation for future growth and success.
            </p>
            <p className="mobile-small">
              At Swek Interactive, we want to build brands that not only survive,
              but thrive. We look forward to being part of the journey and
              helping your new brand reach its full potential.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerBrand8;
