import React from "react";

function SmDigital11() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Advertising on LinkedIn</h3>
              <p className="mobile-small">
                LinkedIn is the leading platform for professional networking,
                B2B marketing and recruitment, perfect for building
                relationships and strengthening your company's credibility with
                both clients and potential new employees. Share insightful
                articles, company news, and industry analysis. LinkedIn is also
                a great place to publish longer post formats like articles.
                Consider using LinkedIn Pulse to increase your reach. Networking
                is key on LinkedIn. Therefore, get involved in relevant groups,
                participate in discussions and build relationships with other
                industry leaders. Remember that the professionalism of the
                profile is crucial to the success of advertising on LinkedIn.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/cb4290b3-e3f3-459c-93ca-3b30515810e4_linkedin.png?auto=compress,format"
                alt="Annonsering på LinkedIn"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital11;
