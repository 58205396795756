import React from "react";

function FolioTechSix5() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
            <img
              loading="lazy"
              src="https://images.prismic.io/wilsoncreative/ef4275a0-4e1d-40c4-b29c-04f44e7962a9_Guldfageln+short+1.png?auto=compress,format"
              alt="Guldfågeln filmutdrag"
              class="absolute inset-0 object-cover h-full w-full cursor-pointer"
            />{" "}
            <img
              src="/_nuxt/img/play.17179b4.svg"
              alt="Starta uppspelning"
              loading="lazy"
              class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            />
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
            <img
              loading="lazy"
              src="https://images.prismic.io/wilsoncreative/ecc3b590-a18a-41bd-a50c-d682aecbcce3_Guldfageln+short+2.png?auto=compress,format"
              alt="Guldfågeln filmutdrag"
              class="absolute inset-0 object-cover h-full w-full cursor-pointer"
            />{" "}
            <img
              src="/_nuxt/img/play.17179b4.svg"
              alt="Starta uppspelning"
              loading="lazy"
              class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            />
          </figure>
        </div>
      </div>
    </section>
  );
}

export default FolioTechSix5;
