import React from "react";

function InnerCopy1() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <div class="lg:w-3/12">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Sell ​​with words - Copywriting
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight text-delete">
              <span class="inline-block align-top">Copy</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  With the help of sharp copywriting, we sell your brand, product or service to both new and already convinced customers.
                </span>{" "}
              </p>
              <p className="mobile-small">
                We love the power of the written word and adjust tone and message by channel for maximum impact.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerCopy1;
