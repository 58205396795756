import React, {useEffect} from 'react'
import HayFolio1 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio1'
import HayFolio2 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio2'
import HayFolio3 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio3'
import HayFolio4 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio4'
import HayFolio5 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio5'
import HayFolio6 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio6'
import HayFolio7 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio7'
import HayFolio8 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio8'
import HayFolio9 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio9'
import HayFolio10 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio10'
import HayFolio11 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio11'
import HayFolio12 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio12'
import HayFolio13 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio13'
import HayFolio14 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio14'
import HayFolio15 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio15'
import HayFolio16 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio16'
import HayFolio17 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio17'
import HayFolio18 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio18'
import HayFolio19 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio19'
import HayFolio20 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio20'
import HayFolio21 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio21'
import HayFolio22 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio22'
import HayFolio23 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio23'
import HayFolio24 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio24'
import HayFolio22a1 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio22a1'
import HayFolio22a2 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio22a2'
import HayFolio22a3 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio22a3'
import HayFolio22a4 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio22a4'
import HayFolio22a5 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio22a5'
import HayFolio22a6 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio22a6'
import HayFolio22a7 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio22a7'
import HayFolio22a8 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio22a8'
import HayFolio22a9 from '../../pageSections/BrandingSections/BrandFolio4/HayFolio22a9'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function HayFolio() {

  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Uganda Waragi</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <HayFolio1 />
          <div data-fetch-key="PageFetch:0">
          <HayFolio2 />
          <HayFolio3 />
          <HayFolio4 />
          <HayFolio5 />
          {/* <HayFolio6 /> */}
          <HayFolio7 />
          <HayFolio8 />
          {/* <HayFolio9 /> */}
          <HayFolio10 />
          <HayFolio11 />
          <HayFolio12 />
          <HayFolio13 />
          <HayFolio14 />
          <HayFolio15 />
          <HayFolio16 />
          {/* <HayFolio17 /> */}
          <HayFolio18 />
          <HayFolio19 />
          <HayFolio20 />
          <HayFolio21 />
            <HayFolio22 />
            <HayFolio22a1 />
            <HayFolio22a2 />
            <HayFolio22a3 />
            <HayFolio22a4 />
            <HayFolio22a5 />
            <HayFolio22a6 />
            <HayFolio22a7 />
            <HayFolio22a8 />
            <HayFolio22a9 />
          {/* <HayFolio23 /> */}
          <HayFolio24 />
          </div>
        </div>
      </div>
    </main>
  )
}

export default HayFolio
