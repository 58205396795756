import React from "react";

function DaOne() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Not all data
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Understand your customers with data-driven marketing </h2>
            <p className="mobile-small">
              With the help of tools such as Google Analytics, Tag Manager and
              Search Console, you can collect a huge amount of data. It is
              available to everyone and in large loads. Rather, the challenge
              lies in probing the terrain, selecting/deselecting and drawing
              conclusions from all the information and this is where Wilson
              Creative comes into play. We help you choose the right KPIs and
              then take action on what is required to achieve them. The work is
              completely transparent and completely free of unnecessary industry
              lingo. We place great value on the fact that you, as a client,
              feel involved and can develop your digital know-how together with
              us .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DaOne;
