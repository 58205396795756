import React from "react";

function DigitalBulliten() {
  return (
    <section class="slice bg-darkest" data-v-605c8685>
      <div class="wrapper" data-v-605c8685>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-605c8685
        >
          <div class="w-full lg:w-1/2" data-v-605c8685>
            <div class="green-smiley rich-text" data-v-605c8685>
              <h3>Creative digital marketing</h3>
              <ul>
                <li className="mobile-small">
                  We tailor the digital marketing completely according to your
                  conditions, needs and budget
                </li>
                <li className="mobile-small">
                  All decisions are based on analysis of relevant data, which
                  means that we never have to guess anything
                </li>
                <li className="mobile-small">
                  With the help of a digital strategy, we produce relevant KPIs
                  and an activity plan for our efforts
                </li>
                <li className="mobile-small">
                  Today, we mostly work with Meta and Google Ads, but are
                  comfortable with all digital media channels
                </li>
                <li className="mobile-small">
                  We have an in-house team that produces value-creating content
                  in the form of text, photos, film and sound
                </li>
                <li className="mobile-small">
                  We are careful to balance sales-driving and
                  brand-strengthening efforts for long-term success
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-605c8685>
            <div class="w-full pb-[70%] relative" data-v-605c8685>
              <img
                src="../images/Others/digital.jpg"
                alt="Kreativ digital marknadsföring hos Wilson är skräddarsydd, mätbar och effektiv"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-605c8685
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DigitalBulliten;
