import React from "react";

function SwekMessage6() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              03/03/04. Nonsense
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h4>Crazy Swek</h4>
            <p className="mobile-small">
              A unique enchantment unfolds when shared laughter resonates among us. Humor establishes bonds and facilitates the navigation of challenging days with ease. Hence, we engage in playful banter not only amongst ourselves but also extend the same jovial spirit to our customers and their clientele. And, in a delightful twist, Trams metamorphose into Smart when spelled backward...
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SwekMessage6;
