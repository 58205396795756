import React, {useEffect} from 'react'
import FolioDigitalThree1 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree1'
import FolioDigitalThree2 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree2'
import FolioDigitalThree3 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree3'
import FolioDigitalThree4 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree4'
import FolioDigitalThree5 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree5'
import FolioDigitalThree6 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree6'
import FolioDigitalThree7 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree7'
import FolioDigitalThree8 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree8'
import FolioDigitalThree9 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree9'
import FolioDigitalThree10 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree10'
import FolioDigitalThree11 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree11'
import FolioDigitalThree12 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree12'
import FolioDigitalThree13 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree13'
import FolioDigitalThree14 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree14'
import FolioDigitalThree15 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree15'
import FolioDigitalThree16 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree16'
import FolioDigitalThree17 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree17'
import FolioDigitalThree18 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree18'
import FolioDigitalThree19 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree19'
import FolioDigitalThree20 from '../../pageSections/DigitalSections/Inner-Folio-Digital-3/FolioDigitalThree20'
import ReactGA from 'react-ga4'

function DigitalFolio3() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
     <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <div data-fetch-key="PageFetch:0">
            <FolioDigitalThree1 />
            <FolioDigitalThree2 />
            <FolioDigitalThree3 />
            <FolioDigitalThree4 />
            <FolioDigitalThree5 />
            <FolioDigitalThree6 />
            <FolioDigitalThree7 />
            <FolioDigitalThree8 />
            <FolioDigitalThree9 />
            <FolioDigitalThree11 />
            <FolioDigitalThree12 />
            <FolioDigitalThree13 />
            <FolioDigitalThree14 />
            <FolioDigitalThree15 />
            <FolioDigitalThree16 />
            <FolioDigitalThree17 />
            <FolioDigitalThree18 />
            <FolioDigitalThree19 />
            <FolioDigitalThree20 />
            <FolioDigitalThree10 />
          </div>
        </div>
      </div>
    </main>
  )
}

export default DigitalFolio3
