import React from "react";
import { Outlet, Link } from "react-router-dom";

function BrandingMessage() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Branding
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Branding baby!</h2>
            <p className="mobile-small">
              Swek Interactive loves{" "}
              <Link to="/brandinginner">
                <a>branding and brand development.</a>
              </Link>
              . Sometimes we start from scratch, but just as often, we chart a
              new course for an existing brand. Once the brand's vision,
              mission, position, and tone are in place, we move on to the{" "}
              <Link to="/designinner">
                <a>
                  visual identity<strong></strong>
                </a>
              </Link>
              <strong> </strong>which is compiled into a graphic profile. In
              this way, we create recognition and a common thread in all further
              communication. When everything is in place, it might be time to
              refine a clever{" "}
              <Link to="/connectinner">
                <a>
                  communication concept.<strong></strong>
                </a>
              </Link>
              <strong> </strong>as<strong> </strong>generates engagement and
              brand awareness in the target audience. Our copywriters craft
              confident and compelling <Link to="/copyinner">
              <a>copy</a></Link> for advertising campaigns, websites, newsletters,
              social media, and customer magazines.
            </p>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default BrandingMessage;
