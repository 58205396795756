import React from "react";

function FolioDigital4() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56
            >
              Sociala medier
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2 className="black-convert">Vi engagerar KFF-fans i sociala medier</h2>
            <p className="black-convert">
              KFF har fått mycket uppmärksamhet och positiv feedback för sin
              kommunikation i digitala och sociala medier. Här är idé,
              kanalkännedom och timing de tydligaste framgångsfaktorerna snarare
              än påkostade produktioner. Strategin är ganska enkel: Trams
              säljer! Vi vill roa (mycket) och retas (lite) utan att sparka
              nedåt i den berömda näringskedjan. Okej, vi har kanske gjort ett
              och annat undantag för Östers IF, men alltid med mycket hjärta och
              glimten i ögat.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital4;
