import React from "react";

function FolioDigital21() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56
             className="black-convert">
              Tech
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2 className="black-convert">Kalmarff.se och webbshop</h2>
            <p className="black-convert">
              Wilson ligger bakom UX/UI-design, programmering och löpande
              hantering av{" "}
              <a target="_blank" rel="noopener" href="https://www.kalmarff.se">
                kalmarff.se
              </a>{" "}
              med tillhörande{" "}
              <a
                target="_blank"
                rel="noopener"
                href="https://shop.kalmarff.se/"
              >
                e-handel
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital21;
