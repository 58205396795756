import React from "react";

function DigitalInfoSlice1() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Customized with maximum commitment
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>
              Why should Swek Interactive manage my digital marketing?
            </h2>
            <p className="mobile-small">
              We work with most digital marketing channels and always tailor the plan to your company's operations, needs and goals. We are just as committed to smaller, local marketing efforts as we are to larger and more long-term overall solutions. If you choose Swek Interactive as the agency for your digital marketing, you will get to know a loyal team consisting of digital specialists equipped with maximum competence in the latest technology.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DigitalInfoSlice1;
