import React from "react";

function InnerDesign4() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Fire path
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>Digital profile</h3>
            <p className="mobile-small">
              Brandpad is an interactive, digital platform developed for
              companies and organizations that want a unified expression and
              control over the visual part of the brand. <br />
            </p>
            <h4>Three good things</h4>
            <p className="mobile-small">
              <strong>01. Smooth </strong>- the entire graphic profile is
              gathered in one place
              <br />
              <strong>02. Accessible </strong>- easy access for authorized users
              <br />
              <strong>03. Fast </strong>- download the files you need when you
              need them
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerDesign4;
