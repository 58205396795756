import React from 'react'

function FolioTechThree4() {
  return (
     <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <div class="overflow-hidden">
                                        <div class="pb-[100%] relative w-full"><video src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/f8bb8927-353f-4289-8e04-d09cc10db617_Showcase_Hero.mp4" autoplay playsinline loop muted class="absolute inset-0 object-cover h-full w-full"></video></div>
                                    </div>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <div class="overflow-hidden">
                                        <div class="pb-[100%] relative w-full"><video src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/4193de43-be5a-4e60-8e28-0747010e602f_Showcase_LightsOn.mp4" autoplay playsinline loop muted class="absolute inset-0 object-cover h-full w-full"></video></div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioTechThree4
