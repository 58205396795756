import React, {useEffect} from 'react'
import FolioTechFive1 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive1'
import FolioTechFive2 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive2'
import FolioTechFive3 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive3'
import FolioTechFive4 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive4'
import FolioTechFive5 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive5'
import FolioTechFive6 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive6'
import FolioTechFive7 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive7'
import FolioTechFive8 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive8'
import FolioTechFive9 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive9'
import FolioTechFive10 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive10'
import FolioTechFive11 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive11'
import FolioTechFive12 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive12'
import FolioTechFive13 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive13'
import FolioTechFive14 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive14'
import FolioTechFive15 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive15'
import FolioTechFive16 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive16'
import FolioTechFive17 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive17'
import FolioTechFive18 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive18'
import FolioTechFive19 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive19'
import FolioTechFive20 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive20'
import FolioTechFive21 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive21'
import FolioTechFive22 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive22'
import FolioTechFive23 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive23'
import FolioTechFive24 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive24'
import FolioTechFive25 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive25'
import FolioTechFive26 from '../../pageSections/TechSections/Inner-Folio-Tech-5/FolioTechFive26'
import ReactGA from 'react-ga4'

function TechFolio5() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <FolioTechFive1 /> 
          <div data-fetch-key="PageFetch:0">
            <FolioTechFive2 /> 
            <FolioTechFive3 /> 
            <FolioTechFive4 /> 
            <FolioTechFive5 /> 
            <FolioTechFive6 /> 
            <FolioTechFive7 /> 
            <FolioTechFive8 /> 
            <FolioTechFive9 /> 
            <FolioTechFive10 /> 
            <FolioTechFive11 /> 
            <FolioTechFive12 /> 
            <FolioTechFive13 /> 
            <FolioTechFive14 /> 
            <FolioTechFive15 /> 
            <FolioTechFive16 /> 
            <FolioTechFive17 /> 
            <FolioTechFive18 /> 
            <FolioTechFive19 /> 
            <FolioTechFive20 /> 
            <FolioTechFive21 /> 
            <FolioTechFive22 /> 
            <FolioTechFive23 /> 
            <FolioTechFive24 /> 
            <FolioTechFive25 /> 
            <FolioTechFive26 /> 
            
          </div>
        </div>
      </div>
    </main>
  )
}

export default TechFolio5
