import React, {useEffect} from 'react'
import GridAnimation1 from '../../pageSections/StudioSections/InnerAnimation/GridAnimation1'
import GridAnimation2 from '../../pageSections/StudioSections/InnerAnimation/GridAnimation2'
import GridAnimation3 from '../../pageSections/StudioSections/InnerAnimation/GridAnimation3'
import GridAnimation4 from '../../pageSections/StudioSections/InnerAnimation/GridAnimation4'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Animation() {

  return (
    <main class="flex-grow contain">
      
     <Helmet>
        <meta charSet="utf-8" />
        <title>Animation</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
        <GridAnimation1 />
        <GridAnimation2 />
        <GridAnimation3 />
        <GridAnimation4 />
        </div>
      </div>
    </main> 
  )
}

export default Animation
