import React from "react";

function KalmaFolio41() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <div class="overflow-hidden">
          <div class="pb-[56%] relative w-full">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723244590/SWEK/images/GenOrganics/m3_r1mj21.mp4"
              autoPlay
              loop
              muted
            ></video>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KalmaFolio41;
