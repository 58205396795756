import React from 'react'

function FolioDigitalFour11() {
  return (
    <section class="py-20 lg:py-40 bg-lapis" data-v-7dc95f74>
                                <div class="wrapper" data-v-7dc95f74>
                                    <div class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10" data-v-7dc95f74>
                                        <div class="w-full lg:w-1/2" data-v-7dc95f74>
                                            <div class="white-smiley link rich-text" data-v-7dc95f74>
                                                <h3>Vill du veta mer? Kontakta Emmy!</h3>
                                                <p>Emmy Karlsson<br/>Projektledare<br/><a href="mailto:emmy@wilsoncreative.se">Maila mig</a><br/><a href="tel:073-097 22 29">073-097 22 29</a></p>
                                            </div>
                                        </div>
                                        <div class="w-full lg:w-1/2" data-v-7dc95f74>
                                            <div class="w-full pb-[70%] relative" data-v-7dc95f74><img src="https://images.prismic.io/wilsoncreative/e6f1a164-6c42-42de-af04-2aa32b8ae5cc_T03KUTTFU-U038W38U362-0751178e4615-512.jpeg?auto=compress,format" alt="Projektledare Emmy Karlsson" class="w-full h-full inset-0 object-cover absolute"
                                                    data-v-7dc95f74/></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioDigitalFour11
