import React from "react";
import { Outlet, Link } from "react-router-dom";

function TechMessageSlice() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex custom-padding" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Web agency since 2019
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>More than a web agency.</h2>
            <p className="mobile-small">
              Since our start in 2019, our web agency has been involved in
              several exciting <Link to='/techwebdev'><a>web projects.</a></Link>{" "}
              across all possible areas. Our driving force and ultimate goals are
              to be at the forefront both technically and in terms of design. We
              love building websites that enhance our clients' competitiveness.{" "}
            </p>
            <p className="mobile-small">
              No mission is too big or too small; we are accustomed to complex
              challenges and love working with clients who have high
              expectations. Buying a website or an online store is not an
              everyday occurrence, and that's why it's crucial to choose the
              right agency. Swek Interactive is the choice when you want a
              caring and responsive full-service agency that not only
              understands the web. We are a fully-fledged design agency that
              also offers <Link to='/brandinginner'><a>brand development.</a></Link>,
              <Link to='/digital'><a>marketing in digital and social media</a></Link> as
              well as <Link to='/studio'><a>content production.</a>.</Link>{" "}
            </p>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default TechMessageSlice;
