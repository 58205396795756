import React from "react";

function CoDigital4() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>Moving content</h4>
              <p className="mobile-small">
                Moving content of various kinds completely dominates social
                media. And the development does not seem to stop, quite the
                opposite. Social platforms such as Facebook, Instagram and
                TikTok are leading the way with constantly new video formats.
                Video is rewarded when it comes to exposure and gets up to 10x
                more engagement than static content. This development also means
                that the requirements for the content are constantly being
                raised. We create mobile content adapted for:
              </p>
              <ul>
                <li>Social Media</li>
                <li>Homepages</li>
                <li>E-commerce</li>
                <li>Digital presentations</li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243154/SWEK/images/Others/as_lwzrv1.png"
                alt="Content marketing - Kvinna på Cresent. "
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoDigital4;
