import React from "react";

function SmDigital9() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Advertising on Instagram</h3>
              <p className="mobile-small">
                Instagram is a dream for brands looking to sell visual stories. The platform is particularly popular with younger users and is ideal for branding. In addition to posting high-quality photos and videos, you should create engaging Instagram Stories and Reels. Use IGTV for longer videos and consider doing live broadcasts to increase engagement. To reach new target groups, influencer collaborations can be an effective complement to Instagram advertising. Pay close attention to the aesthetics of your feed and use Instagram's analytics tools to understand what types of posts perform best. Consider the timing and frequency of your posts.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/f6b9a7d2-2691-439b-94c2-b822b2ab0555_insta.png?auto=compress,format"
                alt="Annonsering på Instagram"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital9;
