import React from "react";

function GridPhoto2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Swek Interactive
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Simply great pictures</h2>
            <p className="mobile-small">
              Whether it's content for social media, portrait photos for a
              website or product photos for a webshop - Swek Interactive is your
              go-to photographer most of the time.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GridPhoto2;
