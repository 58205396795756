import React from "react";

function FolioTechTwo6() {
  return (
    <section
      class="text-slice py-20 lg:py-40 bg-white mydark-bg"
      data-v-2ac86e56
    >
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56
            >
              bravura.se
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <p>
              Sök jobb eller hitta nya medarbetare på{" "}
              <a target="_blank" rel="noopener" href="https://bravura.se">
                bravura.se
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioTechTwo6;
