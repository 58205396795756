import React from "react";

function SmDigital5() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56>
              SoMe is measurable
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>How is your social media advertising performing?</h3>
            <p className="mobile-small">
              The strongest advantage of social media advertising is measurability. Measuring the results of the efforts constantly gives us new insights. Here we simply find out which messages, formats and channels work well and what can be improved. That analysis then forms the basis for further optimization and contributes ideas for future campaigns. Working this way leads to smarter, more effective social media marketing in both the short and long term.{" "}
            </p>
            <p className="mobile-small">
              Do you have questions about how to take your social media presence and advertising to the next level? Then you contact us now!
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital5;
