import React from "react";

function GridPhoto3() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>Photo menu</h4>
              <ul>
                <li>Advertising photography</li>
                <li>Product photography</li>
                <li>Portrait photography</li>
                <li>Environmental photography</li>
                <li>Fashion photography</li>
                <li>Food photography</li>
                <li>Drone photography</li>
                <li>Reportage photography</li>
                <li>Etc...</li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243750/SWEK/images/Photo/3_o8cbqy.jpg"
                alt="Drags Plåt truck"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GridPhoto3;
