import React, {useEffect} from 'react'
import InnerConcept1 from '../../pageSections/BrandingSections/Inner-Concept/InnerConcept1'
import InnerConcept2 from '../../pageSections/BrandingSections/Inner-Concept/InnerConcept2'
import InnerConcept3 from '../../pageSections/BrandingSections/Inner-Concept/InnerConcept3'
import InnerConcept4 from '../../pageSections/BrandingSections/Inner-Concept/InnerConcept4'
import InnerConcept5 from '../../pageSections/BrandingSections/Inner-Concept/InnerConcept5'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function ConnectInner() {

  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Concept</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <InnerConcept1 />
            <InnerConcept2 />
            {/* <InnerConcept3 /> */}
            <InnerConcept4 />
            <InnerConcept5 />
        </div>
      </div>
    </main> 
  )
}

export default ConnectInner
