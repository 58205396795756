import React from "react";

function KalmaFolio3() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243248/SWEK/images/GenOrganics/1_cwp9pr.jpg"
            alt="Gen-Organics Brand strategy and marketing"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default KalmaFolio3;
