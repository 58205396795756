import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'



const firebaseConfig = {
  apiKey: "AIzaSyB7npSLZ2C8lIv9MAHq941mkTmsswfZKj4",
  authDomain: "contact-form-c062c.firebaseapp.com",
  projectId: "contact-form-c062c",
  storageBucket: "contact-form-c062c.appspot.com",
  messagingSenderId: "448431411781",
  appId: "1:448431411781:web:bf7410220416e57aab092c",
  measurementId: "G-133PG8RCFW"
};

if (!firebase.app.lenth) {
  firebase.initializeApp(firebaseConfig)
}

export const firestore =  firebase.firestore()

