import React, {useEffect} from 'react'
import Stories1a from '../../pageSections/StoriesSections/StoriesInner2/Stories1a'
import Stories3a from '../../pageSections/StoriesSections/StoriesInner2/Stories3a'
import Stories2a from '../../pageSections/StoriesSections/StoriesInner2/Stories2a'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Blog2() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Stories - Color</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <Stories1a />
            <Stories2a />
            <Stories3a />
        </div>
      </div>
    </main> 
  )
}

export default Blog2
