import React from "react";

function DaDigital3() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              SoMe
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>Social media strategy
</h3>
            <p className="mobile-small">
              When we make a strategy for social media, the focus is (not entirely unexpectedly) on the use of Facebook, Instagram, LinkedIn and so on. A social media strategy is suitable for companies and organizations that to some extent want to manage their social media presence themselves and need something to lean on.
            </p>
            <h4>Social media guidelines may include:</h4>
            <ul>
              <li>Communication and impact goals</li>
              <li>Strategic focus</li>
              <li>Target groups</li>
              <li>Channels and communication surfaces</li>
              <li>Tonality</li>
              <li>Suggestions for relevant content</li>
              <li>What can be measured and how</li>
              <li>Focus areas and action plan</li>
              <li>Content calendar</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DaDigital3;
