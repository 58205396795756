import React from 'react'

function FolioDigitalFour4() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
              <div class="overflow-hidden">
                  
                  <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
                      
                      
                        <img loading="lazy"
                        src="https://images.prismic.io/wilsoncreative/7fdef3ce-bf59-413c-a161-c9013067288a_Guldfageln.png?auto=compress,format" alt="Guldfågeln filmutdrag" class="absolute inset-0 object-cover h-full w-full cursor-pointer" />
                      
                      <img src="/_nuxt/img/play.17179b4.svg" alt="Starta uppspelning" loading="lazy" class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer" /></figure>
                                       
               </div>
                                </div>
    </section>
  )
}

export default FolioDigitalFour4
