import React, {useEffect} from 'react'
import InnerCopy1 from '../../pageSections/BrandingSections/Inner-Copy/InnerCopy1'
import InnerCopy2 from '../../pageSections/BrandingSections/Inner-Copy/InnerCopy2'
import InnerCopy3 from '../../pageSections/BrandingSections/Inner-Copy/InnerCopy3'
import InnerCopy4 from '../../pageSections/BrandingSections/Inner-Copy/InnerCopy4'
import InnerCopy5 from '../../pageSections/BrandingSections/Inner-Copy/InnerCopy5'
import InnerCopy6 from '../../pageSections/BrandingSections/Inner-Copy/InnerCopy6'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function CopyInner() {

  return (
    <main class="flex-grow contain">
      
    <Helmet>
        <meta charSet="utf-8" />
        <title>Copy</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
           <InnerCopy1 />
           <InnerCopy2 />
           <InnerCopy3 />
           <InnerCopy4 />
           <InnerCopy5 />
           <InnerCopy6 />
        </div>
      </div>
    </main> 
  )
}

export default CopyInner
