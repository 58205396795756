import React from "react";

function FolioDigital11() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <div class="overflow-hidden">
          <div class="pb-[56%] relative w-full">
            <video
              src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/0188de42-976e-4677-a8e1-3d554de30b07_FB_KALMARFF_KVINNOJOUREN_BUSS_10MBPS_30s_H264.mp4"
              autoplay
              playsinline
              loop
              muted
              class="absolute inset-0 object-cover h-full w-full"
            ></video>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital11;
