import React from 'react'

function OlandFolio4() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
                                <div class="wrapper lg:flex" data-v-2ac86e56>
                                    <div class="lg:w-3/12" data-v-2ac86e56>
                                        <div class="transform translate-y-10 opacity-0" data-v-2ac86e56><span class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight" data-v-2ac86e56>18 grader</span></div>
                                    </div>
                                    <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
                                        <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56 >
                                            <h2>Framåtlutad position</h2>
                                            <p>Vi har tagit fasta på Ölands geometriska form och att hela ön har en vinkel på 18 grader. Här finns en framåtlutad position som ger energi. En enkel och avskalad stil som vi låtit genomsyra hela identiteten.
                                                Resultatet är enkelt, opretentiöst men samtidigt självsäkert och kraftfullt. Den raka formen ger en tidlös känsla som fungerar lika bra nu som i framtiden.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default OlandFolio4
