import React from "react";

function KalmaFolio4() {
  return (
    <section
      class="text-slice py-20 lg:py-40 bg-white mydark-bg"
      data-v-2ac86e56
    >
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Emblem
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2>Purpose:</h2>
            <p className="mobile-small">
              To promote wellness and sustainability through natural and organic products, enhancing the health and beauty of individuals and families.

            </p>
            <p  className="mobile-small">
              Vision:
To become a trusted global leader in organic health and beauty products, inspiring a healthier and more sustainable lifestyle for all.
Mission:
To provide high-quality organic soaps and skincare products that nurture both the body and the planet, catering to the needs of adults and children alike.

            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KalmaFolio4;
