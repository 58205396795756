import React from 'react'

function FolioTech5() {
  return (
     <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/4cbc0dcc-8db9-42a3-bc45-9fffca645fe2_bracke_case_webb_1x1_1.jpg?auto=compress,format" alt="" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/81b94a98-db95-4990-8b52-42bd52489c61_bracke_case_webb_1x1_2.jpg?auto=compress,format" alt="" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTech5
