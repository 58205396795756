import React, {useEffect} from 'react'
import TechEcommerce1 from '../../pageSections/TechSections/Inner-E-Commerce/TechEcommerce1'
import TechEcommerce2 from '../../pageSections/TechSections/Inner-E-Commerce/TechEcommerce2'
import TechEcommerce3 from '../../pageSections/TechSections/Inner-E-Commerce/TechEcommerce3'
import TechEcommerce4 from '../../pageSections/TechSections/Inner-E-Commerce/TechEcommerce4'
import TechEcommerce5 from '../../pageSections/TechSections/Inner-E-Commerce/TechEcommerce5'
import TechEcommerce6 from '../../pageSections/TechSections/Inner-E-Commerce/TechEcommerce6'
import TechEcommerce7 from '../../pageSections/TechSections/Inner-E-Commerce/TechEcommerce7'
import TechEcommerce8 from '../../pageSections/TechSections/Inner-E-Commerce/TechEcommerce8'
import TechEcommerce9 from '../../pageSections/TechSections/Inner-E-Commerce/TechEcommerce9'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function TechEcommercePage() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>e-Commerce</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <TechEcommerce1 />
          <TechEcommerce2 />
          <TechEcommerce3 />
          {/* <TechEcommerce4 /> */}
          <TechEcommerce5 />
          <TechEcommerce6 />
          <TechEcommerce7 />
          <TechEcommerce8 />
          <TechEcommerce9 />
        </div>
      </div>
    </main>
  )
}

export default TechEcommercePage

