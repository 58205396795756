import React from "react";

function SwekMessage4() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              01/04. Love
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h4>Loving Swek
</h4>
            <p className="mobile-small">
              We establish the foundation of our business upon genuine affection and trust, devoid of cynicism. We foster a culture of genuine care and curiosity among our team, embracing each other fully. Love serves as the bedrock, providing the security necessary for creativity, freedom, and growth. It also stands as the paramount element in the communication we cultivate and in our relationships with valued customers. 
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SwekMessage4;
