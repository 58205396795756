import React from 'react'

function OlandFolio20() {
  return (
     <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/ede92dbc-ebad-44be-8df2-8bcd68280611_oland_16x9_pattern_2.png?auto=compress,format" alt="Öland mönster" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio20
