import React from "react";

function TechSeo1() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">

        <img
          src="./images/Headers/tech/swek-seo.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        >
        </img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white mobile-small">
              SEO agency for effective search engine optimization.
            </h1>
          </div>

        
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Rank</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">higher</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top"> on</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Google</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">with</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">SEO</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  The purpose of SEO is to make your website visible to the target audience by ranking high on search engines such as Google.
                </span>
              </p>
              <p className="mobile-small">
                To create a user-friendly experience, which is also liked by search engines, we work with search engine optimization on several fronts: Technical SEO, On page SEO and Off Page SEO.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechSeo1;
