import React from "react";

function SmDigital12() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Advertising on TikTok</h3>
              <p className="mobile-small">
                TikTok has quickly become a leading platform for short, creative
                videos. The platform's young and energetic audience offers a
                unique opportunity to reach out with innovative and viral
                messages. Create original and entertaining videos that reflect
                the latest trends. Use music, effects and popular challenges to
                make your content more engaging. TikTok is also a great place
                for collaborations with influencers. Be quick and flexible to
                adapt your advertising on TikTok to rapidly changing trends.
                TikTok values ​​authenticity and creativity, so don't be afraid
                to experiment with different formats and styles.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/58e1e6dd-9929-4744-bd8e-e2af49d1a979_tiktok.png?auto=compress,format"
                alt="Annonsering på TikTok"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital12;
