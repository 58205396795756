import React, {useEffect} from 'react'
import DigitalStrategyHome from '../../pageSections/DigitalSections/Inner-Digital-Strategy/DigitalStrategyHome'
import DaDigital1 from '../../pageSections/DigitalSections/Inner-Digital-Strategy/DaDigital1'
import DaDigital2 from '../../pageSections/DigitalSections/Inner-Digital-Strategy/DaDigital2'
import DaDigital3 from '../../pageSections/DigitalSections/Inner-Digital-Strategy/DaDigital3'
import DaDigital4 from '../../pageSections/DigitalSections/Inner-Digital-Strategy/DaDigital4'
import DaDigital5 from '../../pageSections/DigitalSections/Inner-Digital-Strategy/DaDigital5'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function DigitalStrategy() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Digital Strategy</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
                  <DigitalStrategyHome />
                  <DaDigital1 />
                  <DaDigital2 />
                  <DaDigital3 />
                  <DaDigital4 />
                  <DaDigital5 />
        </div>
      </div>
    </main>
  )
}

export default DigitalStrategy
