import React from 'react'

function FolioTechTwo5() {
  return (
    <section class="py-20 lg:py-40 bg-lapis" data-v-7dc95f74>
                                <div class="wrapper" data-v-7dc95f74>
                                    <div class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10" data-v-7dc95f74>
                                        <div class="w-full lg:w-1/2" data-v-7dc95f74>
                                            <div class="white-smiley link rich-text" data-v-7dc95f74 >
                                                <h3>Vill du veta mer? Kontakta Elin!</h3>
                                                <p>Elin Bergqvist<br/>Projektledare<br/><a href="mailto:elin.bergqvist@wilsoncreative.se">Maila mig</a><br/><a href="tel:070-279 62 65">070-279 62 65</a></p>
                                            </div>
                                        </div>
                                        <div class="w-full lg:w-1/2" data-v-7dc95f74>
                                            <div class="w-full pb-[70%] relative" data-v-7dc95f74><img src="https://images.prismic.io/wilsoncreative/ae623399-5d7f-4dff-b98d-2be17267a929_DSC06502-1-800x800.jpeg?auto=compress,format" alt="Elin Bergqvist, Projektledare Wilson Creative" class="w-full h-full inset-0 object-cover absolute"
                                                    data-v-7dc95f74 /></div>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioTechTwo5
