import React, {useEffect} from 'react'
import TechSeo1 from '../../pageSections/TechSections/SEO/TechSeo1'
import TechSeo2 from '../../pageSections/TechSections/SEO/TechSeo2'
import TechSeo3 from '../../pageSections/TechSections/SEO/TechSeo3'
import TechSeo4 from '../../pageSections/TechSections/SEO/TechSeo4'
import TechSeo5 from '../../pageSections/TechSections/SEO/TechSeo5'
import TechSeo6 from '../../pageSections/TechSections/SEO/TechSeo6'
import TechSeo7 from '../../pageSections/TechSections/SEO/TechSeo7'
import TechSeo8 from '../../pageSections/TechSections/SEO/TechSeo8'
import TechSeo9 from '../../pageSections/TechSections/SEO/TechSeo9'
import TechSeo10 from '../../pageSections/TechSections/SEO/TechSeo10'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function TechSeo() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>SEO</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <TechSeo1 />
          <TechSeo2 />
          <TechSeo3 />
          <TechSeo4 />
          <TechSeo5 />
          <TechSeo6 />
          <TechSeo7 />
          <TechSeo8 />
          <TechSeo9 />
          <TechSeo10 />
        </div>
      </div>
    </main>
  )
}

export default TechSeo
