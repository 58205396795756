import React from "react";

function KalmaFolio43() {
  return (
    <section
      class="text-slice py-20 lg:py-40 bg-white  mydark-bg"
      data-v-2ac86e56
    >
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Sammanfattning
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2>Brand Insight:</h2>
            <p className="mobile-small">
              Consumers seek products that not only deliver results but also align with their values of health and sustainability. Gen Organics fulfils this need by offering organic skincare solutions that not only benefit the individual but also contribute positively to the environment, fostering a sense of well-being and responsibility among its customers.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KalmaFolio43;
