import React, {useEffect} from 'react'
import OlandFolio1 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio1'
import OlandFolio2 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio2'
import OlandFolio3 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio3'
import OlandFolio4 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio4'
import OlandFolio5 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio5'
import OlandFolio6 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio6'
import OlandFolio7 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio7'
import OlandFolio8 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio8'
import OlandFolio9 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio9'
import OlandFolio10 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio10'
import OlandFolio11 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio11'
import OlandFolio12 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio12'
import OlandFolio13 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio13'
import OlandFolio14 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio14'
import OlandFolio15 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio15'
import OlandFolio16 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio16'
import OlandFolio17 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio17'
import OlandFolio18 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio18'
import OlandFolio19 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio19'
import OlandFolio20 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio20'
import OlandFolio21 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio21'
import OlandFolio22 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio22'
import OlandFolio23 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio23'
import OlandFolio24 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio24'
import OlandFolio25 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio25'
import OlandFolio26 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio26'
import OlandFolio27 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio27'
import OlandFolio28 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio28'
import OlandFolio29 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio29'
import OlandFolio30 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio30'
import OlandFolio31 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio31'
import OlandFolio32 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio32'
import OlandFolio33 from '../../pageSections/BrandingSections/BrandFolio1/OlandFolio33'
import ReactGA from 'react-ga4'

function OlandFolio() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <OlandFolio1 />
          <div data-fetch-key="PageFetch:0">
          <OlandFolio2 />
          <OlandFolio3 />
          <OlandFolio4 />
          <OlandFolio5 />
          <OlandFolio6 />
          <OlandFolio7 />
          <OlandFolio8 />
          <OlandFolio9 />
          <OlandFolio10 />
          <OlandFolio11 />
          <OlandFolio12 />
          <OlandFolio13 />
          <OlandFolio14 />
          <OlandFolio15 />
          <OlandFolio16 />
          <OlandFolio17 />
          <OlandFolio18 />
          <OlandFolio19 />
          <OlandFolio20 />
          <OlandFolio21 />
          <OlandFolio22 />
          <OlandFolio23 />
          <OlandFolio24 />
          <OlandFolio25 />
          <OlandFolio26 />
          <OlandFolio27 />
          <OlandFolio28 />
          <OlandFolio29 />
          <OlandFolio30 />
          <OlandFolio31 />
          <OlandFolio32 />
          <OlandFolio33 />
                      
          </div>
        </div>
      </div>
    </main>
  )
}

export default OlandFolio
