import React from "react";
import { Outlet, Link } from "react-router-dom";

function TechMiniFolio() {
  return (
    <section data-fetch-key="Cases:1" class="case-slice">
      <div class="w-full">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-1/2">
            <a class="block w-full pb-[56%] relative transform origin-center group">
              <Link to="/strifefolio">
                <div class="w-full h-full overflow-hidden absolute inset-0 pointer-events-none">
                  <div class="w-full pb-[56%] relative">
                    <video
                      class="absolute inset-0 object-cover h-full w-full"
                      controls
                      src="../images/Tech/Dots2.mp4"
                      autoPlay
                      loop
                      muted
                    ></video>
                  </div>
                </div>
                <div class="wrapper absolute left-0 right-0 mx-auto bottom-8 md:bottom-12 lg:bottom-16 z-20 group-hover:opacity-0 duration-500 ease-out-expo transform group-hover:translate-y-10">
                  <div class="flex items-center text-xl">
                    Uzawi
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-laser"
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="w-full h-full absolute inset-0 bg-darkest-overlay bg-opacity-50 flex items-center opacity-0 group-hover:opacity-100 duration-500 transition-all ease-out-expo">
                  <div class="w-full overflow-hidden">
                    <div
                      class="overflow-hidden w-full leading-tight transform translate-y-20 group-hover:translate-y-0 duration-1000 transition-all ease-out-expo"
                      data-v-32a13d1f
                    >
                      <div
                        class="scroll-text whitespace-nowrap transform pointer-events-none inline-block text-[4.5vw]"
                        data-v-32a13d1f
                      >
                        <span class="inline-block" data-v-32a13d1f>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            <span data-v-32a13d1f> </span>Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            <span data-v-32a13d1f> </span>Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            <span data-v-32a13d1f> </span>Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            <span data-v-32a13d1f> </span>Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            <span data-v-32a13d1f> </span>Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                        </span>{" "}
                        <span class="inline-block" data-v-32a13d1f>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </a>
          </div>

          <div class="w-full lg:w-1/2">
            <a class="block w-full pb-[56%] relative transform origin-center group">
              <Link to="/techfolio5">
                <div class="w-full h-full overflow-hidden absolute inset-0 pointer-events-none">
                  <div class="w-full pb-[56%] relative">
                    <picture>
                      <source
                        srcset="./images/Rashid/7.jpg"
                        media="(min-width: 650px)"
                      />{" "}
                      <img
                        src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243171/SWEK/images/Rashid/7_rpfmgw.jpg"
                        alt="En kycklingwok från Guldfågeln med spännande färger och ingredienser"
                        loading="lazy"
                        class="w-full h-full object-cover absolute inset-0 case"
                      />
                    </picture>
                  </div>
                </div>
                <div class="wrapper absolute left-0 right-0 mx-auto bottom-8 md:bottom-12 lg:bottom-16 z-20 group-hover:opacity-0 duration-500 ease-out-expo transform group-hover:translate-y-10">
                  <div class="flex items-center text-xl">
                    Chef Rashid
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-laser"
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="w-full h-full absolute inset-0 bg-darkest-overlay bg-opacity-50 flex items-center opacity-0 group-hover:opacity-100 duration-500 transition-all ease-out-expo">
                  <div class="w-full overflow-hidden">
                    <div
                      class="overflow-hidden w-full leading-tight transform translate-y-20 group-hover:translate-y-0 duration-1000 transition-all ease-out-expo"
                      data-v-32a13d1f
                    >
                      <div
                        class="scroll-text whitespace-nowrap transform pointer-events-none inline-block text-[4.5vw]"
                        data-v-32a13d1f
                      >
                        <span class="inline-block" data-v-32a13d1f>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            <span data-v-32a13d1f> </span>Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            <span data-v-32a13d1f> </span>Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            <span data-v-32a13d1f> </span>Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            <span data-v-32a13d1f> </span>Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            <span data-v-32a13d1f> </span>Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                        </span>{" "}
                        <span class="inline-block" data-v-32a13d1f>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                          <span class="whitespace-nowrap" data-v-32a13d1f>
                            Explore projects
                            <span class="slash" data-v-32a13d1f>
                              /
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </a>
          </div>
        </div>

        <div class="wrapper flex justify-center my-10 md:my-20">
          <Link to="/project">
            {" "}
            <a class="button">
              <span>More projects</span>{" "}
              <svg
                width="23"
                height="24"
                viewBox="0 0 23 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </a>
          </Link>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default TechMiniFolio;
