import React from 'react'

function FolioDigitalTwo4() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <div class="overflow-hidden">
                                        <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/59aa964e-ddfe-42b2-be24-89f850aea2a8_KE+-+1+.png?auto=compress,format" alt="Kalmar Energi filmutdrag" class="absolute inset-0 object-cover h-full w-full cursor-pointer"/>                                            <img src="/_nuxt/img/play.17179b4.svg" alt="Starta uppspelning" loading="lazy" class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"/></figure>
                                        
                                    </div>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <div class="overflow-hidden">
                                        <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/1b5bd1a0-0298-43d0-97bd-f1b66c3ed41b_KE+-+2.png?auto=compress,format" alt="Kalmar Energi filmutdrag" class="absolute inset-0 object-cover h-full w-full cursor-pointer"/>                                            <img src="/_nuxt/img/play.17179b4.svg" alt="Starta uppspelning" loading="lazy" class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"/></figure>
                                        
                                    </div>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <div class="overflow-hidden">
                                        <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/ae06a7f6-20a7-4efd-8e87-b98c4d9ad16f_Hans+El+-+KE+3.png?auto=compress,format" alt="Kalmar Energi filmutdrag" class="absolute inset-0 object-cover h-full w-full cursor-pointer"/>                                            <img src="/_nuxt/img/play.17179b4.svg" alt="Starta uppspelning" loading="lazy" class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"/></figure>
                                        
                                    </div>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <div class="overflow-hidden">
                                        <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/3d4c6799-8828-45f5-a9f5-036fe2d26db4_KE+-+4.png?auto=compress,format" alt="Kalmar Energi filmutdrag" class="absolute inset-0 object-cover h-full w-full cursor-pointer"/>                                            <img src="/_nuxt/img/play.17179b4.svg" alt="Starta uppspelning" loading="lazy" class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"/></figure>
                                        
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioDigitalTwo4
