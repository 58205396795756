import React from "react";

function GaDigital7() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Google Ads - Google Shopping</h3>
              <p className="mobile-small">
                For e-tailers, Google Shopping is a hard-to-beat marketing channel. Here you advertise your goods directly in the search results and also end up above the organic search with image, price and offer. Those who search for the specific product are often far along in their purchase process, which is why the conversion rate is extra high when advertising with Google Shopping. On paper it looks simple, but the truth is that it takes a lot of fixes and tricks to create structure, an optimal product feed and achieve maximum bang for the buck. But you, we'll keep an eye on you so you don't have to. Or we will teach you along the way.{" "}
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/a3686c5b-c83b-4b9d-adba-fad60370e708_Google_shopping.png?auto=compress,format"
                alt="Google Shopping logo"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GaDigital7;
