import React from "react";

function StudioHome() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243253/SWEK/images/Headers/studio_ibe3ak.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723294545/SWEK/images/Headers/stories2_jyhcvf.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case mobile"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              A creative production agency
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Swek</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Studio</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  Movies create emotions, and we create movies.
                </span>{" "}
              </p>
              <p className="mobile-small">
                Everything from writing film scripts to rowing recordings and
                tinkering with final editing. We make animations and
                infographics that stand out and commercials that make business
                go bananas on social media. And of course we take photos with
                you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StudioHome;
