import React from "react";

function TechSeo4() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Technical SEO
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Technical SEO is the heart of your search engine optimization</h2>
            <p className="mobile-small">
              Technical SEO is about how your website is structured and how well it meets Google's user experience requirements. Optimized code is critical to ranking higher on Google. We make sure your website is fast, easy to navigate and optimized for mobile users, which is essential to maintaining a fiery and long-lasting love affair with Google.
            </p>
            <h3>We optimize the technical basis of your website</h3>
            <p className="mobile-small">
              We dive deep into your website's structure to identify and fix any technical flaws. This includes optimizing load times, ensuring the site is mobile friendly and accessible to all users. By optimizing these factors, we ensure that your website is not only friendly to search engines like Google, but also to your visitors.

Swek Tech

            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechSeo4;
