import React from 'react'

function OlandFolio26() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/afbcb440-9401-4f75-af24-93837006b5e1_oland_16x9_logo_vinter.jpg?auto=compress,format" alt="Öland vinterlandskap" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio26
