import React from "react";

function FolioTechThree1() {
  return (
    <section class="pt-page pb-20 lg:pb-40">
      <div class="wrapper lg:flex">
        <div class="lg:w-3/12">
          <span class="label block mb-label">Myrstack</span>
        </div>
        <div class="lg:w-9/12">
          <div class="huge-title">
            <h1>Myrstack - ambitiös webb till flitiga myror</h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioTechThree1;
