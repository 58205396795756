import React from "react";
import { Outlet, Link } from "react-router-dom";

function BrandingServicesGrid() {
  return (
    <section class="slice">
      <div class="wrapper">
        <div class="text-laser text-[10vw] md:text-[7vw] leading-[0.85] mb-10 lg:mb-20"></div>
        <div class="rich-text green-smiley"></div>
        <div class="over-flow-x-hidden swiper-container mt-10 lg:mt-20">
          <div class="swiper-wrapper relative h-full z-[1] flex transition-transform transform-gpu lg:flex lg:flex-wrap lg:-mx-8 lg:-mb-16 flexing">
            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/brandinginner">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Read more about Branding
                      </span>
                    </a>{" "}
                  </Link>

                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/6b6e60eb-0a43-45cf-9ad6-6fb9928dc96a_location-arrow.svg"
                    alt="Ikon - pil"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      Branding
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p  className="mobile-small">
                        Whether you want to develop an existing brand or create
                        a brand new one, Swek is the agency for you. We adapt
                        the arrangement 100% based on your needs.
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read more
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/designinner">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Read more about Design
                      </span>
                    </a>{" "}
                  </Link>

                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/10333ad9-d203-46d6-a8f1-08041d87d3c2_ruler-pencil.svg"
                    alt="Ikon - penna och linjal"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      Design
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        Swek speaks the digital design language fluently.
                        Logo, colors, typography - yes, everything needed for a
                        flexible and user-friendly graphic profile.
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read more
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/connectinner">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Läs mer om Koncept
                      </span>
                    </a>{" "}
                  </Link>

                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/b725e1e4-7bc6-4bc5-bd8a-9272013b8ea3_direction-alt.svg"
                    alt="Ikon - skylt/pil"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      Concept
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        A good idea gets through noise, algorithms and ad
                        blockers completely without cheating. And good ideas are
                        the most fun we know when creating communication
                        concepts.
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read more
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/illustrationinner">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Läs mer om Illustration
                      </span>
                    </a>{" "}
                  </Link>

                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/77079ab1-3ac3-4c1b-b89b-f7dfd04d6cd9_vector.svg"
                    alt="Ikon - vektorgrafik"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      Illustration
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        Unique and creative illustrations contribute to an
                        easily accessible and exciting brand. An effective and
                        fun way to simply stand out from the crowd.
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read more
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/copyinner">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Läs mer om Copy
                      </span>
                    </a>
                  </Link>
                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/15caf063-c97c-4ba3-8393-8d3c095797b5_smallcap.svg"
                    alt="Ikon - bokstäver"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      Copy
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        We develop a tone that marries the brand and write
                        everything from lightning-fast Facebook posts to more
                        in-depth content.
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read more
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination lg:hidden"></div>

        <Outlet />
      </div>
    </section>
  );
}

export default BrandingServicesGrid;
