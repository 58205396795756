import React from "react";
import { Outlet, Link } from "react-router-dom";

function SmDigital4() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Paid social
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>Strategy, data analysis and content for paid social</h3>
            <p className="mobile-small">
              As we have mentioned, the work begins with a strategy for paid
              social. We review which social media platforms are most relevant
              based on business and target group and thus lay the foundation for
              creative and effective marketing with high returns in social
              media. Thanks to careful data analysis, we don't have to guess,
              but can make our decisions exclusively based on actual data. When
              strategy and analysis are in place, we move on to what is perhaps
              our absolute strength, namely relevant content. We have a
              professional team of {" "}
              <Link to='/copyinner'><a>copywriters</a></Link>,{" "}
              <Link to='/designinner'><a>designers</a></Link> {" "}and{" "}
              <Link to='/studio'><a>film creators</a></Link> {" "}standing by, ready to produce
              value-creating content that stands out in the noise.
            </p>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default SmDigital4;
