import React from "react";

function DigitalInfoSlice2() {
  return (
    <section class="slice bg-darkest" data-v-605c8685>
      <div class="wrapper" data-v-605c8685>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-605c8685
        >
          <div class="w-full lg:w-1/2" data-v-605c8685>
            <div class="green-smiley rich-text" data-v-605c8685>
              <h3>How does it work when hiring Swek Interactive?</h3>
              <ol>
                <li className="mobile-small">
                  We get to know each other and make an analysis of the current
                  situation.
                </li>
                <li className="mobile-small">
                  We start by setting goals, KPIs and a strategy.
                </li>
                <li className="mobile-small">
                  Activation begins. We produce content, publish and optimize.
                </li>
                <li className="mobile-small">
                  We analyze results and report, often in one meeting.
                </li>
                <li className="mobile-small">
                  New ideas based on new insights.
                </li>
                <li className="mobile-small">
                  New activation. In this way, we become sharper with each
                  effort.
                </li>
              </ol>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-605c8685>
            <div class="w-full pb-[70%] relative" data-v-605c8685>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243503/SWEK/images/Others/b-woman_xprkmq.jpg"
                alt="Specialist på digitala medier"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-605c8685
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DigitalInfoSlice2;
