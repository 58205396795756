import React, { useState } from "react";

function Footer() {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <footer
      data-fetch-key="data-v-1a40eb22:0"
      class="slice relative z-20 leading-footer tracking-tighter"
      data-v-1a40eb22
    >
      <div
        class="border-t border-darker py-10 lg:py-14 mt-10 lg:mt-14"
        data-v-1a40eb22
      >
        <div class="wrapper" data-v-1a40eb22>
          <section class="w-full" data-v-b2c68646 data-v-1a40eb22>
            <form
              method="POST"
              class="w-full flex items-center flex-col sm:flex-row"
              data-v-b2c68646
            >
              <div
                class="flex-1 w-full relative overflow-hidden"
                data-v-b2c68646
              >

                <label
                  for="email"
                  class="absolute cursor-pointer leading-normal text-2xl lg:text-5xl lg:leading-footer lg:tracking-tighter text-white top-1/2 transform -translate-y-1/2"
                  data-v-b2c68646
                >
                  <span data-v-b2c68646>Swek's news letter? click here!</span>
                </label>{" "}
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="E-postadress"
                  required
                  data-v-b2c68646
                ></input>
              </div>{" "}
              <button
                type="submit"
                class="pointer-events-none hidden sm:inline-flex button ml-autoself-end mt-5 sm:mt-0 sm:self-center opacity-0"
                data-v-b2c68646
              >
                Submit
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-3 block text-laser"
                  data-v-b2c68646
                >
                  <path
                    d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    data-v-b2c68646
                  ></path>
                </svg>
              </button>
            </form>
          </section>
        </div>
      </div>
      <div
        class="w-full border-t border-b border-darker py-10 lg:py-14 mb-10 lg:mb-14"
        data-v-1a40eb22
      >
        <div class="wrapper" data-v-1a40eb22>
          <div class="md:flex justify-between" data-v-1a40eb22>
            <div class="md:w-5/12 mb-8 lg:mb-0" data-v-1a40eb22>
              <div class="footer-text" data-v-1a40eb22>
                <p>
                  Buziga
                  <br />
                  Kampala, Uganda
                  <br /> <br />
                  
                  
                </p>
              </div>
            </div>

            <div class="md:w-5/12" data-v-1a40eb22>
              <div class="footer-text" data-v-1a40eb22>
                <p>
                  London <br />
                  Chrisp St. E14 6LL
                  <br /> <br />
                  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper" data-v-1a40eb22>
        <div
          class="md:flex justify-between text-laser footer-text"
          data-v-1a40eb22
        >
          <div class="md:block w-full md:w-5/12 mb-8 lg:mb-0" data-v-1a40eb22>
            <a
              href="mailto:info@wilsoncreative.se"
              class="w-full inline-block hover:underline"
              data-v-1a40eb22
            >
              info@swekinteractive.com
            </a>{" "}
            <a
              // href="tel:+46480363770"
              class="w-full inline-block hover:underline"
              data-v-1a40eb22
            >
              000-000 000
            </a>{" "}
            <br data-v-1a40eb22 />
            <br />{" "}
            <a
              // href="tel:+46850165660"
              class="w-full inline-block hover:underline"
              data-v-1a40eb22
            >
              {/* 0000-00 00 00 */}
            </a>
          </div>
          <div class="block w-full md:w-5/12" data-v-1a40eb22>
            <a
              href="https://www.instagram.com/swek_interactive/"
              class="flex items-center w-full md:w-5/12 lg:w-full myb-2 lg:mb-0 hover:underline"
              data-v-1a40eb22
            >
              <svg
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-10 lg:w-10 lg:h-14 transform translate-y-1 flex-shrink-0 flex-grow-0"
                data-v-1a40eb22
              >
                <path
                  fill="#7DFFAF"
                  d="M224 202.66A53.34 53.34 0 1 0 277.36 256A53.38 53.38 0 0 0 224 202.66Zm124.71-41a54 54 0 0 0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31 6.43a54 54 0 0 0-30.41 30.41c-8.28 21-6.43 71.05-6.43 94.33s-1.85 73.27 6.47 94.34a54 54 0 0 0 30.41 30.41c21 8.29 71 6.43 94.31 6.43s73.24 1.93 94.3-6.43a54 54 0 0 0 30.41-30.41c8.35-21 6.43-71.05 6.43-94.33s1.92-73.26-6.43-94.33ZM224 338a82 82 0 1 1 82-82a81.9 81.9 0 0 1-82 82Zm85.38-148.3a19.14 19.14 0 1 1 19.13-19.14a19.1 19.1 0 0 1-19.09 19.18ZM400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48Zm-17.12 290c-1.29 25.63-7.14 48.34-25.85 67s-41.4 24.63-67 25.85c-26.41 1.49-105.59 1.49-132 0c-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61 0-132c1.29-25.63 7.07-48.34 25.85-67s41.47-24.56 67-25.78c26.41-1.49 105.59-1.49 132 0c25.63 1.29 48.33 7.15 67 25.85s24.63 41.42 25.85 67.05c1.49 26.32 1.49 105.44 0 131.88Z"
                  data-v-1a40eb22
                ></path>
              </svg>{" "}
              <span class="ml-4 block" data-v-1a40eb22>
                {" "}
                @swek_interactive{" "}
              </span>
            </a>{" "}
            <a
              href="https://www.facebook.com/SwekInteractive/"
              class="flex items-center w-full md:w-5/12 lg:w-full my-2 lg:my-0 hover:underline"
              data-v-1a40eb22
            >
              <svg
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8 lg:w-10 lg:h-10 transform translate-y-1 flex-shrink-0 flex-grow-0"
                data-v-1a40eb22
              >
                <path
                  d="M6 48H24V31.5H18V24H24V18C24 13.028 28.028 9 33 9H39V16.5H36C34.344 16.5 33 16.344 33 18V24H40.5L37.5 31.5H33V48H42C45.308 48 48 45.308 48 42V6C48 2.69 45.308 0 42 0H6C2.69 0 0 2.69 0 6V42C0 45.308 2.69 48 6 48Z"
                  fill="#7DFFAF"
                  data-v-1a40eb22
                ></path>
              </svg>{" "}
              <span class="ml-4 block" data-v-1a40eb22>
                {" "}
                @SwekInteractive{" "}
              </span>
            </a>{" "}
            <a
              href="https://www.linkedin.com/company/swek-interactive"
              class="flex items-center w-full md:w-5/12 lg:w-full mt-2 lg:mt-0 hover:underline"
              data-v-1a40eb22
            >
              <svg
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8 lg:w-10 lg:h-10 transform translate-y-1 flex-shrink-0 flex-grow-0"
                data-v-1a40eb22
              >
                <path
                  d="M21.1703 0C29.3588 0 30 0.640501 30 8.82975V21.1695C30 29.3588 29.3588 30 21.1703 30H8.82975C0.64125 30 0 29.3588 0 21.1703V8.82975C0 0.640501 0.64125 0 8.82975 0H21.1703ZM9.77175 23.25V11.046H5.979V23.25H9.77175ZM7.87575 9.30075C9.0945 9.30075 10.086 8.30925 10.086 7.089C10.086 5.87025 9.0945 4.87875 7.87575 4.87875C6.654 4.87875 5.664 5.86875 5.664 7.089C5.664 8.30925 6.6525 9.30075 7.87575 9.30075ZM24 23.25V16.5563C24 13.2698 23.2905 10.7422 19.4497 10.7422C17.6047 10.7422 16.368 11.754 15.861 12.7133H15.81V11.046H12.1718V23.25H15.9623V17.2125C15.9623 15.621 16.266 14.079 18.2393 14.079C20.1848 14.079 20.2102 15.8993 20.2102 17.3153V23.25H24Z"
                  fill="#7DFFAF"
                  data-v-1a40eb22
                ></path>
              </svg>{" "}
              <span class="ml-4 block whitespace-nowrap" data-v-1a40eb22>
                @swek-interactive
              </span>
            </a>
          </div>
        </div>
      </div>
      <div
        class="w-full border-t border-darker pt-10 lg:pt-14 mt-10 lg:mt-14 -mb-12 lg:-mb-20"
        data-v-1a40eb22
      >
        {/* <div class="wrapper" data-v-1a40eb22>
          <div class="flex items-center space-x-10" data-v-1a40eb22>
            <a
              href="https://www.google.com/partners/agency?id=6557445892"
              target="_blank"
              data-v-1a40eb22
            >
              <img
                src="https://www.gstatic.com/partners/badge/images/2023/PartnerBadgeClickable.svg"
                alt="Google Partner logo"
                class="w-28 h-28"
                data-v-1a40eb22
              ></img>
            </a>{" "}
            <a
              href="https://manage.cookiebot.com/goto/signup?rid=55338"
              data-v-1a40eb22
            >
              <img
                src="./images/cookiebot.svg"
                alt="Cookiebot partner logo"
                class="w-auto h-14"
                data-v-1a40eb22
              ></img>
            </a>
          </div>
        </div> */}
      </div>
    </footer>
  );
}

export default Footer;
