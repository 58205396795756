import React, {useEffect} from 'react'
import FolioTechFour1 from '../../pageSections/TechSections/Inner-Folio-Tech-4/FolioTechFour1'
import FolioTechFour2 from '../../pageSections/TechSections/Inner-Folio-Tech-4/FolioTechFour2'
import FolioTechFour3 from '../../pageSections/TechSections/Inner-Folio-Tech-4/FolioTechFour3'
import FolioTechFour4 from '../../pageSections/TechSections/Inner-Folio-Tech-4/FolioTechFour4'
import FolioTechFour5 from '../../pageSections/TechSections/Inner-Folio-Tech-4/FolioTechFour5'
import FolioTechFour6 from '../../pageSections/TechSections/Inner-Folio-Tech-4/FolioTechFour6'
import FolioTechFour7 from '../../pageSections/TechSections/Inner-Folio-Tech-4/FolioTechFour7'
import FolioTechFour8 from '../../pageSections/TechSections/Inner-Folio-Tech-4/FolioTechFour8'
import FolioTechFour9 from '../../pageSections/TechSections/Inner-Folio-Tech-4/FolioTechFour9'
import FolioTechFour10 from '../../pageSections/TechSections/Inner-Folio-Tech-4/FolioTechFour10'
import ReactGA from 'react-ga4'

function TechFolio4() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <FolioTechFour1 />
          <div data-fetch-key="PageFetch:0">
            <FolioTechFour2 />
            <FolioTechFour3 />
            <FolioTechFour4 />
            <FolioTechFour5 />
            <FolioTechFour6 />
            <FolioTechFour7 />
            <FolioTechFour8 />
            <FolioTechFour9 />
            <FolioTechFour10 />
            
          </div>
        </div>
      </div>
    </main>
  )
}

export default TechFolio4
