import React from "react";

function TechSeo3() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Why SEO optimization?</h3>
              <p>
                Here are 6 strong reasons why SEO optimization should play a central role in your digital strategy:
              </p>
              <ol>
                <li className="mobile-small">
                  You are visible when searching on Google. Good SEO optimization means that your website ends up higher in the search results.
                </li>
                <li className="mobile-small">
                  The buying journey starts on Google. Today, a majority of consumers begin their buying journey with a Google search.
                </li>
                <li className="mobile-small">
                 Increased brand awareness. With the right SEO optimization, your visibility increases and the opportunity to become top of mind.
                </li>
                <li className="mobile-small">
                  High credibility. Ranking high in the organic search results increases the credibility of your brand.
                </li>
                <li className="mobile-small">
                  Measurable results. It's easy to see which keywords and phrases generate leads and conversion.
                </li>
                <li className="mobile-small">
                  High ROI. In terms of investment, SEO optimization is one of the efforts that gives the most return over time.
                </li>
              </ol>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="../images/Others/seo.png"
                alt="SEO optimering på luma.energy"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechSeo3;
