import React from "react";

function StudioVideo2() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723244425/SWEK/images/Aamaka/Reel2_q1lttj.mp4"
              autoPlay
              loop
              muted
            ></video>
          </figure>
        </div>
      </div>
    </section>
  );
}

export default StudioVideo2;
