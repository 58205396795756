import React from "react";

function InnerIllustration3() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243115/SWEK/images/illustrations/1_hceetw.gif"
            alt="MISTER YORK illustration"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243267/SWEK/images/illustrations/2_z512yw.gif"
            alt="KLIVET illustration"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243118/SWEK/images/illustrations/3_hrhzbh.gif"
            alt="ÖLAND illustration 3"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243118/SWEK/images/illustrations/4_ofdwsy.jpg"
            alt="KALMAR FF energijakten illustration"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <div class="pb-[100%] relative w-full">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723243110/SWEK/images/illustrations/Dots2_cc9wje.mp4"
              autoPlay
              loop
              muted
            ></video>
          </div>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <div class="pb-[100%] relative w-full">
            <video
              class="absolute inset-0 object-cover h-full w-full"
              controls
              src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723244791/SWEK/images/illustrations/RW_mmtu9o.mp4"
              autoPlay
              loop
              muted
            ></video>
          </div>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243116/SWEK/images/illustrations/5_ky2yap.gif"
            alt="MYQR illustration 1"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243116/SWEK/images/illustrations/6_tgb1i9.gif"
            alt="MYQR illustration 2"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243113/SWEK/images/illustrations/7_xzkogg.jpg"
            alt="ÖLAND illustration 1"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243178/SWEK/images/illustrations/8_mveeiq.gif"
            alt="ÖLAND illustration 2"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243122/SWEK/images/illustrations/9_ovodlo.jpg"
            alt="LUMA Energy illustration 1"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243119/SWEK/images/illustrations/10_anfs2e.jpg"
            alt="LUMA Energy illustration 2"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default InnerIllustration3;
