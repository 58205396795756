import React, { useEffect } from "react";
import "../styles/Home.css";
import MainHome from "../pageSections/HomeSections/MainHome";
import Portfolio from "../pageSections/HomeSections/Portfolio";
import Cta from "../pageSections/HomeSections/Cta";
import Choice from "../pageSections/HomeSections/Choice";
import Services from "../pageSections/HomeSections/Services";
import Stories from "../pageSections/HomeSections/Stories";
import More from "../pageSections/HomeSections/More";
import Form from "../UniSections/Form";
import Clients from "../pageSections/HomeSections/Clients";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <main class="flex-grow contain">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page force-padding">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <MainHome />
          <Portfolio />
          {/* <Cta /> */}
          {/* <Choice /> */}
          <Services />
          <Stories />
          {/* <More /> */}
          <Clients />
          <Form />
        </div>
      </div>
    </main>
  );
}

export default Home;
