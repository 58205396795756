import React from "react";
import { Outlet, Link } from "react-router-dom";

function SwekMessage2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Team
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12 custom-padding" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Four agencies in one</h2>
            <p className="mobile-small">
              Swek Interactive is a one-stop shop for companies and organizations
              that have a broad digital communication need. We are organized in
              teams with different skills:{" "}
              <Link to="/tech">
                <a href="/tech/">Tech</a>
              </Link>
              , <Link to="/branding"></Link>
              <a>Branding</a>,
              <Link to="/digital">
                <a>Digital</a>
              </Link>{" "}
              and <Link to="/studio"></Link>
              <a>Studio</a> but work very closely together. This makes us
              synchronized, fast and cost-effective.
            </p>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default SwekMessage2;
