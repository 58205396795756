import React from "react";

function DigitalContentHome() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243123/SWEK/images/Headers/digital/swek-content_miy71v.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">Swek Content</h1>
          </div>
        </div>

        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Content</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  In a digital world where content is king, we at Swek
                  Interactive are your trusted partner for content production
                  and content marketing.
                </span>
              </p>
              <p className="mobile-small">
                Content is a collective name for text, image, video, audio and
                other material used on web pages, social media and other digital
                channels while Content Marketing is the strategy that uses
                content to attract, engage and convert.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DigitalContentHome;
