import React from "react";

function InnerIllustration1() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <div class="lg:w-3/12">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Visualize and tell with illustration
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Illustration</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p className="mobile-small">
                <span class="ingress">
                  Bespoke illustrations are often that last little finishing touch when it comes to developing a unique brand.
                </span>{" "}
              </p>
              <p className="mobile-small">
                The possibilities are almost unlimited and so are the areas of use.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerIllustration1;
