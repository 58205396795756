import React from "react";
import { Outlet, Link } from "react-router-dom";

function GridFilm13() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              YouTube
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>More videos on YouTube</h2>
            <p className="mobile-small">
              Huh, want to see even more?! Then you surf to our{" "}
              <a
                target="_blank"
                rel="noopener"
                // href="https://www.youtube.com/c/wilsoncreative/videos"
              >
                YouTube-channel
              </a>{" "}
              bums!
            </p>
          </div>
        </div>
      </div>
      <Outlet />
    </section>
  );
}

export default GridFilm13;
