import React, {useEffect} from 'react'
import '../styles/Digital.css'
import DigitalHome from '../pageSections/DigitalSections/DigitalHome'
import DigitalHappyIntro from '../pageSections/DigitalSections/DigitalHappyIntro'
import DigitalInfoSlice1 from '../pageSections/DigitalSections/DigitalInfoSlice1'
import DigitalInfoSlice2 from '../pageSections/DigitalSections/DigitalInfoSlice2'
import DigitalServicesGrig from '../pageSections/DigitalSections/DigitalServicesGrig'
import DigitalMessage from '../pageSections/DigitalSections/DigitalMessage'
import DigitalBulliten from '../pageSections/DigitalSections/DigitalBulliten'
import DigitalMiniFolio from '../pageSections/DigitalSections/DigitalMiniFolio'
import Form from '../UniSections/Form'
// import { Outlet } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Digital() {

  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Digital</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <DigitalHome />
          <DigitalHappyIntro />
          <DigitalInfoSlice1 />
          <DigitalInfoSlice2 />
          <DigitalServicesGrig />
          <DigitalMessage />
          <DigitalBulliten />
          {/* <DigitalMiniFolio /> */}
          <Form />

          
        </div>
      </div>
    </main>  
  )
}

export default Digital
