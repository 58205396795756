import React from 'react'

function FolioTechFour5() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <div class="overflow-hidden">
                                        <div class="pb-[100%] relative w-full"><video src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/09e153e9-ede9-461b-853a-fa35c263258c_autopilot.mp4" autoplay playsinline loop muted class="absolute inset-0 object-cover h-full w-full"></video></div>
                                    </div>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <div class="overflow-hidden">
                                        <div class="pb-[100%] relative w-full"><video src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/b9e2a07c-3fe0-40fc-925b-8523d5341ef4_507_HAILEYHR_WEBBANIMATION_SAFE_001_OVP.mp4" autoplay playsinline loop muted class="absolute inset-0 object-cover h-full w-full"></video></div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioTechFour5
