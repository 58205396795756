import React from "react";

function DigitalHappyIntro() {
  return (
    <section class="slice bg-darkest" data-v-605c8685>
      <div class="wrapper" data-v-605c8685>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-605c8685
        >
          <div class="w-full lg:w-1/2" data-v-605c8685>
            <div class="green-smiley rich-text" data-v-605c8685>
              <h3>Digital Marketing - The Swek Way</h3>
              <ul>
                <li className="mobile-small">
                  Ongoing overall solution or on a campaign basis
                </li>
                <li className="mobile-small">
                  The work is carried out by trained digital specialists{" "}
                </li>
                <li className="mobile-small">
                  We make decisions based on data analysis
                </li>
                <li className="mobile-small">
                  Customized reporting with full transparency
                </li>
                <li className="mobile-small">
                  Accurate campaign management with continuous optimization
                </li>
                <li className="mobile-small">
                  We have been working with digital marketing since 2019
                </li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-605c8685>
            <div class="w-full pb-[70%] relative" data-v-605c8685>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243275/SWEK/images/Others/b-man_x5cdri.jpg"
                alt=""
                class="w-full h-full inset-0 object-cover absolute"
                data-v-605c8685
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DigitalHappyIntro;
