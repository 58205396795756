import React from 'react'

function FolioTechSix3() {
  return (
   <section class="text-slice py-20 lg:py-40 bg-white mydark-bg-3" data-v-2ac86e56>
                                <div class="wrapper lg:flex" data-v-2ac86e56>
                                    <div class="lg:w-3/12" data-v-2ac86e56>
                                        <div class="transform translate-y-10 opacity-0" data-v-2ac86e56><span class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight" data-v-2ac86e56>Intro</span></div>
                                    </div>
                                    <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
                                        <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56 >
                                            <h2>En vardagshjältes digitala närvaro</h2>
                                            <p>Guldfågeln är ett familjeföretag i tre generationer med bas på Öland. De är ledande i Sverige när det gäller svenskproducerad kyckling av högsta kvalitet. Guldfågeln grundades 1975 och har sen start hållit fast
                                                vid sina grundvärderingar om långsiktighet och etisk djuruppfödning. </p>
                                            <p>Wilsons huvudfokus ligger på den digitala närvaron i form av webbsida, sociala medier, content och SEM.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioTechSix3
