import React from "react";

function DaDigital2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Rubbed
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>Communication strategy</h3>
            <p className="mobile-small">
              When we make a communication strategy, we take a holistic view of the entire digital ecosystem: Website, e-commerce, intranet, newsletter, social media, advertising platforms - yes, the rub. Everything is summarized in a digital bible that is the basis for the entire digital presence.{" "}
            </p>
            <h4>A communication strategy may include:</h4>
            <ul>
              <li className="mobile-small">
                Which target groups do we have and what do they need to see, read, hear and feel?
              </li>
              <li className="mobile-small">
                How does the target group use digital channels and which channels are prio?

              </li>
              <li className="mobile-small">
                What kind of content do we need to produce for each channel?
              </li>
              <li className="mobile-small">
                Activity plan: Who does what and when?
              </li>
              <li  className="mobile-small">Measurable objectives: Which KPIs are interesting?</li>
              <li className="mobile-small">Drive! And be prepared to revise the communications strategy along the way.</li>
              <li className="mobile-small">
                What is the purpose of the digital presence? What do we want to achieve?
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DaDigital2;
