import React from "react";

function TechEcommerce1() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243129/SWEK/images/Headers/tech/swek-e-commerce_quh1xv.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Web agency for e-commerce.
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">E-Commerce</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  We build your online store to fit your ambitions and your
                  market precisely.
                </span>
              </p>
              <p className="mobile-small">
                Are you an experienced e-commerce entrepreneur looking for help
                to take your online store to the next level? Or perhaps a
                curious rookie dreaming of your first e-commerce venture?
                Regardless, we are a creative web agency for e-commerce that
                assists you with the right e-commerce platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechEcommerce1;
