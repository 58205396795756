import React from 'react'

function OlandFolio12() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/260dfca6-a266-48d6-aa70-119d0a865739_oland_1x1_colors.jpg?auto=compress,format" alt="Färgschema Öland" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio12
