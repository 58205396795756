import React from "react";

function GaDigital4() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Why Swek Interactive?
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>10 reasons to choose us for your Google Ads advertising</h3>
            <p className="mobile-small">
              Anyone can get started with Google Ads, but to achieve maximum effect, you should consider enlisting the help of specialists in the field.
            </p>
            <h4>1. Expert knowledge of Google</h4>
            <p className="mobile-small">
              Google's huge platform handles billions of searches daily and our experience and know-how ensures that your ads reach the right audience and take full advantage of Google's potential.
            </p>
            <h4>2. Customized target group strategies</h4>
            <p className="mobile-small">
              Our team is skilled at creating targeted campaigns that reach potential customers at different stages of their buying journeys. We optimize the use of both broad and specific keywords to effectively reach your target audience.
            </p>
            <h4>3. Focus on search intent</h4>
            <p className="mobile-small">
              We understand the value of reaching people with a specific intent. Our strategies are designed to target users who are actively searching for your products or services, increasing the chances of conversion.
            </p>
            <h4>4. Flexible campaign management</h4>
            <p className="mobile-small">
              We follow ongoing campaigns closely, which gives us the opportunity to make quick adjustments based on real-time data. We handle everything from campaign start to optimization to ensure maximum effectiveness.
            </p>
            <h4>5. Budget adjustment for each client</h4>
            <p className="mobile-small">
              We adapt the campaign to your budget. Regardless of the size of your investment, we ensure that you get maximum value for every penny spent on Google Ads.
            </p>
            <h4>6. Continuous reporting</h4>
            <p className="mobile-small">
              We keep you in the loop and ensure that you receive ongoing reports on how advertising on Google is performing. Our analysis also gives us insights that are important for continuous development and improvement.
            </p>
            <h4>7. Fast results </h4>
            <p className="mobile-small">
             While SEO is an important long-term strategy, we create immediate results with Google Ads. We use Ads to quickly increase your visibility and reach potential customers effectively.
            </p>
            <h4>8. Brand building </h4>
            <p className="mobile-small">
              We use Google Ads not only to drive traffic to your website, but also to create brand awareness. Through consistent exposure, we increase recognition and trust in your company.
            </p>
            <h4>9. Optimized landing pages
</h4>
            <p className="mobile-small">
              We make sure your landing pages are optimized for maximum conversion. A good landing page is crucial to successful Google Ads campaigns.
            </p>
            <h4>10. Maximized ROI</h4>
            <p className="mobile-small">
              We design individual strategies based on your unique business goals. Our goal is to maximize your return on investment, whether it's increasing traffic, building brand awareness or driving conversions.
            </p>
            <p className="mobile-small">
             We work wholeheartedly to ensure that your digital marketing yields results. Our expertise and tailored strategies ensure that your investment in Google Ads will be profitable and effective.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GaDigital4;
