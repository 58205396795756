import React from "react";

function SmDigital13() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Advertising on Snapchat</h3>
              <p className="mobile-small">
                Known for its focus on snapshots, Snapchat has a strong presence
                among younger users. The platform offers unique tools to create
                dynamic and engaging content. Use Snapchat's various features
                such as filters, Geofilters and Stories to create an interactive
                experience. A good tip for your advertising on Snapchat is
                offers that are exclusive to Snapchat users. Make sure your
                content is current and relevant. Since content disappears
                quickly, it's important to keep your followers engaged through
                regular updates.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/b81f947c-4a62-47de-9484-2b4a60fac3be_snap.png?auto=compress,format"
                alt="Annonsering på Snapchat"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital13;
