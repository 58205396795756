import React from "react";

function InnerDesign1() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <div class="lg:w-3/12">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Identity and Graphic Profile
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Design</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  The visual identity is a very tangible and important part of a brand.{" "}
                </span>
              </p>
              <p className="mobile-small">
                In the digital era, great demands are placed on both ease of use and flexibility as new, exciting digital channels pop up like mushrooms out of the ground most of the time. Swek Interactive speaks the digital design language fluently so you don't have to. Or we can teach you…
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerDesign1;
