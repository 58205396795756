import React from "react";

function DigitalStrategyHome() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243124/SWEK/images/Headers/digital/swek-digital_eg1kou.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Digital strategy / Communication strategy
            </h1>
          </div>
        </div>

        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Digital</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">strategy</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  A digital strategy is a prerequisite for success with your
                  digital communication and marketing in the long term.{" "}
                </span>
              </p>
              <p className="mobile-small">
                What is the purpose of digital marketing? Which target groups
                are interesting and in which channels can we find them? What
                kind of content do we need to create and what is the goal of all
                the efforts? All of this is summarized and serves as a map and
                compass in the pursuit of your digital success story.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DigitalStrategyHome;
