import React from "react";
import { Outlet, Link } from "react-router-dom";

function StudioMessage() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Film studio
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Film for digital and social channels</h2>
            <p className="mobile-small">
              We are a fast-paced production agency for all kinds of{" "}
              <Link to="/film">
                <a>film</a>
              </Link>
              : Advertising film, branding film, product film, explainer film,
              aftermovie, recruitment film, employer branding film,{" "}
              <Link to="/animation">
                <a>animated film</a>
              </Link>
              , , motion graphics and advertising film for social media such as
              Facebook, Instagram, TikTok, Snapchat and YouTube. And of course:
              Whether it's content for social media, portrait photos for a
              website or product photos for a webshop - Wilson Creative is your
              go-to{" "}
              <Link to="/photo">
                <a href="/studio/foto/">photographer</a>
              </Link>{" "}
              for all needs.
            </p>
            
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default StudioMessage;
