import React from 'react'

function FolioTechTwo3() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/2e8b4fcb-340a-4e74-8acf-759e3c52c73e_bravura_case_webb_16x9.jpg?auto=compress,format" alt="Bravuras webbsida design Wilson Creative" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTechTwo3
