import React from "react";

function CoDigital2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Strategic use of content
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>What is content marketing?</h3>
            <p className="mobile-small">
              It is not uncommon to confuse the terms Content and Content Marketing, and perhaps not the whole world. In short, Content is the content itself, such as films, texts, images, etc. while Content marketing is a strategic marketing method that involves creating and sharing valuable, relevant and consistent content to attract and retain a clearly defined target group and thus create profitability. So producing the right content, meeting the right target group, in the right channel at the right time. Are you curious about working more strategically in this way? Contact us today and we'll help you reach, engage and convert your target audience with effective content marketing.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoDigital2;
