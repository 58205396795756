import React from "react";

function TechWebdev7() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>Aamaka</h4>
              <p className="mobile-small">
                With its photo-realistic visualizations, Walk the Room helps
                real estate companies communicate the potential of their
                projects. Visit the website{" "}
                <a target="_blank" rel="noopener">
                  aamaka.com
                </a>
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243107/SWEK/images/Aamaka/1_aypwil.jpg"
                alt="Webbcase walktheroom.com | Webbutveckling"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechWebdev7;
