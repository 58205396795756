import React from "react";

function InnerBrand9() {
  return (
    <section class="slice relative" data-v-597e490d>
      <div class="wrapper" data-v-597e490d>
        <div class="rich-text mb-5 md:mb-[2.5rem]" data-v-597e490d>
          <div data-v-597e490d>
            <h3>Common questions linked to brand strategy</h3>
          </div>
        </div>
        <div class="flex flex-col" data-v-597e490d>
          <div class="faq-item border-b border-darker py-8" data-v-597e490d>
            <div
              class="flex justify-between items-center rich-text faq-question cursor-pointer hover:text-laser"
              data-v-597e490d
            >
              <div class="pointer-events-none" data-v-597e490d>
                <h3>
                  What is the difference between a company's vision and mission?
                </h3>
              </div>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-6 w-6 pointer-events-none chevron"
                data-v-597e490d
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                  data-v-597e490d
                ></path>
              </svg>
            </div>
            <div
              class="faq-answer h-0 overflow-hidden rich-text"
              data-v-597e490d
            >
              <div class="mt-5" data-v-597e490d>
                <div data-v-597e490d>
                  <p className="mobile-small">
                    <strong>
                      Vision
                      <br />
                    </strong>
                    A vision<strong> </strong>describes an ideal future for the
                    company. It serves as a source of inspiration and a guide
                    for what the organization aims to achieve in the long run.
                  </p>

                  <p className="mobile-small">
                    <em>
                      Example: Creating a better everyday life for the many
                      people (IKEA)
                    </em>
                  </p>
                  <p className="mobile-small">
                    <strong>
                      Mission
                      <br />
                    </strong>
                    A mission describes the company's current purpose, its basic
                    function and focus areas. It provides an overview of what
                    the company does, who it serves, and how it differs from the
                    competition.
                  </p>
                  <p className="mobile-small">
                    <em>
                      Example: To offer a wide range of form and
                      function-correct home furnishing products at such low
                      prices that as many people as possible can buy them.
                      (IKEA)
                    </em>
                  </p>
                  <p className="mobile-small">
                    <strong>
                      Summary
                      <br />
                    </strong>
                    While the vision is a future-oriented declaration that sets
                    a long-term direction, the mission is more focused on the
                    present and defines the company's fundamental purpose and
                    operational goals. Together, they form a powerful foundation
                    for brand development and strategic planning.
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div class="faq-item border-b border-darker py-8" data-v-597e490d>
            <div
              class="flex justify-between items-center rich-text faq-question cursor-pointer hover:text-laser"
              data-v-597e490d
            >
              <div class="pointer-events-none" data-v-597e490d>
                <h3>What is a brand promise?</h3>
              </div>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-6 w-6 pointer-events-none chevron"
                data-v-597e490d
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                  data-v-597e490d
                ></path>
              </svg>
            </div>
            <div
              class="faq-answer h-0 overflow-hidden rich-text"
              data-v-597e490d
            >
              <div class="mt-5" data-v-597e490d>
                <div data-v-597e490d>
                  <p className="mobile-small">
                    The brand promise summarizes the values, benefits and
                    experiences that the target group should be able to expect
                    from a company. A brand promise must be distinctive,
                    realistic and credible. Keep it short and simple.
                  </p>
                  <p className="mobile-small">
                    <em>
                      Example: Inspire moments of optimism and uplift (Coca
                      Cola)
                    </em>
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div class="faq-item border-b border-darker py-8" data-v-597e490d>
            <div
              class="flex justify-between items-center rich-text faq-question cursor-pointer hover:text-laser"
              data-v-597e490d
            >
              <div class="pointer-events-none" data-v-597e490d>
                <h3>What are a company's core values?</h3>
              </div>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-6 w-6 pointer-events-none chevron"
                data-v-597e490d
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                  data-v-597e490d
                ></path>
              </svg>
            </div>
            <div
              class="faq-answer h-0 overflow-hidden rich-text"
              data-v-597e490d
            >
              <div class="mt-5" data-v-597e490d>
                <div data-v-597e490d>
                  <p className="mobile-small">
                    By clearly defining (and living up to) their most
                    fundamental and timeless values, companies can create a
                    strong foundation for long-term success. The core values
                    ​​act as a guide for the day-to-day work at the company.
                  </p>
                  <p>
                    <em>
                      Example: Love, Freedom, Smart Trick (Swek Interactive)
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="faq-item border-b border-darker py-8" data-v-597e490d>
            <div
              class="flex justify-between items-center rich-text faq-question cursor-pointer hover:text-laser"
              data-v-597e490d
            >
              <div class="pointer-events-none" data-v-597e490d>
                <h3>What does positioning mean?</h3>
              </div>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="h-6 w-6 pointer-events-none chevron"
                data-v-597e490d
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                  data-v-597e490d
                ></path>
              </svg>
            </div>
            <div
              class="faq-answer h-0 overflow-hidden rich-text"
              data-v-597e490d
            >
              <div class="mt-5" data-v-597e490d>
                <div data-v-597e490d>
                  <p className="mobile-small">
                    Positioning in a brand context means strategically defining
                    how a brand should be perceived in the market in order to
                    differentiate itself from the competition. It is about
                    creating a unique place in the customer's mind by
                    emphasizing particular characteristics, values ​​or benefits
                    that are relevant to the target group. Effective positioning
                    aims to make the brand recognisable, desirable and to appear
                    as the best option in its category.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerBrand9;
