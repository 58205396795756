import React from 'react'

function FolioTechThree7() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/f3710bf1-590b-4a23-aab2-c6de145fe82b_myrstack_2023_case_16x9_webb3.jpg?auto=compress,format" alt="Myrstack webbdesign" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTechThree7
