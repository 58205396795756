import React from "react";

function SmDigital14() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Advertising on X</h3>
              <p className="mobile-small">
                X, formerly known as Twitter, is a platform that enables fast
                and direct communication. It's a great place for brands to
                engage in topical conversations and build relationships with
                their audience. Create short and punchy posts that engage your
                audience. Use images and videos to increase engagement. Join
                relevant conversations through hashtags and trending topics. Be
                active and responsive. Speed ​​is key on X, so stay up-to-date
                and engage in real-time conversations. Use the platform for
                customer service, direct communication and advertising.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/344ecdb4-7023-4fe3-938c-815f7f15608f_twitter.png?auto=compress,format"
                alt="Annonsering på X"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital14;
