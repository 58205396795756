import React from "react";

function GridPhoto11() {
  return (
    <section class="slice bg-darkest custom-padding" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Studio/Photo
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h4>Photographer for all needs</h4>
            <p className="mobile-small">
              Advertising photo, product photo, portrait photo and environmental
              photo for web, social media, customer magazines and most other
              uses. Yep, the studio gang solves all kinds of photography, even
              outside of Kalmar and Stockholm.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GridPhoto11;
