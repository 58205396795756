import React from "react";

function More() {
  return (
    <section class="slice bg-darkest" data-v-4faa0344>
      <div class="wrapper lg:flex" data-v-4faa0344>
        <div class="lg:w-3/12" data-v-4faa0344>
          <div class="transform translate-y-10 opacity-0" data-v-4faa0344>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-4faa0344
            >
              Why Swek Interactive?
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-4faa0344>
          <div class="black-bg green-smiley content rich-text" data-v-4faa0344>
            <h2>Therefore, we are the right Design agency for you</h2>
            <p></p>
            <h3>We are loving</h3>
            <p className="mobile-small">
              Every project we take on, regardless of size or scope, is done
              with 100% love and care. Our team consists of skilled designers,
              coders, digital experts, filmmakers and project managers, who work
              closely together to create solutions that not only meet, but
              exceed our clients' expectations.
            </p>
            <h3>We put the customer first</h3>
            <p className="mobile-small">
              At Swek Creative, we do not use ready-made templates or manuals.
              Instead, we strive to work based on each customer's unique needs
              and visions, and translate this into effective strategies.
            </p>
            <h3>We are curious and want to see results</h3>
            <p className="mobile-small">
              We keep up to date with the latest trends and technologies in the
              digital world. Our goal is to deliver solutions that not only look
              great, but also provide measurable results.
            </p>
            <h3>We are your friend </h3>
            <p className="mobile-small">
              We build long-term relationships with our customers and together
              we navigate the ever-changing digital landscape, hand in hand.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default More;
