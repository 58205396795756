import React, {useEffect} from 'react'
import DillFolio1 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio1'
import DillFolio2 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio2'
import DillFolio3 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio3'
import DillFolio4 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio4'
import DillFolio5 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio5'
import DillFolio6 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio6'
import DillFolio7 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio7'
import DillFolio8 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio8'
import DillFolio9 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio9'
import DillFolio10 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio10'
import DillFolio11 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio11'
import DillFolio12 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio12'
import DillFolio13 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio13'
import DillFolio14 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio14'
import DillFolio15 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio15'
import DillFolio16 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio16'
import DillFolio17 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio17'
import DillFolio18 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio18'
import DillFolio19 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio19'
import DillFolio20 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio20'
import DillFolio21 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio21'
import DillFolio22 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio22'
import DillFolio23 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio23'
import DillFolio24 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio24'
import DillFolio25 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio25'
import DillFolio26 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio26'
import DillFolio27 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio27'
import DillFolio28 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio28'
import DillFolio29 from '../../pageSections/BrandingSections/BrandFolio5/DillFolio29'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function DillFolio() {

  return (
    <main class="flex-grow contain">

      <Helmet>
        <meta charSet="utf-8" />
        <title>Aamaka - Swek</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <DillFolio1 />
          <div data-fetch-key="PageFetch:0">
           <DillFolio2 />
           <DillFolio3 />
           <DillFolio4 />
           <DillFolio5 />
           <DillFolio6 />
           <DillFolio7 />
           <DillFolio8 />
           <DillFolio9 />
           <DillFolio10 />
           <DillFolio11 />
           <DillFolio12 />
           <DillFolio13 />
           <DillFolio14 />
           <DillFolio15 />
           <DillFolio16 />
           <DillFolio17 />
           <DillFolio18 />
           <DillFolio19 />
           <DillFolio20 />
           <DillFolio21 />
           <DillFolio22 />
           {/* <DillFolio23 /> */}
           {/* <DillFolio24 /> */}
           {/* <DillFolio25 /> */}
           {/* <DillFolio26 /> */}
           {/* <DillFolio27 /> */}
           {/* <DillFolio28 /> */}
           {/* <DillFolio29 /> */}
          </div>
        </div>
      </div>
    </main>
  )
}

export default DillFolio
