import React from 'react'

function FolioTechFour6() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
                                <div class="wrapper lg:flex" data-v-2ac86e56>
                                    <div class="lg:w-3/12" data-v-2ac86e56>
                                        <div class="transform translate-y-10 opacity-0" data-v-2ac86e56><span class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight" data-v-2ac86e56>Webbsida</span></div>
                                    </div>
                                    <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
                                        <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56 >
                                            <h2>En blixtsnabb sida med hög smooth-faktor</h2>
                                            <p>Utifrån den grafiska identiteten har vi utvecklat en helt ny webbsida, kodad i <a href="/tech/webbutveckling/">JamStack</a> med Prismic som <a target="_blank" rel="noopener" href="https://prismic.io/blog/headless-cms">headless CMS</a>.
                                                Blixtsnabb och smooth, precis som deras tjänst. Målsättningen har varit att undvika krångliga navigationsvägar och gå rakt på sak. På så vis skapar vi precis den enkelhet som genomsyrar hela deras löfte
                                                till kund och användare. Upplev känslan på <a target="_blank" rel="noopener" href="https://www.haileyhr.com">haileyhr.com</a>.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioTechFour6
