import React from "react";
import { Outlet, Link } from "react-router-dom";

function TechServicesGrid() {
  return (
    <section class="slice">
      <div class="wrapper" >
        <div class="text-laser text-[10vw] md:text-[7vw] leading-[0.85] mb-10 lg:mb-20">
          <h2></h2>
        </div>
        <div class="rich-text green-smiley">
          <p></p>
        </div>
        <div class="over-flow-x-hidden swiper-container mt-10 lg:mt-20">
          <div class="swiper-wrapper relative h-full z-[1] flex transition-transform transform-gpu lg:flex lg:flex-wrap lg:-mx-8 lg:-mb-16 flexing">
            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/techuxdesign">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Read more about UX/UI design
                      </span>{" "}
                    </a>{" "}
                  </Link>
                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/c5c3ff9f-293e-4248-ad8f-dafb02d42447_ink-pen.svg"
                    alt="Ikon - UX/UI-design"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      UX/UI-design
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        Apps, websites, or complex digital experiences - we design everything that can be measured in pixels. And we do it with a large dose of user-friendliness and a loving attention to detail.
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                     Read more
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/techwebdev">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Read more about Web Development
                      </span>
                    </a>{" "}
                  </Link>
                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/f133d45b-48e7-482f-97f4-755e8d531206_layout-accordion-list.svg"
                    alt="Ikon - Webbutveckling"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      Web Development
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        We love code with all our hearts and craft fast, secure, and sustainable websites in
                        JAMstack.
                        And if you prefer a WordPress website, we can certainly handle that too...
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read More
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/techecommercepage">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Read more about E-commerce
                      </span>
                    </a>{" "}
                  </Link>
                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/1d89b3a0-b007-4a6a-8b1b-29c4c0c02bcc_shopping-cart-full.svg"
                    alt="Ikon - E-handel"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      E-commerce
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        Swek Interactive has extensive experience in e-commerce and helps you build up your business empire online. Start cautiously or go all out with a fully loaded webshop, including all the extras, right away.{" "}
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read More
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/techapp">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Read more about App Development
                      </span>
                    </a>
                  </Link>
                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/fada1380-ec4e-48d3-9072-f3d7d8926387_mobile.svg"
                    alt="Ikon - App-utveckling"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      App Development
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        When you have an idea that will revolutionize the digital generation, we can help you turn it into reality with an application.
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read More
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/techseo">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Read more about SEO
                      </span>
                    </a>{" "}
                  </Link>
                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/0a1d66f7-0247-4b01-b787-8569d13c7a33_flag-alt-2.svg"
                    alt="Ikon - SEO"
                    class="w-16 h-16"
                    data-v-a2570448
                  ></img>
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      SEO
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        Isn't it wonderful to have a lot of visitors to your website? Well-planned and lovingly executed search engine optimization (SEO) is the foundation for high rankings on Google and visibility on the web.{" "}
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read More
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/techhosting">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Read more about Hosting/Support
                      </span>
                    </a>{" "}
                  </Link>

                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/b1de2925-88f0-4eba-8ca4-0a7b37c749d7_harddrives.svg"
                    alt="Ikon - Hosting & support"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      Hosting/Support
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        Swek Interactive offers server environment management for websites. We install and handle all the technical details required to keep the site online and functioning perfectly.
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read More
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination lg:hidden"></div>
        <Outlet />
      </div>
    </section>
  );
}

export default TechServicesGrid;
