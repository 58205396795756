import React from 'react'

function OlandFolio18() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/6a656a56-7b7e-4829-ac03-accf84fabfc4_oland_16x9_pattern_1.png?auto=compress,format" alt="Öland mönster" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio18
