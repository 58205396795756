import React from "react";

function FolioDigital2() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56
            >
              Intro
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text black-convert" data-v-2ac86e56>
            <h2 className="black-convert">Kalmar FF i våra hjärtan</h2>
            <p className="black-convert">
              Vi har varit Kalmar FF:s fullservicebyrå sedan Kalmarunionen 1397
              (eller nja, nästan i alla fall). Fotboll är stort i Kalmar men med
              över 12 000 platser att fylla på Guldfågeln Arena så krävs en
              rejäl dos påhittighet, hyss och oprövade metoder och det passar
              oss på Wilson utmärkt. Ansvaret innefattar allt från{" "}
              <a href="/tech/webbutveckling/">hemsida</a>,{" "}
              <a href="/tech/e-handel/">e-handel</a> och{" "}
              <a href="/digital/sociala-medier/">sociala medier</a> till{" "}
              <a href="/branding/design/">grafisk profil</a>,{" "}
              <a href="/branding/konceptutveckling/">kampanjkoncept</a> och{" "}
              <a href="/studio/film/">filmproduktion</a>. Och behövs det
              merchandise så fixar vi det med såklart.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital2;
