import React from 'react'

function FolioTechFour4() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
                                <div class="wrapper lg:flex" data-v-2ac86e56>
                                    <div class="lg:w-3/12" data-v-2ac86e56>
                                        <div class="transform translate-y-10 opacity-0" data-v-2ac86e56><span class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight" data-v-2ac86e56>Animationer</span></div>
                                    </div>
                                    <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
                                        <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56 >
                                            <h2>Rörelser skapar stickiness</h2>
                                            <p>En viktig del av identiteten kretsar kring animationer. Oftast med fågeln i centrum. Animationerna lyfter fram Haileys USPar som bland annat är automation och säkerhet, se exempel nedan.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioTechFour4
