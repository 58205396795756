import React from "react";

function CoDigital8() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Content by Swek
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Content agency</h2>
            <p className="mobile-small">
              By choosing Swek Interactive as your content agency, you get a partner committed to your success. We believe in strong collaboration and adapt our services to fit your specific needs and goals. Whether you need help starting your content marketing strategy or improving your existing content, our team is ready to help you achieve your marketing goals
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoDigital8;
