import React from "react";

function TechWebdev1() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243132/SWEK/images/Headers/tech/swek-webdev_sklsa9.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Environmentally friendly web development
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Time</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">for</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">a new</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">website?</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  Good! We have been programming websites since 2019.{" "}
                </span>
              </p>
              <p className="mobile-small">
                Despite that, we are ridiculously curious about new
                possibilities, techniques and challenges in web development.
                Responsiveness, pixel-perfect web design and maximum performance
                are hygiene factors. We enjoy web projects of all kinds, but
                feel a little extra good when we get to produce sustainable
                websites with latest technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechWebdev1;
