import React from "react";

function FolioDigitalThree2() {
  return (
    <section
      class="text-slice py-20 lg:py-40 bg-white mydark-bg"
      data-v-2ac86e56
    >
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Intro
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2>Introducing Rock Boom</h2>
            <p className="mobile-small">
              an invigorating Mental & Physical Eneray Drink. specially
              crafted to enhance your focus. elevate consciousness, and
              heighten perception. Inspired by the indomitable Ugandan spirit and
              unwavering determination, our bold and radical label design
              boasts thick tonts,{" "}
              <a >
                symbolizing strength and resilience. 
              </a>{" "}
              The unique feature of Rock Boom lies in its ability to clear the mind and sharpen cognitive abilities, making it the perfect choice for those seeking an edge in their daily pursuits. To enhance legibility and make an impactful statement, we have ingeniously rotated the text on the package by 90˚, ensuring every detail is easily discernible.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigitalThree2;
