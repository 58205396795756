import React, {useEffect} from 'react'
import '../styles/Stories.css'
import StoriesHome from '../pageSections/StoriesSections/StoriesHome'
import StoriesMessage from '../pageSections/StoriesSections/StoriesMessage'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Stories() {

  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Stories</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <StoriesHome />  
            <StoriesMessage />      
        </div>
      </div>
    </main> 
  )
}

export default Stories
