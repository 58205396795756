import React from 'react'

function OlandFolio24() {
  return (
   <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/34b2ccd8-913e-48c6-82ca-5675bfd0cbd8_oland_16x9_flags.jpg?auto=compress,format" alt="Öland flaggor" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio24
