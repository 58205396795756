import React from 'react'

function OlandFolio22() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/f64d852e-b149-4b04-a4f0-15f04d6f73ab_oland_16x9_stationery_design2.jpg?auto=compress,format" alt="Öland stationery design" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio22
