import React from "react";

function TechWebdev8() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              The development process
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Web Development - <br/>The Swek Way</h2>
            <p>
              When <a href="/tech/uxui-design/">UX/UI-fasen</a>  is complete, we move on to development of the website.
            </p>
            <h3>From concept to launch</h3>
            <p className="mobile-small">
              At the beginning of the development process, we already have a clear plan for the work thanks to our careful feasibility study and UX process. Although the web development is the most time-consuming phase of the project, it is also an exciting period when design and functionality are emerging. The project's web developer works closely with the site's designer to ensure that the website's interface lives up to our high demands for a desirable user experience.
            </p>
            <h3>Development of the website
</h3>
            <p className="mobile-small">
              Our web developer starts working on the design, user interface and possible system integrations. During this phase, the developers ensure that the new design is responsive and works equally well regardless of browser and device.
            </p>
            <h3>Integrations </h3>
            <p className="mobile-small">
              Not infrequently, the website must be integrated into one or more external systems. This can mean connections to CRM systems, newsletter services, company systems, booking systems, membership registers and so on. These integrations are often necessary for the website to be relevant to the user.{" "}
            </p>
            <h3>Content, testing and training</h3>
            <p className="mobile-small">
              As the site begins to complete, it's time to add content. In some cases this means moving existing content to the new website, but sometimes completely new materials such as images, videos and SEO-friendly texts are required. This phase also includes extensive testing to confirm that all features work as intended. We also ensure that the website is responsive for mobile devices and tablets and works across different browsers. Finally, we offer training for every website we develop, so your team feels confident managing the content themselves.
            </p>
            <h3>Launch</h3>
            <p className="mobile-small">
              Finally, it's launch time! On launch day, we handle server installation and configuration, register the new web structure with search engines (via 301 redirects) and re-manage DNS settings. All content should be in place at this point and the page is tested by both you and us. After the launch, further tests are carried out to make sure that the new website works as it should.{" "}
            </p>
            <p className="mobile-small">
              Pssst! Of course we don't forget to celebrate our joint web project with a little bubbly!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechWebdev8;
