import React, {useEffect} from 'react'
import '../styles/Tech.css'
import TechHome from '../pageSections/TechSections/TechHome'
import TechPortifolio from '../pageSections/TechSections/TechPortifolio'
import TechInfoSlice from '../pageSections/TechSections/TechInfoSlice'
import TechServicesGrid from '../pageSections/TechSections/TechServicesGrid'
import TechMessageSlice from '../pageSections/TechSections/TechMessageSlice'
import TechMiniFolio from '../pageSections/TechSections/TechMiniFolio'
import TechBulliten from '../pageSections/TechSections/TechBulliten'
import TechMiniClients from '../pageSections/TechSections/TechMiniClients'
import Form from '../UniSections/Form'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'



function Tech() {
  
  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Tech</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
              <TechHome />
              {/* <TechPortifolio /> */}
              <TechInfoSlice />
              <TechServicesGrid />
              <TechMessageSlice />
              <TechMiniFolio />
              <TechBulliten />
              <TechMiniClients />
              <Form />
        </div>
      </div>
    </main>  
  )
}

export default Tech
