import React from "react";

function SmDigital2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Specialists in SoMe
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div
            class="black-bg green-smiley content rich-text"
            data-v-2ac86e56
          >
            <h2>Swek Interactive - your social media agency</h2>
            <p className="mobile-small">
              Our digital media specialists have been around for a long time and have seen many Social Media platforms both come and go. At the time of writing, we work daily with Facebook, Instagram, LinkedIn, TikTok, X, Snapchat and YouTube. We are curious and constantly stay at the forefront when it comes to new conditions and opportunities in the rapidly changing social media world.
            </p>
            <p className="mobile-small">
              Swek  Interactive is a loving and results-driven digital agency that helps you and your business reach out with brand-strengthening and sales-driving marketing in social media. Organic posts and advertising in social media strengthen the relationship with your customers and enable interactivity from the target audience. Your company quickly receives valuable feedback that can be used to optimize the strategy around messages, formats and channel selection, and if you are only prepared to listen and learn, social channels represent a fantastic opportunity.{" "}
            </p>
            <p className="mobile-small">
              To achieve the best effect, we start by developing a social media strategy based on your conditions and goals. The strategy is flexible and provides scope for both long-term and rapid changes. Swek Interactive's social media agency works agile and data-driven with the allocation of the advertising budget so that the resources are used in a profitable and sustainable way.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital2;
