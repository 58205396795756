import React from "react";

function InnerBrand7() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>The communication strategy is the way to the goal</h3>
              <p className="mobile-small">
                The communication strategy is at the heart of how your brand
                interacts with the world. An effective communication strategy
                defines not only what to say, but also how, when and to whom. It
                takes into account all aspects of your communication - from
                advertising to social media and direct communication. At Swek
                Interactive, we help you create a communications strategy that
                is deeply rooted in your brand identity and goals. We ensure
                that your communication not only reaches your target audience
                but also engages them in a meaningful way. By using the right
                channels and messages to reach the right people at the right
                time, we maximize the impact of your communications and help
                your brand flourish.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243133/SWEK/images/Uzawi/8_ybrvg5.jpg"
                alt="Swek Branding "
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerBrand7;
