import React, {useEffect} from "react";
import FolioDigital1 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital1";
import FolioDigital2 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital2";
import FolioDigital3 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital3";
import FolioDigital4 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital4";
import FolioDigital5 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital5";
import FolioDigital6 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital6";
import FolioDigital7 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital7";
import FolioDigital8 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital8";
import FolioDigital9 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital9";
import FolioDigital10 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital10";
import FolioDigital11 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital11";
import FolioDigital12 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital12";
import FolioDigital13 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital13";
import FolioDigital14 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital14";
import FolioDigital15 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital15";
import FolioDigital16 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital16";
import FolioDigital17 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital17";
import FolioDigital18 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital18";
import FolioDigital19 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital19";
import FolioDigital20 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital20";
import FolioDigital21 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital21";
import FolioDigital22 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital22";
import FolioDigital23 from "../../pageSections/DigitalSections/Inner-Folio-Digital/FolioDigital23";
import ReactGA from 'react-ga4'

function DigitalFolio() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <FolioDigital1 />
          <div data-fetch-key="PageFetch:0">
            
            <FolioDigital2 />
            <FolioDigital3 />
            <FolioDigital4 />
            <FolioDigital5 />
            <FolioDigital6 />
            <FolioDigital7 />
            <FolioDigital8 />
            <FolioDigital9 />
            <FolioDigital10 />
            <FolioDigital11 />
            <FolioDigital12 />
            <FolioDigital13 />
            <FolioDigital14 />
            <FolioDigital15 />
            <FolioDigital16 />
            <FolioDigital17 />
            <FolioDigital18 />
            <FolioDigital19 />
            <FolioDigital20 />
            <FolioDigital21 />
            <FolioDigital22 />
            <FolioDigital23 />
          </div>
        </div>
      </div>
    </main>
  );
}

export default DigitalFolio;
