import React from 'react'

function FolioTechThree3() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/c04ed3e4-4e26-4406-9803-a3edff5df07e_myrstack_2023_case_16x9_iphone.jpg?auto=compress,format" alt="Myrstacks webbsida" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTechThree3
