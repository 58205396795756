import React from 'react'

function FolioDigitalFour8() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
                                <div class="wrapper lg:flex" data-v-2ac86e56>
                                    <div class="lg:w-3/12" data-v-2ac86e56>
                                        <div class="transform translate-y-10 opacity-0" data-v-2ac86e56><span class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight" data-v-2ac86e56>Guldfageln.se</span></div>
                                    </div>
                                    <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
                                        <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
                                            <h2>Recept på en bra webbsida</h2>
                                            <p>Vad ska vi äta till middag ikväll? Svaret på den frågan finns med största sannolikhet på <a target="_blank" rel="noopener" href="https://www.guldfageln.se">guldfageln.se</a>. Här snackar vi verkligen om en räddare
                                                i nöden för alla som vill laga nyttig, snabb och enkel mat. Receptbanken fylls på hela tiden och vi har såklart även tänkt på att de allra flesta använder telefonen när man trollar i köket. </p>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioDigitalFour8
