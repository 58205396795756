import React from 'react'

function FolioTechFour3() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <div class="overflow-hidden">
                                        <div class="pb-[100%] relative w-full"><video src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/5f55c87e-6e20-403d-99fa-5d4514e3384a_1920x1080+LOGO+ANIM.mp4" autoplay playsinline loop muted class="absolute inset-0 object-cover h-full w-full"></video></div>
                                    </div>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/0f08ab28-084d-4d9a-abdb-f8ea3f85be0e_haileyhr_illustrations_01.jpeg?auto=compress,format" alt="" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTechFour3
