import React from 'react'

function FolioDigitalFour7() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/922d44ac-c141-4c09-9866-30fb50baf9a3_GF_Grill_1080x1080.jpeg?auto=compress,format" alt="Guldfågeln Grillfest Sociala medier" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/92e327ba-dd83-46cd-830a-f4b47c72c16a_GF_2023_annons+2.png?auto=compress,format" alt="Guldfågeln majskyckling" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/e9af7e64-8d7e-4c64-bbd3-c6eac52c0a0b_guldfageln_2021_grill_banner_1x1_2.jpg?auto=compress,format" alt="Guldfågeln sommarsallad" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/307115a9-e72a-4c76-931b-1e1e70fa1918_asian_wok_1.jpg?auto=compress,format" alt="Guldfågeln Asiatisk wok" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioDigitalFour7
