import React from "react";

function TechHosting2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Sustainability.
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Green server</h2>
            <p className="mobile-small">
              We use dedicated servers located at GleSYS in Falkenberg. The choice of GleSYS is made even easier as we share their perspective on sustainability and the environment. An example of this is the surplus energy from the server hall being converted into district heating, contributing to lower carbon dioxide emissions.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechHosting2;
