import React from "react";

function DillFolio9() {
  return (
    <section
      class="text-slice py-20 lg:py-40 bg-white aamaka"
      data-v-2ac86e56
    >
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Functional
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <p className="mobile-small">
              Functional Benefit:
Exclusive and personalised real estate solutions that cater to the unique desires and needs of high-net-worth individuals, travellers, investors, and corporations.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DillFolio9;
