import React from "react";

function TechAppdev2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Web app
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Scalable and cost-effective web app.</h2>
            <p className="mobile-small">
              Swek's main focus is on web apps, which are essentially responsive web pages accessed through the phone's web browser. This means there's no need to download the app, and it works just as well on tablets and computers as it does on phones. A web app is scalable, relatively quick to build, and therefore cost-effective compared to native apps for iOS and Android. Additionally, there is the option to publish a web app on the App Store and Google Play Store to increase accessibility and visibility.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechAppdev2;
