import React from "react";

function StudioFood() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
          <video
            class="absolute inset-0 object-cover h-full w-full"
            controls
            src="https://res.cloudinary.com/dxatqyrxn/video/upload/v1723243500/SWEK/images/Others/Bottle-R_svbrph.mp4"
            autoPlay
            loop
            muted
          ></video>
        </figure>
      </div>
    </section>
  );
}

export default StudioFood;
