import React, {useEffect} from 'react'
import '../styles/Swek.css'
import SwekHome from '../pageSections/SwekSections/SwekHome'
import SwekImage from '../pageSections/SwekSections/SwekImage'
import SwekMessage1 from '../pageSections/SwekSections/SwekMessage1'
import SwekMessage2 from '../pageSections/SwekSections/SwekMessage2'
import SwekImage2 from '../pageSections/SwekSections/SwekImage2'
import SwekMessage3 from '../pageSections/SwekSections/SwekMessage3'
import SwekMessage4 from '../pageSections/SwekSections/SwekMessage4'
import SwekMessage5 from '../pageSections/SwekSections/SwekMessage5'
import SwekMessage6 from '../pageSections/SwekSections/SwekMessage6'
import SwekMessage7 from '../pageSections/SwekSections/SwekMessage7'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Swek() {

  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Swek</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <SwekHome />       
            <SwekImage /> 
            <SwekMessage1 />      
            <SwekMessage2 /> 
            <SwekImage2 />      
            <SwekMessage3 />      
            <SwekMessage4 />      
            <SwekMessage5 />      
            <SwekMessage6 />      
            <SwekMessage7 />      
        </div>
      </div>
    </main> 
  )
}

export default Swek
