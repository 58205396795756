import React from "react";

function SmDigital8() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Advertising on Facebook
</h3>
              <p className="mobile-small">
                Facebook continues to be a giant in social media, where businesses can reach a wide and diverse audience. It's a platform where both organic and paid reach can deliver amazing results. To be successful with your advertising on Facebook, you should create a mix of content that includes engaging text posts, high-quality images, video and live broadcasts. It is also important to take advantage of Facebook's various tools such as groups, events and Facebook Stories. Build a community around your brand by regularly interacting with your audience. Use Facebook's analytical tools to better understand your target audience and adjust your strategies accordingly.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/8c4c8e38-15c6-450c-951d-1ea810b787a2_fb.png?auto=compress,format"
                alt="Annonsering på Facebook"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital8;
