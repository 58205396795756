import React from "react";

function FolioDigitalTwo2() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white  mydark-bg" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56
            >
              Intro
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div
            class="white-bg dark-smiley content rich-text  black-convert"
            data-v-2ac86e56
          >
            <h2>Energirepubliken - energi härifrån, inte därifrån</h2>
            <p>
              Som Kalmarregionens ledande energiföretag har Kalmar Energi en
              stark lokal närvaro. De levererar el, fjärrvärme, fiber och
              energitjänster till över 50 000 kunder, och lyckas samtidigt vara
              nära och engagerade. Miljö och människor ligger företaget varmt om
              hjärtat, därför erbjuder Kalmar Energi endast 100% förnybar
              energi. 
            </p>
            <p>
              Vi har varit Kalmar Energis fullservicebyrå sedan 2014 och står
              för såväl strategiskt arbete som analys, kampanjer, webb och
              grafisk produktion. 2015 utsågs Kalmar Energi till årets
              marknadsförare i Kalmar Län.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigitalTwo2;
