import React from "react";
import { Outlet, Link } from "react-router-dom";

function Services() {
  return (
    <section class="slice" data-v-d8f50b44>
      <div class="wrapper flex flex-col" data-v-d8f50b44>
        <div
          class="w-full py-12 border-t border-darker first-of-type:border-none first:pt-0 last:pb-0"
          data-v-d8f50b44
        >
          <div class="flex flex-wrap lg:flex-nowrap group" data-v-d8f50b44>
            <div class="w-full lg:w-1/4 mb-4 lg:mb-0" data-v-d8f50b44>
              <Link to="/tech">
                <a
                  class="text-base lg:text-lg block hover:text-laser hover:underline md:mt-5"
                  data-v-d8f50b44
                >
                  01.Tech
                </a>
              </Link>
            </div>
            <div class="w-full lg:w-3/4" data-v-d8f50b44>
              <span
                class="text-2xl md:text-3xl lg:text-5xl block"
                data-v-d8f50b44
              >
                <div class="leading-tight links" data-v-d8f50b44>
                  <p>
                    <Link to="/techuxdesign">
                      {" "}
                      <a>UX & UI</a> /{" "}
                    </Link>
                    <Link to="/techwebdev">
                      {" "}
                      <a>Web Development</a> /{" "}
                    </Link>
                    <Link to="/techecommercepage">
                      <a>E-commerce</a> /{" "}
                    </Link>
                    <Link to="/techapp">
                      {" "}
                      <a>App</a> /{" "}
                    </Link>
                    <Link to="/techseo">
                      {" "}
                      <a>SEO</a>{" "}
                    </Link>
                    <Link to="/techhosting">
                      / <a>Hosting</a>
                    </Link>
                  </p>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div
          class="w-full py-12 border-t border-darker first-of-type:border-none first:pt-0 last:pb-0"
          data-v-d8f50b44
        >
          <div class="flex flex-wrap lg:flex-nowrap group" data-v-d8f50b44>
            <div class="w-full lg:w-1/4 mb-4 lg:mb-0" data-v-d8f50b44>
              <Link to="/digital">
                <a
                  class="text-base lg:text-lg block hover:text-laser hover:underline md:mt-5"
                  data-v-d8f50b44
                >
                  02.Digital
                </a>
              </Link>
            </div>
            <div class="w-full lg:w-3/4" data-v-d8f50b44>
              <span
                class="text-2xl md:text-3xl lg:text-5xl block"
                data-v-d8f50b44
              >
                <div class="leading-tight links" data-v-d8f50b44>
                  <p>
                    <Link to="/digitaldataanalysis">
                      {" "}
                      <a>Analysis</a> /{" "}
                    </Link>
                    <Link to="/digitalstrategy">
                      {" "}
                      <a>Digital strategy</a> /{" "}
                    </Link>
                    <Link to="/digitalsocialmedia">
                      {" "}
                      <a>Social media</a> /{" "}
                    </Link>
                    <Link to="/googleads">
                      {" "}
                      <a>Google Ads</a> /{" "}
                    </Link>
                    <Link to="/content">
                      {" "}
                      <a>Content</a>{" "}
                    </Link>
                  </p>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div
          class="w-full py-12 border-t border-darker first-of-type:border-none first:pt-0 last:pb-0"
          data-v-d8f50b44
        >
          <div class="flex flex-wrap lg:flex-nowrap group" data-v-d8f50b44>
            <div class="w-full lg:w-1/4 mb-4 lg:mb-0" data-v-d8f50b44>
              <Link to="/branding">
                <a
                  class="text-base lg:text-lg block hover:text-laser hover:underline md:mt-5"
                  data-v-d8f50b44
                >
                  03.Branding
                </a>
              </Link>
            </div>
            <div class="w-full lg:w-3/4" data-v-d8f50b44>
              <span
                class="text-2xl md:text-3xl lg:text-5xl block"
                data-v-d8f50b44
              >
                <div class="leading-tight links" data-v-d8f50b44>
                  <p>
                    <Link to="/brandinginner">
                      {" "}
                      <a>Brand</a> /{" "}
                    </Link>
                    <Link to="/designinner">
                      {" "}
                      <a>Design</a> /{" "}
                    </Link>
                    <Link to="/connectinner">
                      {" "}
                      <a>Concept</a> /{" "}
                    </Link>
                    <Link to="/illustrationinner">
                      {" "}
                      <a>Illustration</a> /{" "}
                    </Link>
                    <Link to="/copyinner">
                      {" "}
                      <a>Copy</a>
                    </Link>
                  </p>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div
          class="w-full py-12 border-t border-darker first-of-type:border-none first:pt-0 last:pb-0"
          data-v-d8f50b44
        >
          <div class="flex flex-wrap lg:flex-nowrap group" data-v-d8f50b44>
            <div class="w-full lg:w-1/4 mb-4 lg:mb-0" data-v-d8f50b44>
              <Link to="/studio">
                <a
                  class="text-base lg:text-lg block hover:text-laser hover:underline md:mt-5"
                  data-v-d8f50b44
                >
                  04.Studio
                </a>
              </Link>
            </div>
            <div class="w-full lg:w-3/4" data-v-d8f50b44>
              <span
                class="text-2xl md:text-3xl lg:text-5xl block"
                data-v-d8f50b44
              >
                <div class="leading-tight links" data-v-d8f50b44>
                  <p>
                    <Link to="/film">
                      {" "}
                      <a>Film</a> /{" "}
                    </Link>
                    <Link to="/animation">
                      {" "}
                      <a>Animation</a> /{" "}
                    </Link>
                    <Link to="/photo">
                      {" "}
                      <a>Photography</a>
                    </Link>
                  </p>
                </div>
              </span>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default Services;
