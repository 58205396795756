import React, {useEffect} from 'react'
import GridFilm1 from '../../pageSections/StudioSections/InnerFilm/GridFilm1'
import GridFilm2 from '../../pageSections/StudioSections/InnerFilm/GridFilm2'
import GridFilm3 from '../../pageSections/StudioSections/InnerFilm/GridFilm3'
import GridFilm4 from '../../pageSections/StudioSections/InnerFilm/GridFilm4'
import GridFilm5 from '../../pageSections/StudioSections/InnerFilm/GridFilm5'
import GridFilm6 from '../../pageSections/StudioSections/InnerFilm/GridFilm6'
import GridFilm7 from '../../pageSections/StudioSections/InnerFilm/GridFilm7'
import GridFilm8 from '../../pageSections/StudioSections/InnerFilm/GridFilm8'
import GridFilm9 from '../../pageSections/StudioSections/InnerFilm/GridFilm9'
import GridFilm10 from '../../pageSections/StudioSections/InnerFilm/GridFilm10'
import GridFilm11 from '../../pageSections/StudioSections/InnerFilm/GridFilm11'
import GridFilm12 from '../../pageSections/StudioSections/InnerFilm/GridFilm12'
import GridFilm13 from '../../pageSections/StudioSections/InnerFilm/GridFilm13'
import GridFilm14 from '../../pageSections/StudioSections/InnerFilm/GridFilm14'
import GridFilm15 from '../../pageSections/StudioSections/InnerFilm/GridFilm15'
import GridFilm16 from '../../pageSections/StudioSections/InnerFilm/GridFilm16'
import GridFilm17 from '../../pageSections/StudioSections/InnerFilm/GridFilm17'
import GridFilm18 from '../../pageSections/StudioSections/InnerFilm/GridFilm18'
import GridFilm19 from '../../pageSections/StudioSections/InnerFilm/GridFilm19'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function Film() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Film</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
        <GridFilm1 />
        <GridFilm2 />
        <GridFilm3 />
        <GridFilm4 />
        <GridFilm5 />
        <GridFilm6 />
        <GridFilm7 />
        <GridFilm8 />
        <GridFilm9 />
        <GridFilm10 />
        <GridFilm11 />
        <GridFilm12 />
        <GridFilm13 />
        <GridFilm14 />
        <GridFilm15 />
        <GridFilm16 />
        <GridFilm17 />
        <GridFilm18 />
        <GridFilm19 />
        </div>
      </div>
    </main> 
  )
}

export default Film
