import React from "react";

function TechHosting5() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Security and support
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Service agreement and web support</h2>
            <p  className="mobile-small">
              A fast page speed is positive for the user experience and also leads to better rankings on Google. We ensure that everything functions as it should and optimize the performance to maintain the site at a green level in the most common measurement tools. For WordPress sites, we update and install current plugins, and test the functionality after these updates. Minor updates are performed quarterly, while larger or critical security updates are done continuously. In conjunction with optimization, we compile a simple report on what we have done and how the site's performance has developed.{" "}
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechHosting5;
