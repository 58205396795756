import React from 'react'

function OlandFolio10() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/63d8a099-a405-4012-99c8-6bd822830955_oland_16x9_jordhamn.jpg?auto=compress,format" alt="Ölandslogo över strand och solnedgång" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio10
