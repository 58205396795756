import React from "react";

function TechUX3() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h2>The UX process</h2>
              <p className="mobile-small">
                Sweks's UX design can, somewhat be simplified, divided into five
                steps:{" "}
              </p>
              <ol>
                <li>Product definition </li>
                <li>Research </li>
                <li>Analysis</li>
                <li>UI design </li>
                <li>Testing</li>
              </ol>
              <p></p>
              <p className="mobile-small">
                Below we explain the different parts in a little more detail.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243295/SWEK/images/Others/ux_iludxz.png"
                alt="Myrstack UX Design i mobilvy"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechUX3;
