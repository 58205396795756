import React from "react";
import { Outlet, Link } from "react-router-dom";

function StudioServiceGrid() {
  return (
    <section class="slice">
      <div class="wrapper">
        <div class="text-laser text-[10vw] md:text-[7vw] leading-[0.85] mb-10 lg:mb-20"></div>
        <div class="rich-text green-smiley"></div>
        <div class="over-flow-x-hidden swiper-container mt-10 lg:mt-20">
          <div class="swiper-wrapper relative h-full z-[1] flex transition-transform transform-gpu lg:flex lg:flex-wrap lg:-mx-8 lg:-mb-16 flexing">
            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/film">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Läs mer om Film
                      </span>
                    </a>{" "}
                  </Link>
                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/eca31b97-f7f2-4a25-b557-3dda33cc8781_video-clapper.svg"
                    alt="Ikon - filmklappa"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      Film
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        We solve most things in-house; from solid film
                        productions suited for big screens to business-driven
                        content tailored for social media on the phone.{" "}
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read more
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/animation">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Läs mer om Animerad film
                      </span>
                    </a>{" "}
                  </Link>
                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/7fcb6f12-34ec-4a7b-8c5a-d52332915f8b_Animerad+film+ikon.svg"
                    alt="Ikon - animering"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      Animated videos
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        We mainly produce 2D, 3D and traditionally animated
                        films for all imaginable purposes. We have extra fun
                        when our own illustrator takes care of the shape.
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read more
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide flex-shrink-0 flex-grow-0 flex flex-col w-full lg:w-1/3 lg:px-8 relative transform translate-y-20 opacity-0 lg:mb-16">
              <div class="flex-1" data-v-a2570448>
                <div
                  class="p-8 lg:p-10 xl:p-16 bg-darkest-overlay h-full flex-1 group relative grid-margin"
                  data-v-a2570448
                >
                  <Link to="/photo">
                    <a
                      aria-label="Läs mer"
                      class="absolute block inset-0 w-full h-full cursor-pointer z-50"
                      data-v-a2570448
                    >
                      <span class="sr-only" data-v-a2570448>
                        Läs mer om Foto
                      </span>
                    </a>{" "}
                  </Link>
                  <img
                    src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/4ec9d82f-f9c4-4ca2-b767-6827f949a23d_gallery.svg"
                    alt="Ikon - fotofil"
                    class="w-16 h-16"
                    data-v-a2570448
                  />
                  <div class="mt-32" data-v-a2570448>
                    <h3
                      class="text-3xl 2xl:text-[2.5rem] mb-8 leading-none transform duration-200 ease-in group-hover:translate-y-2"
                      data-v-a2570448
                    >
                      Photo
                    </h3>
                    <div
                      class="rich-text green-smiley lg:text-lg lg:leading-relaxed text-white"
                      data-v-a2570448
                    >
                      <p className="mobile-small">
                        Photography in the studio and out in the field. We do
                        product photos, environmental photos, portraits and
                        everything else that is placed in front of the camera
                        lens.
                      </p>
                    </div>
                  </div>
                  <div class="flex items-center mt-10" data-v-a2570448>
                    <span
                      class="text-base lg:text-lg block text-white"
                      data-v-a2570448
                    >
                      Read more
                    </span>{" "}
                    <svg
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ml-3 block text-white transform transition-all duration-200 group-hover:translate-x-1 group-hover:text-neon"
                      data-v-a2570448
                    >
                      <path
                        d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-a2570448
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination lg:hidden"></div>
        <Outlet />
      </div>
    </section>
  );
}

export default StudioServiceGrid;
