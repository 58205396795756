import React, { useState } from "react";
import {Outlet, Link} from 'react-router-dom'
import {firestore} from '../firebase'

function Form() {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, company, email, phone, message);

    firestore
      .collection("messages")
      .add({
        name,
        company,
        email,
        phone,
        message,
        
      })
      .then(() => {
        setName("");
        setCompany("");
        setEmail("");
        setPhone("");
        setMessage("");
      })
      .catch((error) => console.error("Error submitting data: ", error));
  };

  

  return (
    <section class="slice" onSubmit={handleSubmit}>
      <div class="wrapper">
        
        {/* Title */}
        <div class="rich-text max-w-4xl mb-7 md:mb-10">
          <h2>Get in touch!</h2>
          <p className="mobile-small">
            What can we help you with? Drop us a few <br/>
            lines and we'll get back to you pronto.
          </p>
        </div>

        {/* onClick  function to switch between tabs */}
        <div
          class="flex text-base md:text-xl lg:text-2xl justify-between md:justify-start md:space-x-8 mb-8 md:mb-14"
          data-v-9bb94bc4
        >
          <button //contact button
            name="contact"
            class="w-24 md:w-auto text-left leading-tight tab active-tab"
            data-v-9bb94bc4
          >
            Contact
          </button>{" "}

          <button //Job button
            name="job"
            class="w-24 md:w-auto text-left leading-tight tab inactive-tab"
            data-v-9bb94bc4

          >
            {/* Job & Practice */}
          </button>

        </div>

        {/* Switch  the menu on and off */}
        <div class="form-slice z-[1] relative"> 
          <div Style="display:none">  
            <form
              enctype="multipart/form-data"
              id="conditional-form"
              method="POST"
              name="Job"
            >
              <input type="hidden" name="form-name" value="Job"></input>{" "}
              <input
                name="name"
                placeholder="Name*"
                onfocus='this.placeholder=""'
                onblur='this.placeholder="Name"'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}

              ></input>{" "}

              <input
                name="email"
                type="email"
                placeholder="Email*"
                onfocus='this.placeholder=""'
                onblur='this.placeholder="Email*"'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}

              ></input>{" "}

              <input
                name="phone"
                placeholder="Phone"
                onfocus='this.placeholder=""'
                onblur='this.placeholder="Phone"'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}

              ></input>{" "}

              <select name="type" required class="placeholder">
                <option value="" selected disabled>
                  Whats on your mind?*
                </option>{" "}
                <option value="Jobb">Job</option>{" "}
                <option value="Praktik">Practice</option>
              </select>{" "}
              <textarea
                name="message"
                placeholder="Tell me a little about yourself and why you are curious about Swek Interactive.*"
                onfocus='this.placeholder=""'
                onblur='this.placeholder="Berätta lite om dig själv och varför du är nyfiken på Wilson Creative*"'
                rows="5"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}

              ></textarea>{" "}


              <label for="file-upload" class="file-upload placeholder">
                <span>Upload CV/Application</span>{" "}
                <input
                  id="file-upload"
                  name="file"
                  type="file"
                  class="hidden"
                ></input>
              </label>
              <p className="mobile-small">
                Now your personal data will go immediately to Swek interactive!
                We promise to take good care of them. Here you can {" "}
                 <a >read more</a> about how it is
                done.
              </p>
              <p class="hidden">
                <input name="nonsense-field"></input>
              </p>
              <div class="md:col-span-2">
                <button type="submit" class="button">
                  Submit
                  <svg
                    width="23"
                    height="24"
                    viewBox="0 0 23 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="ml-3 block text-laser"
                  >
                    <path
                      d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>
              </div>
            </form>
            <form class="!hidden" method="POST" name="Trainee">
              <input type="hidden" name="form-name" value="Trainee"></input>{" "}
              <input name="name"></input> <input name="email"></input>{" "}
              <input name="phone"></input> <select name="type"></select>{" "}
              <textarea name="message"></textarea> <input name="file"></input>
            </form>
          </div>

          {/* contact form */}
          <form method="POST" name="Contact">
            <input type="hidden" name="form-name" value="Contact"></input>{" "}
            <input
              name="name"
              placeholder="Name"
              onfocus='this.placeholder=""'
              onblur='this.placeholder="Namn*"'
              required
              value={name}
              onChange={(e) => setName(e.target.value)}

            ></input>{" "}
            <input
              name="company"
              placeholder="Company"
              onfocus='this.placeholder=""'
              onblur='this.placeholder="Company"'
              value={company}
              onChange={(e) => setCompany(e.target.value)}

            ></input>{" "}
            <input
              name="phone"
              placeholder="Phone"
              onfocus='this.placeholder=""'
              onblur='this.placeholder="Phone"'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}

            ></input>{" "}
            <input
              name="email"
              type="email"
              placeholder="Email*"
              onfocus='this.placeholder=""'
              onblur='this.placeholder="Email*"'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}

            ></input>{" "}

            <textarea
              name="message"
              placeholder="What's on your mind?*"
              onfocus='this.placeholder=""'
              onblur='this.placeholder= " Whats on your mind?* "'
              rows="5"
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}

            ></textarea>
            <p className="mobile-small">
              Now your personal data will go immediately to Swek interactive! We
              promise to take good care of them. Here you can
              <a > read more</a> about how it is
              done.
            </p>
            <p class="hidden">
              <input name="nonsense-field"></input>
            </p>
            <div class="md:col-span-2">
              <button type="submit" class="button">
                Submit
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-3 block text-laser"
                >
                  <path
                    d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
          </form>



        </div>
        <Outlet/>
      </div>
    </section>
  );
}

export default Form;
