import React from "react";
import {Outlet, Link} from 'react-router-dom'

function Stories() {
  return (
    <section
      data-fetch-key="data-v-f0580954:0"
      class="my-20 lg:my-32"
      data-v-f0580954
    >
      <div class="border-b border-darker group block pb-10" data-v-f0580954>
        <div class="wrapper" data-v-f0580954>
          <div class="lg:flex" data-v-f0580954>
            <div class="lg:w-3/12" data-v-f0580954>
              <span
                class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]"
                data-v-f0580954
              >
                <span class="inline-block transform text-white" data-v-f0580954>
                  Swek Interactive
                </span>
              </span>
            </div>
            <Link to="/stories">
              <a class="mt-4 md:mt-0 lg:w-9/12" data-v-f0580954>
                <div
                  class="story group-hover:text-laser transition-colors duration-200"
                  data-v-f0580954
                >
                  <h1>Blueprint for Success</h1>
                </div>
                <div
                  class="flex flex-col md:flex-row -mx-8 mt-4 md:mt-12 lg:mt-16"
                  data-v-f0580954
                >
                  <div class="w-full md:w-1/2 px-8" data-v-f0580954>
                    <div class="w-full pb-[66%] relative" data-v-f0580954>
                      <img
                        src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243654/SWEK/images/Others/Grains/6_ezqkdk.jpg"
                        alt="Onlinemarknadsföring under lågkonjunktur. Flygande motorcykel."
                        class="w-full h-full inset-0 object-cover absolute"
                        data-v-f0580954
                      ></img>
                    </div>
                  </div>
                  <div
                    class="flex flex-col justify-between w-full md:w-1/2 px-8 mt-4 md:mt-0"
                    data-v-f0580954
                  >
                    <div
                      class="rich-text text-white ingress-text"
                      data-v-f0580954
                    >
                      <p className="mobile-small">
                        In times of economic downturn, such as the ones we are
                        experiencing right now, it is important not to bury our
                        heads in the sand and wait for better times. If everyone
                        thinks the return to a more stable economy will take
                        forever and we can't have it like that, can we?
                      </p>
                    </div>
                    <div
                      class="text-base text-laser flex items-center mt-4 md:mt-0"
                      data-v-f0580954
                    >
                      <span
                        class="group-hover:underline transition-all"
                        data-v-f0580954
                      ></span>{" "}
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ml-2 transform transition-all duration-200 group-hover:translate-x-1"
                        data-v-f0580954
                      >
                        <path
                          d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          data-v-f0580954
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div class="border-b border-darker py-10 w-full" data-v-f0580954>
        <div class="wrapper" data-v-f0580954>
          <div class="lg:flex justify-end" data-v-f0580954>
            <div class="lg:w-9/12" data-v-f0580954>
              <a
                class="story-link hover:text-laser transition-colors duration-200"
                data-v-f0580954
              >
                Therefore, you must have an environmentally friendly website
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="border-b border-darker py-10 w-full" data-v-f0580954>
        <div class="wrapper" data-v-f0580954>
          <div class="lg:flex justify-end" data-v-f0580954>
            <div class="lg:w-9/12" data-v-f0580954>
              <a
                class="story-link hover:text-laser transition-colors duration-200"
                data-v-f0580954
              >
                Swek News 10.23
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper flex justify-end mt-10 md:mt-20" data-v-f0580954>
        <a class="flex items-center group" data-v-f0580954>
          <span
            class="text-laser group-hover:underline transition-all"
            data-v-f0580954
          >
            <Link to="/stories"> All stories</Link>
          </span>{" "}
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2 text-laser transform group-hover:translate-x-1 transition-all duration-200"
            data-v-f0580954
          >
            <path
              d="M17.3137 12.0001L5.31372 12.0001M12.6471 7.33341L17.3137 12.0001L12.6471 7.33341ZM17.3137 12.0001L12.6471 16.6667L17.3137 12.0001Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              data-v-f0580954
            ></path>
          </svg>
        </a>
        <Outlet />
      </div>
    </section>
  );
}

export default Stories;
