import React from "react";
import { Outlet, Link } from "react-router-dom";
import Marquee from "react-fast-marquee";

function MainHome() {

  return (
    <section class="slice relative home-height">
      <div class="wrapper lg:flex mainList">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243196/SWEK/images/Rock-boom/9_cloam6.jpg"
          alt="Swek-Interactive-home"
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            {/* <h1 class="inline-block transform text-darkest agencyDark">
              Design Agency
            </h1> */}
          </div>
        </div>
        <div class="lg:w-9/12">
          <h2 class="huge-title mb-huge-title leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-darkest">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Design</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Love™</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-darkest ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  Swek Interactive is an agency with creative prowess soaring to
                  new heights.{" "}
                </span>
              </p>
              <p className="mobile-small zero-opacity">
                With the help of design, code, content and data-driven
                marketing, we create sustainable brands and digital experiences
                that are felt in the heart.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="overflow-hidden w-full leading-tight my-4 md:my-6 lg:mt-4 lg:mb-10 scroll-mobile scroll-pc"
        data-v-012f42de
      >
        <Marquee>
          <div
            class="scroll-text whitespace-nowrap transform inline-block tracking-[-0.03em] text-[7vw]"
            data-v-012f42de
          >
            <div data-v-012f42de>
              <span class="inline-block" data-v-012f42de>
                <a class="whitespace-nowrap hover:text-laser" data-v-012f42de>
                  <span data-v-012f42de> </span>
                  <Link to="/tech">01.Tech</Link>
                  <span class="text-white" data-v-012f42de>
                    /
                  </span>
                </a>
                <a class="whitespace-nowrap hover:text-laser" data-v-012f42de>
                  <span data-v-012f42de> </span>{" "}
                  <Link to="/digital">02.Digital</Link>
                  <span class="text-white" data-v-012f42de>
                    /
                  </span>
                </a>
                <a class="whitespace-nowrap hover:text-laser" data-v-012f42de>
                  <span data-v-012f42de> </span>
                  <Link to="/branding">03.Branding</Link>
                  <span class="text-white" data-v-012f42de>
                    /
                  </span>
                </a>
                <a class="whitespace-nowrap hover:text-laser" data-v-012f42de>
                  <span data-v-012f42de> </span>{" "}
                  <Link to="/studio">04.Studio</Link>
                  <span class="text-white" data-v-012f42de>
                    /
                  </span>
                </a>
              </span>{" "}
              <span class="inline-block" data-v-012f42de>
                <a class="whitespace-nowrap hover:text-laser" data-v-012f42de>
                  <Link to="/tech">01.Tech</Link>
                  <span class="text-white" data-v-012f42de>
                    /
                  </span>
                </a>
                <a class="whitespace-nowrap hover:text-laser" data-v-012f42de>
                  <Link to="/digital">02.Digital</Link>
                  <span class="text-white" data-v-012f42de>
                    /
                  </span>
                </a>
                <a class="whitespace-nowrap hover:text-laser" data-v-012f42de>
                  <Link to="/branding">03.Branding</Link>
                  <span class="text-white" data-v-012f42de>
                    /
                  </span>
                </a>
                <a class="whitespace-nowrap hover:text-laser" data-v-012f42de>
                  <Link to="/studio">04.Studio</Link>
                  <span class="text-white" data-v-012f42de>
                    /
                  </span>
                </a>
              </span>
            </div>
          </div>
        </Marquee>
        <Outlet />
      </div>
    </section>
  );
}

export default MainHome;
