import React, {useEffect} from 'react'
import InnerIllustration1 from '../../pageSections/BrandingSections/Inner-Illustration/InnerIllustration1'
import InnerIllustration2 from '../../pageSections/BrandingSections/Inner-Illustration/InnerIllustration2'
import InnerIllustration3 from '../../pageSections/BrandingSections/Inner-Illustration/InnerIllustration3'
import InnerIllustration4 from '../../pageSections/BrandingSections/Inner-Illustration/InnerIllustration4'
import InnerIllustration5 from '../../pageSections/BrandingSections/Inner-Illustration/InnerIllustration5'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function IllustrationInner() {


  return (
    <main class="flex-grow contain">
      
    <Helmet>
        <meta charSet="utf-8" />
        <title>Illustrations</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <InnerIllustration1 /> 
          <InnerIllustration2 /> 
          <InnerIllustration3 /> 
          <InnerIllustration4 /> 
          <InnerIllustration5 /> 
        </div>
      </div>
    </main> 
  )
}

export default IllustrationInner
