import React from "react";

function DaDigital5() {
  return (
    <section class="py-20 lg:py-40 bg-lapis" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="white-smiley link rich-text" data-v-7dc95f74>
              <h3>Do you want to talk about digital strategy?</h3>
              <h3>Contact Edwin!</h3>
              <p>
                Edwin Wamala
                <br />
                Project Lead
                <br />
                <a href="mailto:info@swekinteractive.com">Email me</a>
                <br />
                {/* <a href="tel:076-881 01 91">076-881 01 91</a> */}
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243122/SWEK/images/illustrations/9_ovodlo.jpg"
                alt="Julia Karlsson projektledare på digitalbyrån Wilson Creative"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DaDigital5;
