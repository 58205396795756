import React from "react";

function GridFilm1() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243123/SWEK/images/Headers/studio/Film_jnniq7.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">Film production</h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Film</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p className="mobile-small">
                Building strong brands with the help of film, yes, that's our
                thing. We are a creative full-service film production agency
                that makes your message break through the noise using modern
                methods, maximum attention to detail and unbridled commitment.
                Advertising film, film for social media or employer branding?
                Together with us, you take your brand from boy to boy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GridFilm1;
