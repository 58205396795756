import React from "react";

function InnerIllustration2() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Illustration
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Tell with illustration</h2>
            <p className="mobile-small">
              There are many times when a graphic illustration is the best way
              to communicate a message. It can be anything from an instruction
              manual (think IKEA) and complicated statistics to simple icons and
              quirky figures. The message will be unique, educational and
              overcome any language barriers. It becomes extra cozy when you add
              a little movement.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerIllustration2;
