import React from 'react'

function FolioTechThree11() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <div class="overflow-hidden">
                                        <div class="pb-[56%] relative w-full"><video src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/473a7999-c823-4dc7-baa9-9138e58d1a93_Showcase_CloudNative.mp4" autoplay playsinline loop muted class="absolute inset-0 object-cover h-full w-full"></video></div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioTechThree11
