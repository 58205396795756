import React from "react";

function TechHosting4() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h4>Server: Specifications</h4>
              <ul>
                <li>Secure and encrypted connection with SSL (https)</li>
                <li>
                  CDN that delivers your website quickly to all parts of the
                  world.
                </li>
                <li>Monitoring and operational support</li>
                <li>Optimized servers for SEO and performance</li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243138/SWEK/images/Others/server_bo9xrk.png"
                alt="Vamospadelclub.se webbcase"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechHosting4;
