import React, {useEffect} from 'react'
import FolioTechSix1 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix1'
import FolioTechSix2 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix2'
import FolioTechSix3 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix3'
import FolioTechSix4 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix4'
import FolioTechSix5 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix5'
import FolioTechSix6 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix6'
import FolioTechSix7 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix7'
import FolioTechSix8 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix8'
import FolioTechSix9 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix9'
import FolioTechSix10 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix10'
import FolioTechSix11 from '../../pageSections/TechSections/Inner-Folio-Tech-6/FolioTechSix11'
import ReactGA from 'react-ga4'


function TechFolio6() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <FolioTechSix1 />
          <div data-fetch-key="PageFetch:0">
            <FolioTechSix2 /> 
            <FolioTechSix3 /> 
            <FolioTechSix4 /> 
            <FolioTechSix5 /> 
            <FolioTechSix6 /> 
            <FolioTechSix7 /> 
            <FolioTechSix8 /> 
            <FolioTechSix9 /> 
            <FolioTechSix10 /> 
            <FolioTechSix11 /> 
            
          </div>
        </div>
      </div>
    </main>
  )
}

export default TechFolio6
