import React from "react";

function GaDigital5() {
  return (
    <section class="slice bg-darkest" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col-reverse flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="green-smiley rich-text" data-v-7dc95f74>
              <h3>Display advertising with Google Ads</h3>
              <p className="mobile-small">
                With display advertising, also called banner advertising, your message is visible on websites in the Google network. Display ads are clickable areas on web pages and apps that can contain text, images, graphics, video, animation - yes, anything that simply grabs attention. When you advertise in Google's network, you automatically receive a safety net that guarantees that you are only seen on quality websites and do not end up in places that are unsuitable in one way or another. Swek handles both the production of sharp advertising material and the management of the display advertising itself at Google.
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/77cfb830-dffd-4df1-bbe4-791d5a990b08_google+display+network.png?auto=compress,format"
                alt="Google Display Network"
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GaDigital5;
