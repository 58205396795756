import React, { useState, useEffect } from "react";
import "../styles/Navbar.css";
import { Link } from "react-router-dom";

function Navbar() {
  // useState hook to open and close the menu
  const [active, setActive] = useState(false);

  // useEffect hook listening for any clicks to close menu
  useEffect(() => {
    let handler = () => {
      setActive(false);
    };
    document.addEventListener("mousedown", handler);
  });

  return (
    <div data-server-rendered="true" id="__nuxt">
      <div id="__layout" >
        <nav
          id="mainNav"
          aria-labelledby="mainNavButton"
          data-fetch-key="data-v-a08a0460"
          class={active ? "activeMainNav" : "mainNav"}
        >
          <div
            class="absolute inset-0 w-full h-full z-30 flex items-center justify-center pt-8 md:pt-0"
            data-v-a08a0460
          >
            <div class="wrapper" data-v-a08a0460>
              <nav
                class="md:flex w-full max-w-[1360px] mx-auto relative z-40"
                data-v-a08a0460
              >
                <ul
                  class="md:w-1/2 space-y-3 md:space-y-5 lg:space-y-10 mb-8 md:mb-0"
                  data-v-a08a0460
                >
                  <li
                    class="flex items-start opacity-0 transform -translate-x-14"
                    data-v-a08a0460
                  >
                    <span
                      class="text-neon text-[0.5rem] sm:text-base md:text-xl lg:text-2xl block w-8 md:w-20 mt-1 md:mt-0 lg:mt-1"
                      data-v-a08a0460
                    >
                      01
                    </span>{" "}
                    <a
                      href="/tech/"
                      class="leading-none text-4xl md:text-4xl lg:text-5xl xl:text-6xl hover:text-neon transition-colors duration-200"
                      data-v-a08a0460
                    >
                      <Link to="/tech">Tech</Link>
                    </a>
                  </li>
                  <li
                    class="flex items-start opacity-0 transform -translate-x-14"
                    data-v-a08a0460
                  >
                    <span
                      class="text-neon text-[0.5rem] sm:text-base md:text-xl lg:text-2xl block w-8 md:w-20 mt-1 md:mt-0 lg:mt-1"
                      data-v-a08a0460
                    >
                      02
                    </span>{" "}
                    <a
                      href="/digital/"
                      class="leading-none text-4xl md:text-4xl lg:text-5xl xl:text-6xl hover:text-neon transition-colors duration-200"
                      data-v-a08a0460
                    >
                      <Link to="/digital">Digital</Link>
                    </a>
                  </li>
                  <li
                    class="flex items-start opacity-0 transform -translate-x-14"
                    data-v-a08a0460
                  >
                    <span
                      class="text-neon text-[0.5rem] sm:text-base md:text-xl lg:text-2xl block w-8 md:w-20 mt-1 md:mt-0 lg:mt-1"
                      data-v-a08a0460
                    >
                      03
                    </span>{" "}
                    <a
                      href="/branding-design/"
                      class="leading-none text-4xl md:text-4xl lg:text-5xl xl:text-6xl hover:text-neon transition-colors duration-200"
                      data-v-a08a0460
                    >
                      <Link to="/branding">Branding</Link>
                    </a>
                  </li>
                  <li
                    class="flex items-start opacity-0 transform -translate-x-14"
                    data-v-a08a0460
                  >
                    <span
                      class="text-neon text-[0.5rem] sm:text-base md:text-xl lg:text-2xl block w-8 md:w-20 mt-1 md:mt-0 lg:mt-1"
                      data-v-a08a0460
                    >
                      04
                    </span>{" "}
                    <a
                      href="/studio/"
                      class="leading-none text-4xl md:text-4xl lg:text-5xl xl:text-6xl hover:text-neon transition-colors duration-200"
                      data-v-a08a0460
                    >
                      <Link to="/studio">Studio</Link>
                    </a>
                  </li>
                  <li
                    class="flex items-start opacity-0 transform -translate-x-14"
                    data-v-a08a0460
                  >
                    <span
                      class="text-neon text-[0.5rem] sm:text-base md:text-xl lg:text-2xl block w-8 md:w-20 mt-1 md:mt-0 lg:mt-1"
                      data-v-a08a0460
                    >
                      05
                    </span>{" "}
                    <a
                      href="/projekt/"
                      class="leading-none text-4xl md:text-4xl lg:text-5xl xl:text-6xl hover:text-neon transition-colors duration-200"
                      data-v-a08a0460
                    >
                      <Link to="/project">Projects</Link>
                    </a>
                  </li>
                </ul>

                <ul
                  class="md:w-1/2 space-y-3 md:space-y-5 lg:space-y-10"
                  data-v-a08a0460
                >
                  <li
                    class="flex items-start opacity-0 transform translate-x-14"
                    data-v-a08a0460
                  >
                    <span
                      class="text-neon text-[0.5rem] sm:text-base md:text-xl lg:text-2xl block w-8 md:w-20 lg:mt-1"
                      data-v-a08a0460
                    >
                      06
                    </span>{" "}
                    <a
                      href="/wilsonism/"
                      class="leading-none text-xl md:text-4xl lg:text-5xl xl:text-6xl hover:text-neon transition-colors duration-200"
                      data-v-a08a0460
                    >
                      <Link to="/swek">Swek™</Link>
                    </a>
                  </li>
                  <li
                    class="flex items-start opacity-0 transform translate-x-14"
                    data-v-a08a0460
                  >
                    <span
                      class="text-neon text-[0.5rem] sm:text-base md:text-xl lg:text-2xl block w-8 md:w-20 lg:mt-1"
                      data-v-a08a0460
                    >
                      07
                    </span>{" "}
                    <a
                      href="/kontakt/"
                      class="leading-none text-xl md:text-4xl lg:text-5xl xl:text-6xl hover:text-neon transition-colors duration-200"
                      data-v-a08a0460
                    >
                      <Link to="/contact">Contact</Link>
                    </a>
                  </li>
                  <li
                    class="flex items-start opacity-0 transform translate-x-14"
                    data-v-a08a0460
                  >
                    <span
                      class="text-neon text-[0.5rem] sm:text-base md:text-xl lg:text-2xl block w-8 md:w-20 lg:mt-1"
                      data-v-a08a0460
                    >
                      08
                    </span>{" "}
                    <a
                      href="/karriar/"
                      class="leading-none text-xl md:text-4xl lg:text-5xl xl:text-6xl hover:text-neon transition-colors duration-200"
                      data-v-a08a0460
                    >
                      <Link to="/career">Career</Link>
                    </a>
                  </li>
                  <li
                    class="flex items-start opacity-0 transform translate-x-14"
                    data-v-a08a0460
                  >
                    <span
                      class="text-neon text-[0.5rem] sm:text-base md:text-xl lg:text-2xl block w-8 md:w-20 lg:mt-1"
                      data-v-a08a0460
                    >
                      09
                    </span>{" "}
                    <a
                      href="/stories/"
                      class="leading-none text-xl md:text-4xl lg:text-5xl xl:text-6xl hover:text-neon transition-colors duration-200"
                      data-v-a08a0460
                    >
                      <Link to="/stories">Stories</Link>
                    </a>
                  </li>
                  <li
                    class="flex items-start opacity-0 transform translate-x-14"
                    data-v-a08a0460
                  >
                    <span
                      class="text-neon text-[0.5rem] sm:text-base md:text-xl lg:text-2xl block w-8 md:w-20 lg:mt-1"
                      data-v-a08a0460
                    >
                      10
                    </span>{" "}
                    <a
                      href="/digital-love-by-wilson-creative/"
                      class="leading-none text-xl md:text-4xl lg:text-5xl xl:text-6xl hover:text-neon transition-colors duration-200"
                      data-v-a08a0460
                    >
                      <Link to="/philosophy">Philosophy</Link>
                    </a>
                  </li>
                </ul>
              </nav>
              <div>
                <div
                  class="w-full max-w-7xl text-darker absolute top-0 right-0 transform translate-x-44 -translate-y-1/4 scale-90 origin-center opacity-0"
                  data-v-a08a0460
                ></div>
              </div>
            </div>
          </div>
          <div class="w-[100vw] h-[100vw] absolut inset-0" data-v-a08a0460>
            <div
              class="bg-black h-full w-1/2 absolute left-0 bottom-0 transform scale-y-0 origin-bottom-left"
              data-v-a08a0460
            ></div>
            <div
              class="bg-black h-full w-1/2 absolute right-0 bottom-0 transform scale-y-0 origin-bottom-left"
              data-v-a08a0460
            ></div>
            <div
              class="bg-darkest-overlay h-full w-1/2 absolute left-0 bottom-0 transform scale-y-0 origin-bottom-left"
              data-v-a08a0460
            ></div>
            <div
              class="bg-darkest-overlay h-full w-1/2 absolute right-0 bottom-0 transform scale-y-0 origin-bottom-left"
              data-v-a08a0460
            ></div>
          </div>

          {/* <div className='rotate'>
          <div className='rotMain'>
            <img className='rot rot2' src='/images/aa.svg'/>
          </div>
        </div> */}
        </nav>

        <header class="pt-7 md:pt-10 lg:pt-14 fixed w-full z-50" >
          <div class="wrapper flex items-center justify-between ">
            <a
              href="/"
              aria-label="Wilson Creative logo"
              class="nuxt-link-active"
            >
              <div data-v-0c0a2bd1>
                <div class="flex items-center" data-v-0c0a2bd1>
                  <div class="w-[30px] h-[30px]" data-v-0c0a2bd1>
                    <div
                      class="w-[30px] h-[30px] relative transform -rotate-45 origin-center translate-y-[-6px]"
                      data-v-0c0a2bd1
                    >
                      <div
                        class="box bottom-[14px] left-[21px]"
                        data-v-0c0a2bd1
                      >
                        <Link to="/"></Link>
                      </div>
                    </div>
                  </div>
                  <div
                    class="text-[30px] ml-[18px] tracking-[-1px] overflow-hidden leading-none hidden sm:flex nueuplak"
                    data-v-0c0a2bd1
                  >
                    <span class="chars" data-v-0c0a2bd1>
                      S
                    </span>
                    <Link to="/"></Link>
                    <span class="chars" data-v-0c0a2bd1>
                      W
                    </span>
                    <Link to="/"></Link>
                    <span class="chars" data-v-0c0a2bd1>
                      E
                    </span>
                    <Link to="/"></Link>
                    <span class="chars" data-v-0c0a2bd1>
                      K
                    </span>
                  </div>
                </div>
              </div>
            </a>
            <div
              id="mainNavButton"
              aria-haspopup="true"
              aria-controls="mainNav"
              class="w-10 h-10 relative cursor-pointer text-white transition-colors duration-200 BGM"
              onClick={() => setActive(!active)}
            >
              <div className={active ? "activeHamburger" : "hamburger"} />
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Navbar;
