import React from "react";

function KalmaFolio8() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full">
        <div class="overflow-hidden">
          <div class="pb-[56%] relative w-full">
            <img
              loading="lazy"
              src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243254/SWEK/images/GenOrganics/4_nwn3ku.jpg"
              alt="Gen-Organics-app icon"
              class="absolute inset-0 object-cover h-full w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default KalmaFolio8;
