import React from 'react'

function OlandFolio30() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/6268e79c-5434-43d4-9134-4a01e1fcd10d_oland_16x9_retro.jpg?auto=compress,format" alt="Kollage gamla vykort från Öland" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/d0582464-36a8-456a-822c-866af4ebeb5f_oland_16x9_solens_vindarnas_o.jpg?auto=compress,format" alt="Solens och vindarnas ö - grafik på bild" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio30
