import React from "react";

function GaDigital1() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Google Ads
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Advertise with Google Ads for quick impact</h2>
            <p className="mobile-small">
              Advertising on Google is suitable for most businesses and is a profitable and effective way to create visibility to a target group that is actively searching for what you offer. Unlike SEO, which gives effect in the longer term, advertising on Google Ads gives results quickly. In addition, you only pay for actual clicks on the ad, which means that the marketing budget really ends up where it does the most good. Are you looking for an experienced and results-oriented Google Ads agency? You have found the right one!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GaDigital1;
