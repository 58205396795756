import React, {useEffect} from 'react'
import InnerBrand1 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand1'
import InnerBrand2 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand2'
import InnerBrand3 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand3'
import InnerBrand4 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand4'
import InnerBrand5 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand5'
import InnerBrand6 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand6'
import InnerBrand7 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand7'
import InnerBrand8 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand8'
import InnerBrand9 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand9'
import InnerBrand10 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand10'
import InnerBrand12 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand12'
import InnerBrand11 from '../../pageSections/BrandingSections/Inner-Branding/InnerBrand11'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function BrandingInner() {

  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Brand</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <InnerBrand1 />
            <InnerBrand2 />
            <InnerBrand3 />
            <InnerBrand4 />
            <InnerBrand5 />
            <InnerBrand6 />
            <InnerBrand7 />
            <InnerBrand8 />
            <InnerBrand9 />
            {/* <InnerBrand10 /> */}
            <InnerBrand11 />
            <InnerBrand12 />
        </div>
      </div>
    </main> 
  )
}

export default BrandingInner
