import React, {useEffect} from 'react'
import InnerDesign1 from '../../pageSections/BrandingSections/Inner-Design/InnerDesign1'
import InnerDesign2 from '../../pageSections/BrandingSections/Inner-Design/InnerDesign2'
import InnerDesign3 from '../../pageSections/BrandingSections/Inner-Design/InnerDesign3'
import InnerDesign4 from '../../pageSections/BrandingSections/Inner-Design/InnerDesign4'
import InnerDesign5 from '../../pageSections/BrandingSections/Inner-Design/InnerDesign5'
import InnerDesign6 from '../../pageSections/BrandingSections/Inner-Design/InnerDesign6'
import InnerDesign7 from '../../pageSections/BrandingSections/Inner-Design/InnerDesign7'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function DesignInner() {

  return (
    <main class="flex-grow contain">

    <Helmet>
        <meta charSet="utf-8" />
        <title>Design</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
           <InnerDesign1 />
           <InnerDesign2 />
           {/* <InnerDesign3 /> */}
           <InnerDesign4 />
           <InnerDesign5 />
           <InnerDesign6 />
           <InnerDesign7 />
        </div>
      </div>
    </main> 
  )
}

export default DesignInner
