import React from "react";

function FolioDigitalThree1() {
  return (
    <section class="pt-page pb-20 lg:pb-40 home-reset" >
      <div class="wrapper lg:flex">
        <div class="lg:w-3/12">
          <span class="label block mb-label">Project</span>
        </div>
        <div class="lg:w-9/12">
          <div class="huge-title">
            <h1>Rock Boom Energy Drink</h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigitalThree1;
