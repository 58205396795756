import React, {useEffect} from 'react'
import FolioDigitalFour1 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour1'
import FolioDigitalFour2 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour2'
import FolioDigitalFour3 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour3'
import FolioDigitalFour4 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour4'
import FolioDigitalFour5 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour5'
import FolioDigitalFour6 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour6'
import FolioDigitalFour7 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour7'
import FolioDigitalFour8 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour8'
import FolioDigitalFour9 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour9'
import FolioDigitalFour11 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour11'
import FolioDigitalFour10 from '../../pageSections/DigitalSections/Inner-Folio-Digital-4/FolioDigitalFour10'
import ReactGA from 'react-ga4'

function DigitalFolio4() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <div data-fetch-key="PageFetch:0">
            <FolioDigitalFour1 />
            <FolioDigitalFour2 />
            <FolioDigitalFour3 />
            <FolioDigitalFour4 />
            <FolioDigitalFour5 />
            <FolioDigitalFour6 />
            <FolioDigitalFour7 />
            <FolioDigitalFour8 />
            <FolioDigitalFour9 />
            <FolioDigitalFour10 />
            <FolioDigitalFour11 />
            
          </div>
        </div>
      </div>
    </main>
  )
}

export default DigitalFolio4
