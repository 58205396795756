import React from 'react'

function FolioDigitalFour2() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full">
                                    <div class="overflow-hidden">
                                        <div class="pb-[56%] relative w-full"><video src="https://wilsoncreative.cdn.prismic.io/wilsoncreative/18bd98c1-065b-4259-b14e-fbb666aa7061_Guldga%CC%8Ageln_2023_Case_1920x1080.mp4" autoplay playsinline loop muted class="absolute inset-0 object-cover h-full w-full"></video></div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioDigitalFour2
