import React from "react";

function InnerConcept1() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <div class="lg:w-3/12">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Concept development and communication
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Concept</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  A communication concept is a kind of embodiment of the brand
                  platform.{" "}
                </span>
              </p>
              <p className="mobile-small">
                The aim is to arouse emotions in the target group and encourage
                action. Follow along and we'll explain more!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerConcept1;
