import React from "react";

function SmDigital7() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Choose the right one and opt out
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>
              The purpose of advertising in social media is to create value for the target audience
            </h3>
            <p className="mobile-small">
              Having a strategic and conscious presence in social media is the key to success. If you want to succeed, you need both commitment and resources, but above all a purpose. Being in all available social media just because it is expected of a modern company is not a good idea. Then it is smarter to choose your platforms with care and thus create value for the target group with increased loyalty as a result. Marketing in social media is not necessarily about reaching as many people as possible, the focus should rather be on reaching the right people. All social media platforms have advanced functionality for segmentation and customization so that you can reach your target group with the right message at the right time. The ultimate goal is of course to make the brand's target group ambassadors and therefore we make it easy for them to get involved and spread your gospel to their network. This requires engaging content and ongoing communication.{" "}
            </p>
            <p className="mobile-small">
              Does it feel complicated? Don't worry, we at Swek Interactive will help you choose the right SoMe channels, set all the parameters and thus give you the tools for successful social media marketing.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SmDigital7;
