import React from "react";

function FolioDigital10() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56
             className="black-convert">
              Kalmar FF x Kvinnojouren
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2 className="black-convert">Kalmar FF sabbar stämningen med Kvinnojouren</h2>
            <p className="black-convert">
              <a
                target="_blank"
                rel="noopener"
                href="https://www.unizonjourer.se/kvinnojourenkalmar/"
               >
                Kvinnojouren i Kalmar
              </a>{" "}
              och Kalmar FF har teamat upp för att utbilda samtliga aktiva i
              fotbollsföreningen om mäns våld mot kvinnor. I samband med detta
              lanserades en kampanj under hashtaggen #sabbastämningen för att
              uppmuntra fler att engagera sig mot våld, machokultur och
              sexistisk jargong.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital10;
