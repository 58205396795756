import React from "react";
import { Outlet, Link } from "react-router-dom";

function CareerMessage1() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Unsolicited job application
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Job / Internship</h2>
            <p className="mobile-small">
              At the moment, we don't have any vacant positions, but don't let
              that stop you from submitting an unsolicited application. When
              time permits, we also welcome enthusiastic interns. Submit your
              application with a CV!{" "}
            </p>
            <p></p>
            <h4>Working at Swek</h4>
            <p className="mobile-small">
              To develop Swek Interactive, we need a team of extremely
              knowledgeable, dedicated, and creative individuals. In order to
              attract and retain the very best, Swek Interactive aspires to be an
              exceptionally good employer where employees enjoy their work, feel
              they make a meaningful impact in the world, and experience
              significant creative freedom. In return, we have high expectations
              for loyalty, professionalism, and expertise. Our business is built
              on love and trust, completely devoid of cynicism. We care for each
              other, are curious about one another, and fully accept each other.
              Love creates the security that enables creativity, freedom, and a
              bit of playful nonsense.{" "}
              <Link to="/philosophy">
                <a>Read more about our philosophy here.</a>
              </Link>{" "}
            </p>
          </div>
        </div>
        <Outlet />
      </div>
    </section>
  );
}

export default CareerMessage1;
