import React from "react";

function FolioDigital8() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56
              className="black-convert">
              Effekt
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h3 className="black-convert">Engagemanget boostas och mäts</h3>
            <p className="black-convert">
              Vi håller full koll på visningar, kommentarer och annat
              engagemang. Vårt huvudfokus ligger såklart på föreningens fans men
              det finns en viktig sekundär målgrupp i andra lags supportrar,
              influencers och journalister. Många gånger är det bara en
              tidsfråga innan tidningar och andra nyhetskanaler snappar upp och
              rapporterar vilket ger budskapet ännu större vingar.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital8;
