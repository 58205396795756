import React from "react";

function DigitalHome() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243232/SWEK/images/Headers/digital_k2zif4.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243213/SWEK/images/Headers/digital2_rcamzi.jpg"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case mobile"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">Digital marketing</h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Swek</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Digital</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  Creative digital marketing with measurable results.
                </span>
              </p>
              <p className="mobile-small">
                With digital marketing, we can identify and reach your target
                group with perfect timing. We use data analysis to understand
                the digital behaviors of your prospective customers and thus get
                the maximum effect of the marketing by being visible where it is
                most profitable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DigitalHome;
