import React from "react";

function GridPhoto5() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243583/SWEK/images/Photo/4_agmypu.jpg"
            alt="Erik Israelsson på Guldfågeln Arena"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243635/SWEK/images/Photo/5_p0lrbf.jpg"
            alt="Joakim Haeggman Ekerum Resort"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default GridPhoto5;
