import React from "react";

function InnerBrand4() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Strengthen your position
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>Why do you need a brand strategy?</h3>
            <p className="mobile-small">
              In today's competitive market, a well-thought-out brand strategy
              is essential. It gives you a clear plan for how your brand should
              be perceived in the market, which direction your company should
              take and how you can create lasting value for both your company
              and your customers. As I said, a brand strategy is not only about
              choosing the right logo, color scheme or visual style, but also
              about understanding your target audience and how you can meet
              their needs and expectations. An effective strategy will help you
              position your company in the market, differentiate yourself from
              the competition and build a strong, recognizable identity. With
              Swek Interactive, you get a partner who not only helps you
              formulate your brand strategy, but also implement it in a way that
              resonates with your target audience and strengthens your position
              in the market.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnerBrand4;
