import React from "react";
import  {Outlet, Link} from 'react-router-dom'

function TechUX4() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              UX Design in five steps
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>1. Product Definition</h3>
            <p className="mobile-small">
              We take care early on to define the main purpose of the service. What are we going to build, for whom and what problem are we solving? Here, Wilson's UX expert takes the opportunity to collect as much information as possible from the client, often with the help of an inspiring workshop.
            </p>
            <h3>2. Research</h3>
            <p className="mobile-small">
              In the second phase of the UX process, an environmental analysis is carried out where we check similar solutions and competitors, both direct and indirect. We conduct in-depth interviews with possible users of the service, where we ask questions and analyze behavior. Here we also collect available statistics and data from analysis tools.
            </p>
            <h3>3. Analysis and technical specification</h3>
            <p className="mobile-small">
              In this stage, we develop personas and create impact maps based on insights from the previous steps. We conclude by producing a technical specification where we clearly define what is to be built, including functions and technologies. So, now we have everything in place to be able to enter the UI design phase.
            </p>
            <h3>4. UI and web design</h3>
            <p className="mobile-small">
              Here the work begins with what is called User Interface Design, abbreviated UI design. In order to create the right conditions, we often start from a mood board to capture the overall expression and shape of the visual interface. When the feeling is right, we move on to sketching wireframes that serve as a concretization of structure and functionality. Here, it may also be relevant to develop a simple prototype that enables a first feel for the service. The last part of the UI design is to finalize the final web design as it will look on different screens when the site or app is launched.
            </p>
            <h3>5. Testing</h3>
            <p className="mobile-small">
              Before launch, we have to check that everything really works as well as we want. We test internally as well as against users, check that SEO and page load speed are as they should be and can also do A/B tests to make sure that the user experience is satisfactory. More about this last phase on{" "}
              <Link to='/techwebdev'><a>the web development page</a></Link>
            </p>
          </div>
              </div>
              <Outlet/>
      </div>
    </section>
  );
}

export default TechUX4;
