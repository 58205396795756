import React from "react";

function DillFolio21() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243307/SWEK/images/Aamaka/Gif-1_vz55sr.gif"
            alt="Swek Interactive - Aamaka"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <video
            class="absolute inset-0 object-cover h-full w-full"
            controls
            src="./images/Aamaka/Reel.mp4"
            autoPlay
            loop
            muted
          ></video>
        </figure>
      </div>
    </section>
  );
}

export default DillFolio21;
