import React from "react";

function FolioDigital5() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/b821ee79-a05d-40a1-a98a-0cd4cb5463f3_kff_2022_kampanjer_1x1_1.jpg?auto=compress,format"
            alt="Simon Skrabb presenteras för Kalmar FF i sociala medier"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/48b88be3-bdf7-4ed4-9d59-7e6a0f3b440a_kff_2022_kampanjer_1x1_2.jpg?auto=compress,format"
            alt="Resultat av kampanj Simon Skrabb Kalmar FF"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigital5;
