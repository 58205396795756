import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./Pages/Home";
import Footer from "./components/Footer";
import Tech from "./Pages/Tech";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Digital from "./Pages/Digital";
import Branding from "./Pages/Branding";
import Studio from "./Pages/Studio";
import Project from "./Pages/Project";
import Swek from "./Pages/Swek";
import Contact from "./Pages/Contact";
import Career from "./Pages/Career";
import Stories from "./Pages/Stories";
import Philosophy from "./Pages/Philosophy";
import DigitalDataAnalysis from "./Pages/Inner-Digital-M/DigitalDataAnalysis";
import DigitalSocialMedia from "./Pages/Inner-Digital-M/DigitalSocialMedia";
import DigitalStrategy from "./Pages/Inner-Digital-M/DigitalStrategy";
import GoogleAds from "./Pages/Inner-Digital-M/GoogleAds";
import Content from "./Pages/Inner-Digital-M/Content";
import DigitalFolio from "./Pages/Inner-Digital-M/DigitalFolio";
import DigitalFolio2 from "./Pages/Inner-Digital-M/DigitalFolio2";
import DigitalFolio3 from "./Pages/Inner-Digital-M/DigitalFolio3";
import DigitalFolio4 from "./Pages/Inner-Digital-M/DigitalFolio4";
import TechFolio from "./Pages/Inner-Tech-M/TechFolio";
import TechFolio2 from "./Pages/Inner-Tech-M/TechFolio2";
import TechFolio3 from "./Pages/Inner-Tech-M/TechFolio3";
import TechFolio4 from "./Pages/Inner-Tech-M/TechFolio4";
import TechFolio5 from "./Pages/Inner-Tech-M/TechFolio5";
import TechFolio6 from "./Pages/Inner-Tech-M/TechFolio6";
import TechUXDesign from "./Pages/Inner-Tech-M/TechUXDesign";
import TechWebdev from "./Pages/Inner-Tech-M/TechWebdev";
import TechEcommercePage from "./Pages/Inner-Tech-M/TechEcommercePage";
import TechApp from "./Pages/Inner-Tech-M/TechApp";
import TechSeo from "./Pages/Inner-Tech-M/TechSeo";
import TechHosting from "./Pages/Inner-Tech-M/TechHosting";
import BrandingInner from "./Pages/Inner-Branding/BrandingInner";
import DesignInner from "./Pages/Inner-Branding/DesignInner";
import ConnectInner from "./Pages/Inner-Branding/ConnectInner";
import CopyInner from "./Pages/Inner-Branding/CopyInner";
import IllustrationInner from "./Pages/Inner-Branding/IllustrationInner";
import OlandFolio from "./Pages/Inner-Branding/OlandFolio";
import StrifeFolio from "./Pages/Inner-Branding/StrifeFolio";
import KalmaFolio from "./Pages/Inner-Branding/KalmaFolio";
import HayFolio from "./Pages/Inner-Branding/HayFolio";
import DillFolio from "./Pages/Inner-Branding/DillFolio";
import Film from "./Pages/Inner-Story/Film";
import Animation from "./Pages/Inner-Story/Animation";
import Photo from "./Pages/Inner-Story/Photo";
import OurPolicy from "./Pages/Inner-Form/OurPolicy";
import ScrollToTop from "./components/ScrollToTop";
import Blog1 from "./Pages/Inner-Stories/Blog1";
import Blog2 from "./Pages/Inner-Stories/Blog2";

import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          <title>
            Swek Interactive - Welcome to Swek, where creativity knows no
            bounds. As a multidimensional creative studio, we specialize in the
            dynamic realms of design, film, and visual arts.
          </title>

          <meta
            name="description"
            content="Welcome to Swek, where creativity knows no bounds. As a multidimensional creative studio, we specialize in the dynamic realms of design, film, and visual arts. Our team is dedicated to pushing the boundaries of innovation and storytelling, crafting compelling narratives and visually stunning experiences."
          />

          {/* Twitter meta tags below */}
          {/* <!--  Essential META Tags --> */}
          <meta property="og:title" content="Swek Interactive" />
          <meta property="og:type" content="article" />
          <meta
            property="og:image"
            content="https://twitter.com/Interactiv87233/status/1769378432268333501/photo/1"
          />
          <meta
            property="og:url"
            content="http://swekinteractive.com/index.htm"
          />
          <meta name="twitter:card" content="summary_large_image" />

          {/* <!--  Non-Essential, But Recommended --> */}
          <meta
            property="og:description"
            content="Swek Interactive - A multidimensional creative studio, we specialize in the dynamic realms of design, film, and visual arts."
          />
          <meta property="og:site_name" content="Swek Interactive." />
          <meta name="twitter:image:alt" content="Alt text for image" />

          {/* <!--  Non-Essential, But Required for Analytics --> */}
          <meta property="fb:app_id" content="your_app_id" />
          <meta name="twitter:site" content="@Interactiv87233" />

          {/* Facebook meta tags below */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://swekinteractive.com/" />
          <meta
            property="og:title"
            content="Swek Interactive - A multidimensional creative studio, we specialize in the dynamic realms of design, film, and visual arts."
          />
          <meta
            property="og:description"
            content="Swek Interactive - Welcome to Swek, where creativity knows no bounds. As a multidimensional creative studio, we specialize in the dynamic realms of design, film, and visual arts."
          />
          <meta
            property="og:image"
            content="https://twitter.com/Interactiv87233/status/1769378432268333501/photo/1"
          />

          {/* Likedin meta tags below */}
          <meta
            prefix="og: http://ogp.me/ns#"
            property="og:title"
            content="https://swekinteractive.com/"
          />
          <meta
            prefix="og: http://ogp.me/ns#"
            property="og:description"
            content="https://swekinteractive.com/"
          />
          <meta
            property="og:title"
            content="Swek Interactive - A multidimensional creative studio, we specialize in the dynamic realms of design, film, and visual arts."
          />
          <meta
            property="og:description"
            content="Swek Interactive - Welcome to Swek, where creativity knows no bounds. As a multidimensional creative studio, we specialize in the dynamic realms of design, film, and visual arts."
          />
          <meta
            prefix="og: http://ogp.me/ns#"
            property="og:image"
            content="https://swekinteractive.com/"
          />
        </Helmet>

        <Router>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />

            {/* Tech Nested Links */}
            <Route path="tech" element={<Tech />}>
              <Route path="techfolio" element={<TechFolio />} />
              <Route path="techfolio2" element={<TechFolio2 />} />
              <Route path="techfolio3" element={<TechFolio3 />} />
              <Route path="techfolio4" element={<TechFolio4 />} />
              <Route path="techfolio5" element={<TechFolio5 />} />
              <Route path="techfolio6" element={<TechFolio6 />} />
              <Route path="techuxdesign" element={<TechUXDesign />} />
              <Route path="techwebdev" element={<TechWebdev />} />
              <Route path="techecommercepage" element={<TechEcommercePage />} />
              <Route path="techapp" element={<TechApp />} />
              <Route path="techseo" element={<TechSeo />} />
              <Route path="techhosting" element={<TechHosting />} />
            </Route>

            {/* Digital Nested Links */}
            <Route path="digital" element={<Digital />}>
              <Route
                path="digitaldataanalysis"
                element={<DigitalDataAnalysis />}
              />
              <Route
                path="digitalsocialmedia"
                element={<DigitalSocialMedia />}
              />
              <Route path="digitalstrategy" element={<DigitalStrategy />} />
              <Route path="googleads" element={<GoogleAds />} />
              <Route path="content" element={<Content />} />
              <Route path="digitalfolio" element={<DigitalFolio />} />
              <Route path="digitalfolio2" element={<DigitalFolio2 />} />
              <Route path="digitalfolio3" element={<DigitalFolio3 />} />
              <Route path="digitalfolio4" element={<DigitalFolio4 />} />
            </Route>

            {/* Branding */}
            <Route path="branding" element={<Branding />}>
              <Route path="brandinginner" element={<BrandingInner />} />
              <Route path="designinner" element={<DesignInner />} />
              <Route path="connectinner" element={<ConnectInner />} />
              <Route path="copyinner" element={<CopyInner />} />
              <Route path="illustrationinner" element={<IllustrationInner />} />
              <Route path="olandfolio" element={<OlandFolio />} />
              <Route path="strifefolio" element={<StrifeFolio />} />
              <Route path="kalmafolio" element={<KalmaFolio />} />
              <Route path="hayfolio" element={<HayFolio />} />
              <Route path="dillfolio" element={<DillFolio />} />
            </Route>

            {/* Studio */}
            <Route path="studio" element={<Studio />}>
              <Route path="animation" element={<Animation />} />
              <Route path="film" element={<Film />} />
              <Route path="photo" element={<Photo />} />
            </Route>

            {/* others */}
            <Route path="/project" element={<Project />} />
            <Route path="/swek" element={<Swek />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/career" element={<Career />} />

            {/* The Blog */}
            <Route path="stories" element={<Stories />}>
              <Route path="blog1" element={<Blog1 />} />
              <Route path="blog2" element={<Blog2 />} />
            </Route>

            <Route path="/philosophy" element={<Philosophy />} />
            <Route path="/ourpolicy" element={<OurPolicy />} />

            {/* Digital */}
            <Route
              path="/digitaldataanalysis"
              element={<DigitalDataAnalysis />}
            />
            <Route
              path="/digitalsocialmedia"
              element={<DigitalSocialMedia />}
            />
            <Route path="/digitalstrategy" element={<DigitalStrategy />} />
            <Route path="/googleads" element={<GoogleAds />} />
            <Route path="/content" element={<Content />} />
            <Route path="/digitalfolio" element={<DigitalFolio />} />
            <Route path="/digitalfolio2" element={<DigitalFolio2 />} />
            <Route path="/digitalfolio3" element={<DigitalFolio3 />} />
            <Route path="/digitalfolio4" element={<DigitalFolio4 />} />

            {/* Tech */}
            <Route path="/techfolio" element={<TechFolio />} />
            <Route path="/techfolio2" element={<TechFolio2 />} />
            <Route path="/techfolio3" element={<TechFolio3 />} />
            <Route path="/techfolio4" element={<TechFolio4 />} />
            <Route path="/techfolio5" element={<TechFolio5 />} />
            <Route path="/techfolio6" element={<TechFolio6 />} />
            <Route path="/techuxdesign" element={<TechUXDesign />} />
            <Route path="/techwebdev" element={<TechWebdev />} />
            <Route path="/techecommercepage" element={<TechEcommercePage />} />
            <Route path="/techapp" element={<TechApp />} />
            <Route path="/techseo" element={<TechSeo />} />
            <Route path="/techhosting" element={<TechHosting />} />

            {/* Branding */}
            <Route path="/brandinginner" element={<BrandingInner />} />
            <Route path="/designinner" element={<DesignInner />} />
            <Route path="/connectinner" element={<ConnectInner />} />
            <Route path="/copyinner" element={<CopyInner />} />
            <Route path="/illustrationinner" element={<IllustrationInner />} />
            <Route path="/olandfolio" element={<OlandFolio />} />
            <Route path="/strifefolio" element={<StrifeFolio />} />
            <Route path="/kalmafolio" element={<KalmaFolio />} />
            <Route path="/hayfolio" element={<HayFolio />} />
            <Route path="/dillfolio" element={<DillFolio />} />

            {/* Studio */}
            <Route path="/animation" element={<Animation />} />
            <Route path="/film" element={<Film />} />
            <Route path="/photo" element={<Photo />} />

            {/* Blog */}
            <Route path="/blog1" element={<Blog1 />} />
            <Route path="/blog2" element={<Blog2 />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
