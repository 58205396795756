import React from "react";

function TechEcommerce5() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Choose the right e-commerce platform.
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>Your web agency for Shopify.</h3>
            <p className="mobile-small">
              If we at Swek Interactive get to choose, we gladly tailor a webshop from A to Z with Shopify as the headless e-commerce foundation. This allows visitors to check out through Shopify's ready-made checkout and choose from various payment solutions such as PayPal and Klarna. This way, your online store is up and running in the shortest possible time, with scalability for future development if needed. We find this e-commerce platform to be exactly as simple, flexible, and cost-effective as we want it.
            </p>
            <h3>Headless CMS and WooCommerce</h3>
            <p className="mobile-small">
              We like Shopify, but we also use other headless (CMS-independent) e-commerce platforms such as Storm and Centra. They are particularly suitable for more extensive ventures. Finally, we also have WooCommerce, which is a classic in this context. Needs, ambitions, and the market determine the choice. We guide you to the right platform for your new online store, of course!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechEcommerce5;
