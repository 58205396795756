import React from "react";

function FolioDigital18() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <img
              loading="lazy"
              src="https://images.prismic.io/wilsoncreative/1aa57a77-dd9a-4c7d-b51d-2604d2ea144e_Viktor+Elm.png?auto=compress,format"
              alt="Tack för allt Viktor Elm filmutdrag"
              class="absolute inset-0 object-cover h-full w-full cursor-pointer"
            />{" "}
            <img
              src="/_nuxt/img/play.17179b4.svg"
              alt="Starta uppspelning"
              loading="lazy"
              class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            />
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <img
              loading="lazy"
              src="https://images.prismic.io/wilsoncreative/f0d82790-e715-4ffa-9e15-79c231fc7811_KFF+sm.png?auto=compress,format"
              alt="KFF Matchställ filmutdrag"
              class="absolute inset-0 object-cover h-full w-full cursor-pointer"
            />{" "}
            <img
              src="/_nuxt/img/play.17179b4.svg"
              alt="Starta uppspelning"
              loading="lazy"
              class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            />
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <img
              loading="lazy"
              src="https://images.prismic.io/wilsoncreative/8574c813-89ac-49b0-9c3f-96d9c9c957b2_Bajen.png?auto=compress,format"
              alt="Bajenamnesti filmutdrag"
              class="absolute inset-0 object-cover h-full w-full cursor-pointer"
            />{" "}
            <img
              src="/_nuxt/img/play.17179b4.svg"
              alt="Starta uppspelning"
              loading="lazy"
              class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            />
          </figure>
        </div>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <div class="overflow-hidden">
          <figure class="pb-[56%] relative w-full w-full relative overflow-hidden">
            <img
              loading="lazy"
              src="https://images.prismic.io/wilsoncreative/4d0bc505-0ef9-47f2-b4b3-a57a508a128d_KFF+sm+2.png?auto=compress,format"
              alt="Bajenkampanjer filmutdrag"
              class="absolute inset-0 object-cover h-full w-full cursor-pointer"
            />{" "}
            <img
              src="/_nuxt/img/play.17179b4.svg"
              alt="Starta uppspelning"
              loading="lazy"
              class="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
            />
          </figure>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital18;
