import React from "react";

function SocialMedia() {
  return (
    <section class="slice relative home-reset">
      <div class="wrapper lg:flex">
        <img
          src="https://res.cloudinary.com/dxatqyrxn/image/upload/v1723243123/SWEK/images/Headers/digital/swek-social_w51qhu.png"
          alt="Swek-Interactive-home."
          loading="lazy"
          class="w-full h-full object-cover absolute inset-0 case"
        ></img>

        <div class="lg:w-3/12 delete">
          <div class="label mb-label block leading-tight overflow-hidden md:max-w-[200px]">
            <h1 class="inline-block transform text-white">
              Agency for advertising in social media
            </h1>
          </div>
        </div>
        <div class="lg:w-9/12 delete">
          <h2 class="huge-title mb-[1rem] leading-none pt-8 md:pt-14 lg:pt-[0.15em] text-laser">
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">Social</span>
            </span>{" "}
            <span class="word inline-block overflow-hidden mt-[-0.35em] leading-tight">
              <span class="inline-block align-top">media</span>
            </span>
          </h2>
          <div>
            <div class="rich-text text-white ingress-text w-full max-w-3xl">
              <p>
                <span class="ingress">
                  With advertising in social media, also called Paid Social, you
                  reach out to both existing and potential customers.{" "}
                </span>
              </p>
              <p className="mobile-small">
                Swek Interactive is involved all the way from strategy, content
                production, optimization, analysis and reporting so that social
                media marketing generates maximum business benefit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SocialMedia;
