import React from "react";

function FolioTech2() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight"
              data-v-2ac86e56
            >
              Intro
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56>
            <h2>Humanity = accessibility</h2>
            <p>
              Bräcke Diakoni is a non-profit foundation without owners and profit interests. Under the slogan 'A more humane society,' they have served as an innovative welfare actor for a hundred years, and today, there are more than 40 operations within the organization. In time for the 100th anniversary, Wilson Creative was commissioned to create Bräcke Diakoni's new website from scratch.
            </p>
            <p>
              Med utgångspunkt i respektive verksamhets målsättningar, grundliga
              effektkartor och med tillgänglighetsanpassning i centrum, skapade
              vi en framtidssäkrad multisajt designad utifrån de som behöver
              webben och deras behov, oavsett om de är patienter på en
              vårdcentral eller en anhörig som söker information om äldreboende.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioTech2;
