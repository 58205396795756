import React from "react";

function FolioDigital15() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/71f1d39a-5bf8-4157-b466-29d0a8d61e38_kff_2016_mockup.jpg?auto=compress,format"
            alt="Matchaffisch Kalmar FF - AIK"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/e04a9115-7642-4031-af27-e414cec96c23_kff_2014_mockup.jpg?auto=compress,format"
            alt="Matchaffisch Kalmar FF - BK Häcken"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/633197c7-5a34-4fb3-8034-104387181a74_kff_2018_mockup.jpg?auto=compress,format"
            alt="Matchaffisch Kalmar FF - IFK Göteborg"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/795b5fb1-a106-467f-b6aa-186b10083e51_kff_2013_mockup.jpg?auto=compress,format"
            alt="Matchaffisch Kalmar FF - Östers IF"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigital15;
