import React, {useEffect} from 'react'
import FolioDigitalTwo1 from '../../pageSections/DigitalSections/Inner-Folio-Digital-2/FolioDigitalTwo1'
import FolioDigitalTwo2 from '../../pageSections/DigitalSections/Inner-Folio-Digital-2/FolioDigitalTwo2'
import FolioDigitalTwo3 from '../../pageSections/DigitalSections/Inner-Folio-Digital-2/FolioDigitalTwo3'
import FolioDigitalTwo4 from '../../pageSections/DigitalSections/Inner-Folio-Digital-2/FolioDigitalTwo4'
import FolioDigitalTwo5 from '../../pageSections/DigitalSections/Inner-Folio-Digital-2/FolioDigitalTwo5'
import FolioDigitalTwo6 from '../../pageSections/DigitalSections/Inner-Folio-Digital-2/FolioDigitalTwo6'
import ReactGA from 'react-ga4'

function DigitalFolio2() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <FolioDigitalTwo1 />
          <div data-fetch-key="PageFetch:0">
            
            <FolioDigitalTwo2 />
            <FolioDigitalTwo3 />
            <FolioDigitalTwo4 />
            <FolioDigitalTwo5 />
            <FolioDigitalTwo6 />
          </div>
        </div>
      </div>
    </main>
  )
}

export default DigitalFolio2
