import React, { useEffect } from "react";
import "../styles/Branding.css";
import BrandingHome from "../pageSections/BrandingSections/BrandingHome";
import BrandingMiniFolio from "../pageSections/BrandingSections/BrandingMiniFolio";
import BrandingMessage from "../pageSections/BrandingSections/BrandingMessage";
import BrandingServicesGrid from "../pageSections/BrandingSections/BrandingServicesGrid";
import BrandingLogos from "../pageSections/BrandingSections/BrandingLogos";
import BrandingFolio from "../pageSections/BrandingSections/BrandingFolio";
import Form from "../UniSections/Form";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

function Branding() {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Branding</title>
        <link rel="canonical" href="https://www.swekinteractive.com/" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
          <BrandingHome />
          <BrandingMiniFolio />
          <BrandingMessage />
          <BrandingServicesGrid />
          <BrandingLogos />
          {/* <BrandingFolio /> */}
          <Form />
        </div>
      </div>
    </main>
  );
}

export default Branding;
