import React from "react";

function TechWebdev6() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Why React?
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h3>Component-Based Structure: </h3>
            <p className="mobile-small">
              React's component-based architecture allows you to break down your user interface into modular and reusable components. This promotes code reusability, making it easier to manage and maintain your website as it grows.
            </p>
            <h3>Efficient Updates:</h3>
            <p className="mobile-small">
              React utilizes a virtual DOM (Document Object Model) to efficiently update and render only the components that have changed. This results in faster and more efficient page rendering, contributing to a smoother user experience!{" "}
            </p>
            <h3>Declarative Syntax:</h3>
            <p className="mobile-small">
              React employs a declarative approach, making it more straightforward to understand and reason about your code. This can lead to increased development speed and reduced chances of bugs in your web application.
            </p>
            <h3>Strong Community Support:</h3>
            <p className="mobile-small">
              React is backed by a large and active community of developers. This means access to a wealth of resources, libraries, and third-party tools that can enhance the functionality and performance of your website.
            </p>
            <h3>Versatility: </h3>
            <p className="mobile-small">
              React is not limited to just web applications. With frameworks like Next.js, it can be extended to develop various types of applications, including single-page applications (SPAs), mobile applications, and server-rendered applications, providing flexibility for your project's requirements.
            </p>
            <h3>Maintained by Meta (formerly Facebook):</h3>
            <p className="mobile-small">
              React is maintained by Meta, ensuring continuous updates, improvements, and support. This gives confidence in the long-term stability and viability of the technology for your website.  
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechWebdev6;
