import React from "react";

function FolioDigital9() {
  return (
    <section class="flex flex-wrap overflow-hidden">
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/8330a531-6b9e-468f-b9de-8cbe1be149bd_kff_2022_kampanjer_1x1_5.jpg?auto=compress,format"
            alt="Självmål leder skytteligan i Kalmar FF"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
      <div class="transform scale-90 origin-center w-full md:w-1/2">
        <figure class="pb-[100%] relative w-full w-full relative overflow-hidden">
          <img
            loading="lazy"
            src="https://images.prismic.io/wilsoncreative/32d5996d-0918-4ceb-b10a-4a99ebce0252_kff_2022_kampanjer_1x1_6.jpg?auto=compress,format"
            alt="Diverse media rapporterar om Självmål, Kalmar FF"
            class="absolute inset-0 object-cover h-full w-full"
          />
        </figure>
      </div>
    </section>
  );
}

export default FolioDigital9;
