import React from 'react'

function OlandFolio9() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/55488b03-5523-47d5-b712-d1457f0632a6_oland_16x9_logo_sizes.png?auto=compress,format" alt="Öland logo olika storlekar" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[56%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/52b3e257-7941-48c6-97e2-dc7ba37e1908_oland_16x9_logo_detail.png?auto=compress,format" alt="Öland logo inktrap" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio9
