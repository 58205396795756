import React from "react";

function TechSeo7() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              Off page-SEO
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>Gain Google's trust with Off-page SEO</h2>
            <p className="mobile-small">
              Off-page SEO is efforts that are made outside the own website. This is a lot about raising the authority of the website, as reputable websites that are considered trustworthy often rank better on search engines like Google.
            </p>
            <h3>The right backlinks increase the ranking on Google</h3>
            <p className="mobile-small">
              The most important part of Off-page SEO is link building, i.e. links you get from other web pages. The stronger the domains that link to you, the better. Unfortunately, the reverse is also true: Backlinks from web pages that Google considers to be of low quality can lower your page's ranking. We help you get rid of these toxic links so that your website's ranking potential is not reduced.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechSeo7;
