import React from 'react'

function FolioTechFour2() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg" data-v-2ac86e56>
                                <div class="wrapper lg:flex" data-v-2ac86e56>
                                    <div class="lg:w-3/12" data-v-2ac86e56>
                                        <div class="transform translate-y-10 opacity-0" data-v-2ac86e56><span class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight" data-v-2ac86e56>Grafisk profil</span></div>
                                    </div>
                                    <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
                                        <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56 >
                                            <h2>Fågel som identitet och budbärare</h2>
                                            <p>Hailey utvecklar en digital HR-plattform som hanterar allt från tråkig pappersexercis till medarbetarundersökningar. Med deras plattform automatiseras mycket av dessa flöden. Tjänsten förenklar arbetet för HR-chefen
                                                och skapar glada medarbetare som kan fokusera på annat än admin-krångel. Enkelhet, frihet och flexibilitet förkortas Hailey, och därför blev pippin en självklar symbol för varumärket när vi utvecklade ett
                                                tydligare ID genom en personlig och unik <a href="/branding/design/">grafisk profil</a>.</p>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default FolioTechFour2
