import React from "react";

function SwekMessage7() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              04/04/04. sustainability
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h4>Sustainable Swek</h4>
            <p className="mobile-small">
              At Swek Interactive, our communication is grounded in empathy. We embrace the courage to prioritize long-term perspectives over short-lived thrills, extending this principle to all aspects of our work – be it environmental concerns, interpersonal relationships, or interactions with friends and customers. Rather than adopting a separate sustainability strategy, we've chosen to integrate sustainability into the fabric of Swek Interactive's overall approach. Our commitment to fostering positive change toward a sustainable world is evident in our active pursuit of assignments in this domain. Perhaps creativity is a key component of the solution? We choose to believe so.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SwekMessage7;
