import React from "react";
import {Outlet, Link} from 'react-router-dom'

function TechInfoSlice() {
  return (
    <section class="slice bg-darkest" data-v-2ac86e56>
      <div class="wrapper lg:flex" data-v-2ac86e56>
        <div class="lg:w-3/12" data-v-2ac86e56>
          <div class="transform translate-y-10 opacity-0" data-v-2ac86e56>
            <span
              class="text-white label lg:mb-label md:max-w-[200px] leading-tight text-delete"
              data-v-2ac86e56
            >
              We love comprehensive solutions.
            </span>
          </div>
        </div>
        <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
          <div class="black-bg green-smiley content rich-text" data-v-2ac86e56>
            <h2>A complete web agency.</h2>
            <p className="mobile-small">
              A company's website is crucial, and we view the website as the hub
              of a company's digital communication. Guided by this insight, our
              web agency builds all websites from scratch, with each client's
              unique needs and ambitions in focus. What sets us apart from many
              other web agencies, however, is our holistic approach and digital
              full-service offering. Swek Interactive provides a complete
              process that includes a preliminary study,
              <Link to='/techuxdesign'><a>UX-design</a></Link>,{" "}
              <Link to='/techwebdev'><a>web-design</a>,</Link>{" "}
              <Link to='/techwebdev'><a>web development</a>,</Link>{" "}
             <Link to='/techseo'> <a>SEO</a></Link> as well as ongoing{" "}
              <Link to='/digitaldataanalysis'><a>data analysis and optimization.</a></Link>{" "}
              of performance after launch. We also offer{" "}
             <Link to='/techhosting'> <a>hosting</a></Link> in an environmentally
              friendly server environment tailored for the purpose. Yes, we are
              a complete design agency.
            </p>
          </div>
              </div>
              <Outlet/>
      </div>
    </section>
  );
}

export default TechInfoSlice;
