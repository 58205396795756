import React from "react";

function FolioDigital13() {
  return (
    <section class="py-20 lg:py-40 bg-white text-darkest mydark-bg" data-v-7dc95f74>
      <div class="wrapper" data-v-7dc95f74>
        <div
          class="flex-col flex lg:flex-row items-center gap-5 lg:gap-10"
          data-v-7dc95f74
        >
          <div class="w-full lg:w-1/2" data-v-7dc95f74>
            <div class="dark-smiley white-bg rich-text" data-v-7dc95f74>
              <h3 className="black-convert">Nyhetsmorgon i TV4</h3>
              <p className="black-convert">
                Se inslaget om{" "}
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://kalmarff.se/om-kalmar-ff/i-samhallet/"
                >
                  Kalmar FF med hjärtat
                </a>{" "}
                och Kvinnojourens samarbete mot våld, machokultur och sexistisk
                jargong.
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://www.tv4play.se/program/nyhetsmorgon/unikt-samarbete-mot-m%C3%A4ns-v%C3%A5ld-det-finns-ett-enormt-jobb-att-g%C3%B6ra/13803061?offset=1"
                >
                  Klicka här!
                </a>
              </p>
            </div>
          </div>
          <div class="w-full lg:w-1/2 order-first" data-v-7dc95f74>
            <div class="w-full pb-[70%] relative" data-v-7dc95f74>
              <img
                src="https://images.prismic.io/wilsoncreative/cd2f4134-28b3-4d1e-9d1d-158f97c2d181_Ska%CC%88rmavbild+2022-11-28+kl.+10.31.36.png?auto=compress,format&rect=192,0,2820,1880&w=3600&h=2400"
                alt="Pernilla Printz från Kvinno- och ungdomsjouren i Kalmar och Erik Israelsson från Kalmar FF berättar om samarbetet i TV4 Nyhetsmorgon."
                class="w-full h-full inset-0 object-cover absolute"
                data-v-7dc95f74
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FolioDigital13;
