import React from 'react'

function OlandFolio2() {
  return (
    <section class="text-slice py-20 lg:py-40 bg-white mydark-bg-4" data-v-2ac86e56>
                                <div class="wrapper lg:flex" data-v-2ac86e56>
                                    <div class="lg:w-3/12" data-v-2ac86e56>
                                        <div class="transform translate-y-10 opacity-0" data-v-2ac86e56><span class="text-darkest label lg:mb-label md:max-w-[200px] leading-tight" data-v-2ac86e56>Intro</span></div>
                                    </div>
                                    <div class="w-full pl-8 lg:pl-0 mt-5 lg:mt-0 lg:w-9/12" data-v-2ac86e56>
                                        <div class="white-bg dark-smiley content rich-text" data-v-2ac86e56 >
                                            <h2>Hållbar turism året runt</h2>
                                            <p>Öland är ett starkt varumärke som många förknippar med sol, bad och semester. Men vad händer resten av året? Ölands Turismorganisation vill skapa nya reseberättelser. Nya minnen att dela. Med den nya
                                                identiteten gör vi en varumärkesförflyttning. Från vad Öland är och har varit, till vad det ska bli; en året runt-destination med fokus på hållbar turism.</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
  )
}

export default OlandFolio2
