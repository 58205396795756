import React from 'react'

function OlandFolio27() {
  return (
     <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/fe07c701-0f60-4326-a7ed-02bdac946caa_oland_1x1_instagram.jpg?auto=compress,format" alt="Öland mobilt" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/2a5f6de5-3006-42b2-bd4c-4b2c4fc3742b_oland_1x1_365.jpg?auto=compress,format" alt="Öland 365 dagar om året" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default OlandFolio27
