import React, {useEffect} from 'react'
import FolioTechThree1 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree1'
import FolioTechThree2 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree2'
import FolioTechThree3 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree3'
import FolioTechThree4 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree4'
import FolioTechThree5 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree5'
import FolioTechThree6 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree6'
import FolioTechThree7 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree7'
import FolioTechThree8 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree8'
import FolioTechThree9 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree9'
import FolioTechThree10 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree10'
import FolioTechThree11 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree11'
import FolioTechThree12 from '../../pageSections/TechSections/Inner-Folio-Tech-3/FolioTechThree12'
import ReactGA from 'react-ga4'

function TechFolio3() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  //   }, []);

  return (
    <main class="flex-grow contain">
      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
            <FolioTechThree1 />
          <div data-fetch-key="PageFetch:0">
            <FolioTechThree2 />
            <FolioTechThree3 />
            <FolioTechThree4 />
            <FolioTechThree5 />
            <FolioTechThree6 />
            <FolioTechThree7 />
            <FolioTechThree8 />
            <FolioTechThree9 />
            <FolioTechThree10 />
            <FolioTechThree11 />
            <FolioTechThree12 />
            
          </div>
        </div>
      </div>
    </main>
  )
}

export default TechFolio3
