import React from "react";

function Clients() {
  return (
    <section class="client-slice py-20 bg-lapis text-white">
      <div class="wrapper">
        <div class="flex flex-wrap items-center -m-3 lg:-m-7">
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/aura.svg"
                alt="Kalmar FF emblem"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/auten.svg"
                alt="Haypp Group Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/belinda.svg"
                alt="Meds Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/cafe.svg"
                alt="Luma Energy Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/gen.svg"
                alt="Hjältevadshus Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/grey.svg"
                alt="Guldfågeln Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/hemmam.svg"
                alt="Ingelsta Kalkon Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/kohen.svg"
                alt="Humly Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/levels.svg"
                alt="Svenska Elitserien Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/qq.svg"
                alt="Kosta Boda logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/smart.svg"
                alt="Nordic Nest Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/uzazi.svg"
                alt="Vida Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/vision.svg"
                alt="Ekerum Resort Öland Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/nicee.svg"
                alt="Kalmar Energi Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/aamaka.svg"
                alt="Postgatan Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="./images/My-logos/rashid.svg"
                alt="Bravura Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>







          {/* <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/4ba674a8-9878-4352-b594-d316bcc1a4cb_rawfury.png?auto=compress,format"
                alt="Raw Fury Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/2c458f9b-3ca2-4ccb-ad69-750450cc64f9_instalco.png?auto=compress,format"
                alt="Instalco Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/db2d8ff9-6790-4edd-b4cc-0756f871bd60_oland.png?auto=compress,format"
                alt="Öland logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/1106b63e-6a39-4302-bfc4-e3030c0f51c9_ikea.png?auto=compress,format"
                alt="IKEA Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/37d73629-f557-4c46-a673-fdd1f658dd26_o%CC%88landsbank.png?auto=compress,format"
                alt="Ölands Bank Logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/75fb30c6-54ec-4b6c-9133-7f3fc2e91ab6_multibygg.png?auto=compress,format"
                alt="Multibygg logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/0c874017-7703-4ae0-824d-e929ff3ecda3_blue_partner.png?auto=compress,format"
                alt="Blue Partner logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/2e08b1c6-2f8d-4561-a27f-31cc8e13b1bc_trophi.png?auto=compress,format"
                alt="Trophi logga"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/b7492eb6-bd60-4962-a28f-a3d808b35777_lnu.png?auto=compress,format"
                alt="Linnéuniversitetet logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
              
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/3bd4c9e9-eb39-40ff-be09-99c664c7f9b8_orrefors.png?auto=compress,format"
                alt="Orrefors logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/42e7e60e-ce26-4d68-990a-988217d43a1b_promas.png?auto=compress,format"
                alt="Promas logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div>
          <div class="w-1/2 lg:w-3/12 p-3 lg:p-7 transform scale-75 origin-center opacity-0">
            <a
             
              target="_blank"
              rel="noopener noreferrer"
              class="block relative"
            >
              <img
                loading="lazy"
                src="https://images.prismic.io/wilsoncreative/fb1c049c-e95f-45c4-8e37-6554a570e0fa_haileyhr.png?auto=compress,format"
                alt="Hailey HR logo"
                class="mx-auto w-full h-full"
              ></img>
            </a>
          </div> */}



        </div>
      </div>
    </section>
  );
}

export default Clients;
