import React from 'react'

function FolioTechTwo4() {
  return (
    <section class="flex flex-wrap overflow-hidden">
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/57560275-dc44-495a-b7b4-ed9e9873da6a_bravura_case_webb_jobb_1x1_2.jpg?auto=compress,format" alt="Bravuras webb undersida" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/386e1af3-2e74-47da-a114-9e00197623a2_bravura_case_mockup_1x1.jpg?auto=compress,format" alt="Bravuras webb undersida 2" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/5fe56e5e-febb-418a-9ae9-39a3abb096ee_bravura_stationery.jpg?auto=compress,format" alt="Bravura stationary design" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                                <div class="transform scale-90 origin-center w-full md:w-1/2">
                                    <figure class="pb-[100%] relative w-full w-full relative overflow-hidden"><img loading="lazy" src="https://images.prismic.io/wilsoncreative/b228b591-ff1a-4592-ba23-de924e2a7486_bracura_case_element_animation.gif?auto=compress,format" alt="Bravura animerade symboler" class="absolute inset-0 object-cover h-full w-full"/></figure>
                                </div>
                            </section>
  )
}

export default FolioTechTwo4
