import React, {useEffect} from 'react'
import GoogleAdsHome from '../../pageSections/DigitalSections/Inner-Google-Ads/GoogleAdsHome'
import GaDigital1 from '../../pageSections/DigitalSections/Inner-Google-Ads/GaDigital1'
import GaDigital2 from '../../pageSections/DigitalSections/Inner-Google-Ads/GaDigital2'
import GaDigital3 from '../../pageSections/DigitalSections/Inner-Google-Ads/GaDigital3'
import GaDigital4 from '../../pageSections/DigitalSections/Inner-Google-Ads/GaDigital4'
import GaDigital5 from '../../pageSections/DigitalSections/Inner-Google-Ads/GaDigital5'
import GaDigital6 from '../../pageSections/DigitalSections/Inner-Google-Ads/GaDigital6'
import GaDigital7 from '../../pageSections/DigitalSections/Inner-Google-Ads/GaDigital7'
import GaDigital8 from '../../pageSections/DigitalSections/Inner-Google-Ads/GaDigital8'
import GaDigital9 from '../../pageSections/DigitalSections/Inner-Google-Ads/GaDigital9'
import GaDigital10 from '../../pageSections/DigitalSections/Inner-Google-Ads/GaDigital10'
import ReactGA from 'react-ga4'
import { Helmet } from 'react-helmet'

function GoogleAds() {

  return (
    <main class="flex-grow contain">

     <Helmet>
        <meta charSet="utf-8" />
        <title>Google Ads</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div class="pt-page">
        <div data-fetch-key="PageFetch:0" class="relative z-20 slices">
                  <GoogleAdsHome />
                  <GaDigital1 />
                  <GaDigital2 />
                  {/* <GaDigital3 /> */}
                  <GaDigital4 />
                  <GaDigital5 />
                  <GaDigital6 />
                  <GaDigital7 />
                  <GaDigital8 />
                  <GaDigital9 />
                  <GaDigital10 />
        </div>
      </div>
    </main>
  )
}

export default GoogleAds
